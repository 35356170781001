<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import ClassCard from '@/components/admin/ClassRoom/ClasssCard.vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import Loading from '@/components/Loading.vue';
    import FormModel from '@/components/FormModel.vue'
    import LoadingCard from '@/components/admin/ClassRoom/LoadingCard.vue';
    import ClassDetailCard from '@/components/admin/ClassRoom/ClasssDetailCard.vue';
    import FolderCard from '@/components/admin/ClassRoom/FolderCard.vue';
    import { mdiChartBar, mdiFolder } from '@mdi/js';
    import Icon from '@/components/Icon.vue';
    import { ref,reactive, onMounted, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import { snaker } from '@/helper/helper';
    import router from '@/router';
    let store = useStore();
    let route = useRoute();
    let classId= route.params.id;
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.getClassNavs[x]];
        }
        store.commit('setClassNavs',navs);
        router.push({name: name, params: params});
    }
    let i = reactive({v:0});
    let navs = reactive([
        {
            name: 'Level',
            data: computed(()=>store.getters.getLevels),
            func: (id,name)=>{
                openModal(Loading);
                store.commit('setCategories','reset');
                store.dispatch('getCategories',id)
                .then((value)=>{
                    closeModal();
                    let categories = [];
                    value.forEach(ans => {
                        categories = [...categories,{id:ans.id,name:ans.name,total: ans.total}]
                    });
                    navs[1].name=name;
                    navs[1].data=categories;
                    i.v=1;
                }).catch(()=>{
                    closeModal();
                });
            },
        },
        {
            name: null,
            data: null,
            func: (id,name)=>{
                openModal(Loading);
                store.commit('setSubCategories','reset');
                store.dispatch('getSubCategories',id)
                .then((value)=>{
                    closeModal();
                    let subCategories = [];
                    value.forEach(ans => {
                        subCategories = [...subCategories,{id:ans.id,name:ans.name,total: ans.total}]
                    });
                    navs[2].name=name;
                    navs[2].data=subCategories;
                    i.v=2;
                }).catch(()=>{
                    closeModal();
                });
            },
        },
        {
            name: null,
            data: null,
            func: (id,name)=>{
                openModal(FormModel, {
                    toggle: ()=>closeModal(),
                    model: {
                        title: 'Add Exam',
                        form: [
                            {
                                label: 'Exam Title',
                                type: 'text',
                                name: 'title',
                                placeholder: 'Grammer Lesson 1',
                                ref: ref('')
                            },
                            {
                                label: 'Exam Description',
                                type: 'text',
                                name: 'description',
                                placeholder: 'How to Apply',
                                ref: ref('')
                            },
                            {
                                label: 'Exam will start',
                                type: 'date',
                                name: 'start_at',
                                ref: ref('')
                            },
                            {
                                label: 'Exam Dead line',
                                type: 'date',
                                name: 'dead_line',
                                ref: ref('')
                            },
                            {
                                label: '',
                                type: 'hidden',
                                name: 'course_id',
                                placeholder: '',
                                ref: ref(classId)
                            },
                            {
                                label: '',
                                type: 'hidden',
                                name: 'subcategory_id',
                                placeholder: '',
                                ref: ref(id)
                            }
                        ],
                        action: {
                            title: 'Add Exam'
                        },
                    },
                    submit: (formData)=>{
                        store.dispatch('addTest',formData);
                    }
                });
            },
        },
    ]);
    const inTests = computed( ()=>{
        let temp = [];
        store.getters.getClassDetail[2].data.filter( val=> temp=[...temp,val.subcategory_id] );
        return temp;
    } );
    onMounted(()=>{
        if(classId==null){
            router.push({name: 'ClassRoom'});
            return;
        }
        store.commit('setLevels','reset');
        store.dispatch('getLevels')
    });
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getClassNavs" :goRoute="goRoute" />
    <div class="flex flex-row py-7 items-center justify-start pl-12 text-xl bg-slate-300 dark:bg-side-bg-dark">
        <span v-for="value,n in (i.v)+1" :key="n" class="pr-2">
            <span @click="i.v=n" :class="{'cursor-not-allowed animate-pulse bg-blue-500': (i.v)==n,'bg-orange-400':(i.v)!=n}" class="cursor-pointer px-2 py-2 rounded-lg">{{navs[n].name}}</span>            
        </span>
    </div>

    <div class="px-8 pt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 select-none">
        <div v-for="item,index in navs[i.v].data" :key="index">
            <div v-if="i.v<2">
                <FolderCard :check="false" :name="item.name" :id="item.id" :total="item.total" :action="navs[i.v].func"/>
            </div>
            <div v-else>
                <FolderCard :check="inTests.includes(item.id)" :name="item.name" :id="item.id" :total="item.total" :action="navs[i.v].func"/>
            </div>
        </div>
    </div>
</admin-layout></template>