<script setup>
import Icon from '@/components/Icon.vue';
import { mdiClipboardEditOutline  , mdiTrashCanOutline, mdiEye } from '@mdi/js';
let props = defineProps({
    admin:{
        type: Boolean,
        required: false,
        default: true,
    },
    index:{
        type: Number,
        required: true,
    },
    certisUrl:{
        type: String,
        required: true
    },
    className:{
        type: String,
        required: true
    },
    issueDate:{
        type: String,
        required: true
    },
    edit:{
        type: Function,
        required: true
    },
    del:{
        type: Function,
        required: true
    },
    item:{
        type: Object,
        required: true
    },
});
const mode = props.index%2==0? 'lg:flex-row' : 'lg:flex-row-reverse';
</script>
<template>
    <div :class="mode" class="flex flex-col md:w-fit p-5 rounded-lg border-4 border-sky-700 bg-white text-primary dark:border-0 dark:bg-side-bg-dark md:ml-12 items-center font-domine">
        <div class="w-64 h-44 rounded bg-white flex flex-col justify-center">
            <img :src="certisUrl" class="flex flex-col h-full" alt="certis">
        </div>
        <div class="xl:mx-16 flex mt-4 md:mt-0 flex-col w-full md:w-fit justify-center h-full font-medium tracking-wide">
            <div class="py-1 flex flex-row justify-start">
                <span class="inline-flex w-28 md:w-36 mr-2 text-primary dark:text-gray-400">Course:</span>
                <span>{{className}}</span>
            </div>
            <div :class="{'py-4':!admin,'py-1':admin}">
                <span class="inline-flex w-28 md:w-36 mr-2 text-primary dark:text-gray-400">Issue Date: </span>
                <span>{{issueDate}}</span>
            </div>
            <div v-if="admin" class="py-1 flex flex-row items-start justify-start">
                <div class="inline-flex w-28 md:w-36 mr-2 text-primary dark:text-gray-400">Actions: </div>
                <div @click="edit(item)" class="text-green-500 dark:text-green-300 mr-6 cursor-pointer inline-block">
                    <Icon :path="mdiClipboardEditOutline" size="25"/>
                </div>
                <div @click="del(item.id)" class="text-red-400 mr-6 cursor-pointer inline-block">
                    <Icon :path="mdiTrashCanOutline" size="25"/>
                </div>
            </div>
            <div class="pt-1 flex flex-row items-center">
                <div class="inline-flex w-28 md:w-36 mr-2 text-primary dark:text-gray-400">check: </div>
                <a :href="certisUrl" target="_blank" class="dark:text-amber-300 mr-6 cursor-pointer inline-flex items-center justify-center">
                    <Icon :path="mdiEye" size="25"/>
                </a>
            </div>
        </div>
    </div>
</template>