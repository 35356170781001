import axios from 'axios';
import {openModal,closeModal} from "jenesius-vue-modal";
import { exist } from 'joi';
import { useStore } from 'vuex';
import {sleep} from '@/helper/helper';

const state = {
   navs: [],
   levels: [],
   categories: [],
   subCategories: [],
   questions: [],
   loadingState: [
    {
        id: 1,
        name: 'Loading . . .',
        total: '?'
    },
    {
        id: 2,
        name: 'Loading . . .',
        total: '?'
    },
    {
        id: 3,
        name: 'Loading . . .',
        total: '?'
    },
    {
        id: 4,
        name: 'Loading . . .',
        total: '?'
    },
   ],
};

const getters = {
   testGetNavs: state => state.navs,
   getLoadingDatas: state => state.loadingState,
   getLevels: state => state.levels,
   getCategories: state => state.categories,
   getSubCategories: state => state.subCategories,
   getQuestions: state => state.questions,
};

const actions = {
// Test Page
    async getLevels({commit,rootState}){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.get(`${baseUri}${prefix}/levels`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        }
        ).then(function(data){
            commit('setLevels',data.data.data)
        }).catch(function(error){
            commit('checkError',error);
        });
    },
    async addNewLevel({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/levels`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            // console.log(data.data);
            let ans = data.data.level;
            let newLevel = {id:ans.id,name:ans.name,total: '0'};
            commit('addNewLevel',newLevel);
        }).catch((error)=>{
            commit('setLoadFormModel',false);            
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
            }
            //error.response.data.message
        });
    }, 
    async editLevel({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        let params = {};
        for (let key of formData.keys()) params[key]=formData.get(key);
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.put(`${baseUri}${prefix}/levels/${formData.get('id')}`,
        params,headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.data;
            let changeLevel = {id:ans.id,name:ans.name,total: ans.total};
            commit('updateLevel',changeLevel);
        })
        .catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
            }            
        });
    },  
    async deleteLevel({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/levels/${id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteLevel',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
                if(message.name) commit('setErrorFormModel',message.name[0]);
            }            
        });
    },
// Test Page
// TestLevel Page
    async getCategories({commit,rootState},id){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        return axios.get(`${baseUri}${prefix}/levels/${id}`,{
            headers: {
                'Authorization': 'Bearer '+ token,   //'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMWU2ZjFmMmIyNmY2OTJjYWQ1Mzc5YzM5MjU1MjcwYjJlNjM1OTVkYzFkMmVkMjUyNDliNWY0M2Q2MmI1NWU0NjkzNWU3NDQ5MzhkYjhkZGQiLCJpYXQiOjE2NTMyODEzODkuMjQxMjMsIm5iZiI6MTY1MzI4MTM4OS4yNDEyMzQsImV4cCI6MTY4NDgxNzM4OS4yMjY1OCwic3ViIjoiMiIsInNjb3BlcyI6WyJhZG1pbiJdfQ.KENdJNJIQ1bgwxSYSUGSg8v0jQwk2ePzxxj2ad3H0VbIOqEHO7j_yjcWH43us0A9jUuiDd56cvvX2_WxGIeSIud0S5R63mjJsArqqdq3PZ_HGDrVi_piPiuPnCeAMVIz45TYOFGwqV3X9kgQ28NWxpH7vA1hF_lV1DULIhzFdr6sMG6uOtFIfNPEnEcr6lo3UXlF8FmocghKZOFmEnEuIpWW4SKputcSjh8NQsfJ_veX95INzJMHDy0pK5H1mpq9-kqP3LzRexpCNU9BK2eY4l0Dtu-ja9he-DVq40CvGZZ53_h3hUliywnHunJR7JuVG--qw9rAwMILYGvHP1d1V3LuozNQexFogzPNQDYu4t7-McibdyIuLvYdbhUmGafV9s0VQ5rmPFZepMx7J2_jVIv1nuOJp9IBYqHwImp36LiVjKizquZIyVEg1hDp5DIG_Oqe4-_hVzLNffvUMQZi8An0qnPNGEQLxP-y1yxltKXv5yRG2XpLwWgNFMAu9uIq-f8DJsxe0h-vpCBReZPkIXo9SRDTszJyowUJaG2Bt_Y1PlBIRfFCWTdA5IqYzqV2k6cXNIIOH66Gb7wkKdvn3WJtwpT2MEVb29tAjMhYOR0LImnEmUxUjhjo_X60ECUnJ4QXljYSTB6tTq1HwtON48dIK4ZTpkymoA4_S5rxUNA'
            }
        }
        ).then(function(data){
            // console.log(data.data.data);
            commit('setCategories',data.data.data);
            return data.data.data;
        })
        .catch(function(){
            console.log('FAILURE!!');
            return [];
        });
    },
    async addNewCategory({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/categories`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            // console.log(data.data.data);
            let ans = data.data.data;
            let newCategory = {id:ans.id,name:ans.name,total: '0'};
            commit('addNewCategory',newCategory);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
            }
        });
    },
    async editCategory({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        let params = {};
        for (let key of formData.keys()) params[key]=formData.get(key);
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.put(`${baseUri}${prefix}/categories/${formData.get('id')}`,
        params,headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.data;
            let changeCategory = {id:ans.id,name:ans.name,total: ans.total};
            commit('updateCategory',changeCategory);
        })
        .catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
            }            
        });
    },
    async deleteCategory({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/categories/${id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteCategory',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
// TestLevel Page
// TestCategory Page
    async getSubCategories({commit,rootState},id){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        return axios.get(`${baseUri}${prefix}/categories/${id}`,{
            headers: {
                'Authorization': 'Bearer '+ token,   //'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMWU2ZjFmMmIyNmY2OTJjYWQ1Mzc5YzM5MjU1MjcwYjJlNjM1OTVkYzFkMmVkMjUyNDliNWY0M2Q2MmI1NWU0NjkzNWU3NDQ5MzhkYjhkZGQiLCJpYXQiOjE2NTMyODEzODkuMjQxMjMsIm5iZiI6MTY1MzI4MTM4OS4yNDEyMzQsImV4cCI6MTY4NDgxNzM4OS4yMjY1OCwic3ViIjoiMiIsInNjb3BlcyI6WyJhZG1pbiJdfQ.KENdJNJIQ1bgwxSYSUGSg8v0jQwk2ePzxxj2ad3H0VbIOqEHO7j_yjcWH43us0A9jUuiDd56cvvX2_WxGIeSIud0S5R63mjJsArqqdq3PZ_HGDrVi_piPiuPnCeAMVIz45TYOFGwqV3X9kgQ28NWxpH7vA1hF_lV1DULIhzFdr6sMG6uOtFIfNPEnEcr6lo3UXlF8FmocghKZOFmEnEuIpWW4SKputcSjh8NQsfJ_veX95INzJMHDy0pK5H1mpq9-kqP3LzRexpCNU9BK2eY4l0Dtu-ja9he-DVq40CvGZZ53_h3hUliywnHunJR7JuVG--qw9rAwMILYGvHP1d1V3LuozNQexFogzPNQDYu4t7-McibdyIuLvYdbhUmGafV9s0VQ5rmPFZepMx7J2_jVIv1nuOJp9IBYqHwImp36LiVjKizquZIyVEg1hDp5DIG_Oqe4-_hVzLNffvUMQZi8An0qnPNGEQLxP-y1yxltKXv5yRG2XpLwWgNFMAu9uIq-f8DJsxe0h-vpCBReZPkIXo9SRDTszJyowUJaG2Bt_Y1PlBIRfFCWTdA5IqYzqV2k6cXNIIOH66Gb7wkKdvn3WJtwpT2MEVb29tAjMhYOR0LImnEmUxUjhjo_X60ECUnJ4QXljYSTB6tTq1HwtON48dIK4ZTpkymoA4_S5rxUNA'
            }
        }
        ).then(function(data){
            // console.log(data.data.data);
            commit('setSubCategories',data.data.data);
            return data.data.data;
        })
        .catch(function(){
            return [];
            console.log('FAILURE!!');
        });
    },
    async addNewSubCategory({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        if(formData.get('public')=='true') formData.set('public',1);
        else formData.set('public',0);
        // return;
        axios.post(`${baseUri}${prefix}/sub-categories`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token,   //'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMWU2ZjFmMmIyNmY2OTJjYWQ1Mzc5YzM5MjU1MjcwYjJlNjM1OTVkYzFkMmVkMjUyNDliNWY0M2Q2MmI1NWU0NjkzNWU3NDQ5MzhkYjhkZGQiLCJpYXQiOjE2NTMyODEzODkuMjQxMjMsIm5iZiI6MTY1MzI4MTM4OS4yNDEyMzQsImV4cCI6MTY4NDgxNzM4OS4yMjY1OCwic3ViIjoiMiIsInNjb3BlcyI6WyJhZG1pbiJdfQ.KENdJNJIQ1bgwxSYSUGSg8v0jQwk2ePzxxj2ad3H0VbIOqEHO7j_yjcWH43us0A9jUuiDd56cvvX2_WxGIeSIud0S5R63mjJsArqqdq3PZ_HGDrVi_piPiuPnCeAMVIz45TYOFGwqV3X9kgQ28NWxpH7vA1hF_lV1DULIhzFdr6sMG6uOtFIfNPEnEcr6lo3UXlF8FmocghKZOFmEnEuIpWW4SKputcSjh8NQsfJ_veX95INzJMHDy0pK5H1mpq9-kqP3LzRexpCNU9BK2eY4l0Dtu-ja9he-DVq40CvGZZ53_h3hUliywnHunJR7JuVG--qw9rAwMILYGvHP1d1V3LuozNQexFogzPNQDYu4t7-McibdyIuLvYdbhUmGafV9s0VQ5rmPFZepMx7J2_jVIv1nuOJp9IBYqHwImp36LiVjKizquZIyVEg1hDp5DIG_Oqe4-_hVzLNffvUMQZi8An0qnPNGEQLxP-y1yxltKXv5yRG2XpLwWgNFMAu9uIq-f8DJsxe0h-vpCBReZPkIXo9SRDTszJyowUJaG2Bt_Y1PlBIRfFCWTdA5IqYzqV2k6cXNIIOH66Gb7wkKdvn3WJtwpT2MEVb29tAjMhYOR0LImnEmUxUjhjo_X60ECUnJ4QXljYSTB6tTq1HwtON48dIK4ZTpkymoA4_S5rxUNA'
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            // console.log(data.data.data);
            let ans = data.data.data;
            let newSubCategory = {id:ans.id,name:ans.name,public:ans.public,total: '0'};
            commit('addNewSubCategory',newSubCategory);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
            }
        });
    },
    async editSubCategory({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        let params = {};
        if(formData.get('public')=='true') formData.set('public',1);
        else formData.set('public',0);
        for (let key of formData.keys()) params[key]=formData.get(key);
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.put(`${baseUri}${prefix}/sub-categories/${formData.get('id')}`,
        params,headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.data;
            console.log(ans);
            let changeSubCategory = {id:ans.id,name:ans.name,public:ans.public,total: ans.total};
            // console.log(changeSubCategory);
            commit('updateSubCategory',changeSubCategory);
        })
        .catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
            }            
        });
    },
    async deleteSubCategory({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/sub-categories/${id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteSubCategory',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
// TestCategory Page
// TestQuestion Page
    async getQuestions({commit,rootState},id){
        // console.log(id);
        // return;
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        return axios.get(`${baseUri}${prefix}/sub-categories/${id}`,{
            headers: {
                'Authorization': 'Bearer '+ token,   //'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMWU2ZjFmMmIyNmY2OTJjYWQ1Mzc5YzM5MjU1MjcwYjJlNjM1OTVkYzFkMmVkMjUyNDliNWY0M2Q2MmI1NWU0NjkzNWU3NDQ5MzhkYjhkZGQiLCJpYXQiOjE2NTMyODEzODkuMjQxMjMsIm5iZiI6MTY1MzI4MTM4OS4yNDEyMzQsImV4cCI6MTY4NDgxNzM4OS4yMjY1OCwic3ViIjoiMiIsInNjb3BlcyI6WyJhZG1pbiJdfQ.KENdJNJIQ1bgwxSYSUGSg8v0jQwk2ePzxxj2ad3H0VbIOqEHO7j_yjcWH43us0A9jUuiDd56cvvX2_WxGIeSIud0S5R63mjJsArqqdq3PZ_HGDrVi_piPiuPnCeAMVIz45TYOFGwqV3X9kgQ28NWxpH7vA1hF_lV1DULIhzFdr6sMG6uOtFIfNPEnEcr6lo3UXlF8FmocghKZOFmEnEuIpWW4SKputcSjh8NQsfJ_veX95INzJMHDy0pK5H1mpq9-kqP3LzRexpCNU9BK2eY4l0Dtu-ja9he-DVq40CvGZZ53_h3hUliywnHunJR7JuVG--qw9rAwMILYGvHP1d1V3LuozNQexFogzPNQDYu4t7-McibdyIuLvYdbhUmGafV9s0VQ5rmPFZepMx7J2_jVIv1nuOJp9IBYqHwImp36LiVjKizquZIyVEg1hDp5DIG_Oqe4-_hVzLNffvUMQZi8An0qnPNGEQLxP-y1yxltKXv5yRG2XpLwWgNFMAu9uIq-f8DJsxe0h-vpCBReZPkIXo9SRDTszJyowUJaG2Bt_Y1PlBIRfFCWTdA5IqYzqV2k6cXNIIOH66Gb7wkKdvn3WJtwpT2MEVb29tAjMhYOR0LImnEmUxUjhjo_X60ECUnJ4QXljYSTB6tTq1HwtON48dIK4ZTpkymoA4_S5rxUNA'
            }
        }
        ).then(function(data){
            commit('setQuestions',data.data.data);
            return data.data.data;
        })
        .catch(function(){
            console.log('FAILURE!!');
        });
    },
    async addNewQuestion({commit,rootState,state},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/questions`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token,   //'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMWU2ZjFmMmIyNmY2OTJjYWQ1Mzc5YzM5MjU1MjcwYjJlNjM1OTVkYzFkMmVkMjUyNDliNWY0M2Q2MmI1NWU0NjkzNWU3NDQ5MzhkYjhkZGQiLCJpYXQiOjE2NTMyODEzODkuMjQxMjMsIm5iZiI6MTY1MzI4MTM4OS4yNDEyMzQsImV4cCI6MTY4NDgxNzM4OS4yMjY1OCwic3ViIjoiMiIsInNjb3BlcyI6WyJhZG1pbiJdfQ.KENdJNJIQ1bgwxSYSUGSg8v0jQwk2ePzxxj2ad3H0VbIOqEHO7j_yjcWH43us0A9jUuiDd56cvvX2_WxGIeSIud0S5R63mjJsArqqdq3PZ_HGDrVi_piPiuPnCeAMVIz45TYOFGwqV3X9kgQ28NWxpH7vA1hF_lV1DULIhzFdr6sMG6uOtFIfNPEnEcr6lo3UXlF8FmocghKZOFmEnEuIpWW4SKputcSjh8NQsfJ_veX95INzJMHDy0pK5H1mpq9-kqP3LzRexpCNU9BK2eY4l0Dtu-ja9he-DVq40CvGZZ53_h3hUliywnHunJR7JuVG--qw9rAwMILYGvHP1d1V3LuozNQexFogzPNQDYu4t7-McibdyIuLvYdbhUmGafV9s0VQ5rmPFZepMx7J2_jVIv1nuOJp9IBYqHwImp36LiVjKizquZIyVEg1hDp5DIG_Oqe4-_hVzLNffvUMQZi8An0qnPNGEQLxP-y1yxltKXv5yRG2XpLwWgNFMAu9uIq-f8DJsxe0h-vpCBReZPkIXo9SRDTszJyowUJaG2Bt_Y1PlBIRfFCWTdA5IqYzqV2k6cXNIIOH66Gb7wkKdvn3WJtwpT2MEVb29tAjMhYOR0LImnEmUxUjhjo_X60ECUnJ4QXljYSTB6tTq1HwtON48dIK4ZTpkymoA4_S5rxUNA'
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            let navs=[];
            for(let x=0;x<4;x++){
                navs = [...navs,state.navs[x]];
            }
            commit('setNavs',navs);
            commit('toNamed',{name: 'TestQuestion', params: { name: '', id: formData.get('subcategory_id') }});
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                alert(error.response);
            }
        });
    },
    async editQuestion({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        let params = {};
        for (let key of formData.keys()) params[key]=formData.get(key);
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.put(`${baseUri}${prefix}/questions/${formData.get('id')}`,
        params,headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            let navs=[];
            for(let x=0;x<4;x++){
                navs = [...navs,state.navs[x]];
            }
            commit('setNavs',navs);
            commit('toNamed',{name: 'TestQuestion', params: { name: '', id: formData.get('subcategory_id') }});
        })
        .catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
            }            
        });
    },
    async deleteQuestion({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/questions/${id}`,
        headers
        ).then(function(){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteQuestion',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
// TestQuestion Page
// testFileUpload
    async testFileUpload({commit,rootState},formData){
        // let params = {};
        // for (let key of formData.keys()) params[key]=formData.get(key);
        // console.log(params);
        // return;
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/certificates`,
        formData,
        {
            headers: {
                'Authorization': 'Bearer '+ token,
                'Content-Type': 'multipart/form-data'
            }
        }
        ).then(function(data){
            console.log(data);
        })
        .catch(function(){
            console.log('FAILURE!!');
        });
    },
// testFileUpload
};

const mutations = {
    setNavs: (state,navs)=>{
        state.navs=navs;
    },
// Test Page
    setLevels: (state,levels)=>{
        if(levels=='reset'){
            state.levels=[];
        }else if(levels.length>0){
            let newLevels = [];
            levels.forEach(ans => {
                newLevels = [...newLevels,{id:ans.id,name:ans.name,total: ans.total}]
            });
            state.levels=newLevels;
        }else{
            state.levels=-1;
        }
    },
    addNewLevel: (state,level)=>{
        state.levels=[level,...(state.levels==-1?[]:state.levels)];
    },
    updateLevel: (state,level)=>{
        state.levels.filter((ans,index)=>{
            if(ans.id==level.id){
                state.levels[index] = level;
                return;
            }
        });
    },
    deleteLevel: (state,id)=>{
        let changeLevels = state.levels.filter((ans)=>ans.id!=id);
        if(changeLevels.length>0){
            state.levels=changeLevels;
        }else{
            state.levels=-1;
        }
    },
// Test Page
// TestLevel Page
    setCategories:(state,categories)=>{
        if(categories=='reset'){
            state.categories=[];
        }else if(categories.length>0){
            let newCategories = [];
            categories.forEach(ans => {
                newCategories = [...newCategories,{id:ans.id,name:ans.name,total: ans.total}]
            });
            state.categories=newCategories;
        }else{
            state.categories=-1;
        }
    },
    addNewCategory: (state,category)=>{
        state.categories=[category,...(state.categories==-1?[]:state.categories)];
    },
    updateCategory: (state,category)=>{
        state.categories.filter((ans,index)=>{
            if(ans.id==category.id){
                state.categories[index] = category; return;
            }
        });
    },
    deleteCategory: (state,id)=>{
        let changeCategories = state.categories.filter((ans)=>ans.id!=id);
        if(changeCategories.length>0){
            state.categories=changeCategories;
        }else{
            state.categories=-1;
        }
    },
// TestLevel Page
// TestCategory Page
    setSubCategories:(state,subCategories)=>{
        if(subCategories=='reset'){
            state.subCategories=[];
        }else if(subCategories.length>0){
            let newSubCategories = [];
            subCategories.forEach(ans => {
                newSubCategories = [...newSubCategories,{id:ans.id,name:ans.name,public:ans.public,total: ans.total}]
            });
            state.subCategories=newSubCategories;
        }else{
            state.subCategories=-1;
        }
    },
    addNewSubCategory: (state,subCategory)=>{
        state.subCategories=[subCategory,...(state.subCategories==-1?[]:state.subCategories)];
    },
    updateSubCategory: (state,subCategory)=>{
        state.subCategories.filter((ans,index)=>{
            if(ans.id==subCategory.id){
                state.subCategories[index] = subCategory;
                return;
            }
        });
    },
    deleteSubCategory: (state,id)=>{
        let changeSubCategories = state.subCategories.filter((ans)=>ans.id!=id);
        if(changeSubCategories.length>0){
            state.subCategories=changeSubCategories;
        }else{
            state.subCategories=-1;
        }
    },
// TestCategory Page
// TestQuestion Page
    setQuestions:(state,questions)=>{
        if(questions=='reset'){
            state.questions=[];
        }else if(questions.length>0){
            let newQuestions = [];
            questions.forEach(ans => {
                newQuestions = [...newQuestions,{
                        id:ans.id,
                        title:ans.title,
                        multiple:ans.multiple,
                        true:ans.correct_ans,
                        explain:ans.explain,
                        subcategory_id: ans.subcategory_id,
                    }
                ]
            });
            state.questions=newQuestions;
        }else{
            // console.log(questions);return;
            state.questions=-1;
        }
    },
    deleteQuestion: (state,id)=>{
        let changeQuestions = state.questions.filter((ans)=>ans.id!=id);
        if(changeQuestions.length>0){
            state.questions=changeQuestions;
        }else{
            state.questions=-1;
        }
    },
// TestQuestion Page
};

export default {
    state, getters, actions, mutations
}