import axios from 'axios';
import router from '../../router'

const state = {
    loginErrorMsg: '',
    loginOnLoad: false,
};

const getters = {
    loginErrorMsg: (state)=> state.loginErrorMsg,
    loginOnLoad: (state)=> state.loginOnLoad,
};

const actions = {
    async login({commit,rootState,state,dispatch},request){
        let baseUrl = rootState.baseUrl + 'login';
        if(!state.loginOnLoad){
            state.loginOnLoad=true;
            commit('setError','');
            await axios
            .post(baseUrl,request)
            .then((ans)=>{
                state.loginOnLoad=false;
                // console.log(ans.data.token);
                let auth = {
                    id: ans.data.user_data.id,
                    phone: ans.data.user_data.phone,
                    name: ans.data.user_data.name,
                    token: ans.data.token,
                    fb_link: ans.data.user_data.fb_link,
                    photo_url: ans.data.user_data.photo_url,
                    role: ans.data.user_data.role.role,
                    updated_at: ans.data.user_data.updated_at,
                }
                commit('setAuth',auth);
                let role = ans.data.user_data.role.role
                let isAuth = role=='admin' || role=='staff' || role=='lecturer';
                if(isAuth){
                    commit('toNamed',{name: 'Dashboard'});
                }else{
                    commit('toNamed',{name: 'HomePage'});
                }
                if(ans.data.user_data.role.role=='student'){
                    dispatch('getUserCourses');
                }
            })
            .catch((error)=>{
                console.log(error);
                state.loginOnLoad=false;
                if(error.response) commit('setError',error.response.data.message);
            });
        }
    },
};

const mutations = {
    setError: (state,error)=>{
        state.loginErrorMsg=error;
    },
    // toRoute: (state,to)=> router.push(to)
};

export default {
    state,
    getters,
    actions,
    mutations
}