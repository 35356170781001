<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Icon from '@/components/Svg.vue';
    import { mdiClipboardEditOutline, mdiTrashCanOutline, mdiAccountPlus } from '@mdi/js';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue';
    import DataTable from 'vue3-easy-data-table';
    import 'vue3-easy-data-table/dist/style.css';
    import { useStore } from 'vuex';
    import { onMounted, ref } from 'vue';
    import { sleep } from '@/helper/helper';
    let store = useStore();
    const headers = [
      { text: "Order ID", value: "id", sortable: true },
      { text: "Name", value: "name", width: 200, sortable: true },
      { text: "Phone", value: "phone" },
      { text: "Course", value: "course_name" },
      { text: "Status", value: "status" },
      { text: "Remark", value: "remark", width: 200},
      { text: "Action", value: "action"}
    ];
    // search feature
    const searchField = ref('name');
    const searchValue = ref('');
    const searchKey = ['name','phone','course_name','status'];
    // search feature
    // real time data
    let feature = "enroll order";
    let getRealTimeData = ref(true);
    const getRTD = async ()=>{
        if(store.isRTDRun){
            return;
        }else{
            store.isRTDRun=true;
        }
        while(1==2-1){
            store.dispatch('getEnrollOrder');
            await sleep(store.state.interval);
        }
    }
    // real time data
    // const createAcc = (order)=> console.log(order.id);
    const createAcc = (order) => {
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: `Create New Student Account`,
                form: [
                    {
                        label: 'User Name *',
                        type: 'text',
                        name: 'name',
                        placeholder: 'Mg Mg',
                        ref: ref(order.name)
                    },
                    {
                        label: 'Phone *',
                        type: 'text',
                        name: 'phone',
                        placeholder: '09780132792',
                        ref: ref(order.phone)
                    },
                    {
                        label: 'Password *',
                        type: 'password',
                        name: 'password',
                        placeholder: '••••••••',
                        ref: ref('12345678')
                    },
                    {
                        label: 'Remark - Optional (မထည့်လည်းရ)',
                        type: 'text',
                        name: 'remark',
                        placeholder: 'New Student',
                        ref: ref('')
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'role_id',
                        placeholder: '',
                        ref: ref(4)
                    },
                ],
                action: {
                    title: 'Create Account'
                }
            },
            submit: (formData)=>{
                store.dispatch('createAccount',formData);
            }
        });
    };
    const edit = (order)=>{
        const options = [
            {value:'0',text:'order'},
            {value:'1',text:'confirm'},
            {value:'-1',text:'cancel'},
        ];
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Edit Order Status',
                form: [
                    {
                        label: 'order status',
                        type: 'select',
                        name: 'status',
                        options: options,
                        ref: ref(order.status==0?'0':order.status==1?'1':'-1')
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'id',
                        placeholder: '',
                        ref: ref(order.id)
                    },
                ],
                action: {
                    title: 'update'
                },
            },
            submit: (formData)=>{
                store.dispatch('editEnrollOrder',formData);
            }
        });
    };
    const del = (order)=>{
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Are you sure you want to delete this Order?',
                form: null,
                action: {
                    title: 'Delete'
                },
            },
            submit: ()=>{
                store.dispatch('deleteEnrollOrder',order.id);
            }
        });
    };
    onMounted(()=>{
        getRTD();
    })
    const jinn = (ref)=> ref.length==1? 'h-36':ref.length==2? 'h-20': ref.length==3? 'h-12':'';
    const getStatus = status=> status==0? {text:'order',color:'text-amber-700 bg-amber-300'} : status==1? {text:'confirm',color:'text-green-700 bg-green-300'}: {text:'cancel',color:'text-red-700 bg-red-300'};
</script>
<template><admin-layout>
    <div class="flex flex-row h-20 items-center pl-8 md:pl-12 text-xl cursor-pointer">
        Class
    </div>
    <div class="px-8 md:px-12 py-8 z-0">
        <div class="flex flex-col md:flex-row justify-between mb-6">
            <div>
                <span class="mr-2">search field:</span>
                <select v-model="searchField" class="rounded-sm dark:bg-gray-600 py-1 pl-2">
                    <option v-for="v,i in searchKey" :key="i">{{v}}</option>
                </select>
            </div>
            <div class="flex flex-row mt-4 lg:mt-0">
                <span class="mr-4">search value: </span>
                <input type="text" v-model="searchValue" class="rounded-sm bg-gray-200 dark:bg-gray-600 py-1 px-2 w-40 md:w-60">
            </div>
        </div>

        <DataTable :headers="headers" :items="store.getters.getOrders" :rows-per-page="10" :search-field="searchField" :search-value="searchValue">
            <template #id="{id}">
                <div class="flex flex-row items-center justify-start" :class="jinn(store.getters.getOrders)">
                    {{id}}
                </div>
            </template>
            <template #status="{status}">
                <div class="flex flex-row items-center justify-start">
                    <div :class="['my-1 px-4 py-1 rounded-full font-semibold select-none',getStatus(status).color]">
                        {{getStatus(status).text}}
                    </div>
                </div>
            </template>
            <template #action="order">
                <div class="flex flex-row items-center justify-start">
                    <div @click="edit(order)" class="text-green-500 dark:text-green-300 mr-6 cursor-pointer inline-block">
                        <Icon :path="mdiClipboardEditOutline" size="25"/>
                    </div>
                    <div @click="del(order)" class="text-red-400 mr-6 cursor-pointer inline-block">
                        <Icon :path="mdiTrashCanOutline" size="25"/>
                    </div>
                    <div @click="createAcc(order)" class="text-gray-500 -scale-x-100 mr-6 cursor-pointer inline-block">
                        <Icon :path="mdiAccountPlus" size="25"/>
                    </div>
                </div>
            </template>
        </DataTable>
    </div>
    <!-- changes for blank space too high -->
    <div class="my-32"></div>  
</admin-layout></template>