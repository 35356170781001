<script setup>
import UserLayout from '../../layout/UserLayout.vue';
import Icon from '@/components/Icon.vue';
import { mdiCircleOutline, mdiRecordCircleOutline, mdiCheckCircleOutline, mdiCloseCircleOutline, mdiStarFace, mdiLoading } from '@mdi/js';
import { computed, reactive, ref, onMounted } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import FormData from 'form-data';
import { furiTranslator, snaker } from '@/helper/helper';
let store = useStore();let route = useRoute();
let subCateId = route.params.id;
let subCateName = route.params.name;   
// for course enrollee
let test_id = route.params.test_id;
let courseId = route.params.courseId;
// for course enrollee
let isFinished = ref(false);
let score = ref(0);
let questions= reactive([]);
const boxColor = (check)=> check? 'bg-green-200' : 'bg-gray-200';
const gridManager = (list)=>{
    let css = 'grid-cols-2';
    list.filter( val=> {
        if(val.length>7){
            css = 'grid-cols-1'
            return;
        }
    });
    return css;
};
const finish = ()=>{
    let total = 0;
    let nCorrect = 0;
    questions.filter(val=>{
        total++;
        if(val.correct_ans==val.select){
            nCorrect++;
        }
    });
    if(total>0){
        score.value = Math.floor(nCorrect/total*60);
    }else{  
        score.value=0;
    }
    // course enrollee
    if(test_id!=null&&courseId!=null){
        // api call
        let formData = new FormData();
        formData.append('total_correct', nCorrect);
        formData.append('total_question', total);
        formData.append('test_id', test_id);
        formData.append('course_id', courseId);
        store.dispatch('takeTheExam',formData)
        .then(function(data){
            snaker('you score save successful!');
            console.log(data);
        }).catch((error)=>{
            snaker('something wrong!','error');
            console.log(error);
        });
    }
// course enrollee
    isFinished.value = true;
    let pos = document.getElementById("list");
    pos.scrollIntoView(true);
};
onMounted(async()=>{
    if(subCateId==null){
        router.push({name: 'UserTests'});
        return;
    }
    store.commit('setQuestions','reset');
    // await sleep(3000);
    store.dispatch('getQuestions',subCateId)
    .then(function(data){
        if(data.length>0){
            let temp = data.map(val=>{
                return {
                    "title": val.title,
                    "correct_ans": val.correct_ans,
                    "explain": val.explain,
                    "select": '',
                    "multiple": val.multiple
                }
            });
            Object.assign(questions,temp);
        }
    });
});
</script>
<template><user-layout :title="subCateName">
    <div class="container mx-auto mt-12">
        <div v-if="isFinished" class="animate-pulse text-right text-lg lg:text-xl">Score: {{score}}</div>
        <div v-for="item,x in questions" :key="x" class="py-8 select-none">
            <div class="flex items-start flex-row">
                <div :class="{'pt-2.5':( item.title.includes('＾')||item.title.includes('^') )}" class="w-12 pt-1">
                    ( {{ x+1 }} )
                </div>
                <div v-html="furiTranslator(item.title)" class="pb-2 select-none flex-1"></div>
            </div>
            <!-- Before Finish -->
            <div v-if="!isFinished" :class="gridManager(item.multiple)" class="grid lg:grid-cols-2 gap-4 lg:gap-6 mt-4">
                <div @click="questions[x].select=answer" v-for="answer,index in item.multiple" :key="index" :class="boxColor(item.select==answer)" class="p-2 lg:p-6 rounded-lg flex flex-row items-center cursor-pointer text-sm lg:text-lg">
                    <div class="flex flex-col justify-center">
                        <Icon v-if="item.select==answer" :path="mdiRecordCircleOutline" size="20" w="" h="" class="text-green-500 mr-2"/>
                        <Icon v-else :path="mdiCircleOutline" size="20" w="" h="" class="text-blue-400 mr-2"/>
                    </div>
                    <div class="">
                        {{answer}}
                    </div>
                </div>    
            </div>
            <!-- Finish -->
            <div v-else :class="gridManager(item.multiple)" class="grid lg:grid-cols-2 gap-4 lg:gap-6 mt-4">
                <div v-for="answer,index in item.multiple" :key="index" class="p-2 lg:p-6 rounded-lg flex flex-row items-center cursor-pointer text-sm lg:text-lg"
                    :class="item.select==answer&&item.select==item.correct_ans? 'bg-green-200': item.select==answer&&item.select!=item.correct_ans?'bg-red-200':'bg-gray-200'"
                >
                    <div class="flex flex-col justify-center">
                        <Icon v-if="answer==item.correct_ans" :path="mdiCheckCircleOutline" size="20" w="" h="" class="text-green-500 mr-2"/>
                        <Icon v-else :path="mdiCloseCircleOutline" size="20" w="" h="" class="text-red-400 mr-2"/>
                    </div>
                    <div class="">{{answer}}</div>
                </div>
                <!-- explain -->
                <div v-if="item.explain" class="flex flex-row items-center">
                    <Icon :path="mdiStarFace" size="25" class="mr-2 text-yellow-500"/>
                    <span>{{item.explain}}</span>
                </div>
            </div>
        </div>
        <!-- href="#top" -->
        <a v-if="!isFinished && questions.length>0" @click="finish" class="flex flex-row justify-center mt-6">
            <div class="w-fit rounded-md px-8 py-3 bg-primary hover:ring-1 ring-gray-500 text-white tracking-widest text-lg font-bold cursor-pointer select-none">Finish</div>
        </a>
        <div v-if="questions.length==0" class="flex flex-col items-center pt-20">
            <Icon :path="mdiLoading" size="40" w="0" h="0" class="animate-spin text-[#F98E04]"/>
            <div class="text-gray-500 mt-8 animate-pulse">Loading . . .</div>
        </div>
    </div>
    <div class="my-16 lg:my-32"></div>
</user-layout></template>