<script setup>
import AdminLayout from '@/views/layout/AdminLayout.vue';
import Addbar from '@/components/admin/Addbar.vue';
import Navbar from '@/components/admin/Navbar.vue';
import router from '@/router';
import {openModal,closeModal} from "jenesius-vue-modal";
import FormModel from '@/components/FormModel.vue'
import { ref, onMounted, computed, onBeforeUnmount } from 'vue';
import { isJsonStr } from '@/helper/helper'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import RichTextEditor from '@/components/RichTextEditor.vue';
import { mdiEye } from '@mdi/js';
import Icon from '@/components/Icon.vue';
let store = useStore();let route = useRoute();
let blog_id = route.params.id;
let content = ref();
let preview = ref(false);
let blog= computed(()=>{
    if(store.getters.getBlogs.length>0){
        let tmp = store.getters.getBlogs.filter( val=> val.id==blog_id );
        if(isJsonStr(tmp[0].subtitle)){
            content.value = JSON.parse(tmp[0].subtitle);
        }
        return tmp[0];
    }else{
        return null;
    }
});
const goRoute = (name,params,index)=>{
    let navs=[];
    for(let x=0;x<index+1;x++){
        navs = [...navs,store.getters.getBlogNavs[x]];
    }
    store.commit('setBlogNavs',navs);
    router.push({name: name, params: params});
}
onMounted(()=>{
    if(blog_id==null){
        router.push({name:'Blog'});
        return;
    }
    document.addEventListener("keydown", doSave);
});
onBeforeUnmount(()=>{
    document.removeEventListener("keydown", doSave);
});
const change = (val)=> content.value=val;
const save = ()=>{
    openModal(FormModel, {
        toggle: ()=>closeModal(),
        model: {
            title: 'Update Blog',
            form: [
                {
                    label: 'Blog Title',
                    type: 'text',
                    name: 'title',
                    placeholder: 'Burmese Blogger',
                    ref: ref(blog.value.title)
                },
                {
                    label: 'Feature Photo',
                    type: 'file',
                    name: 'img_url',
                    placeholder: 'Feature Photo',
                    ref: ref(null)
                },
                {
                    label: 'Public',
                    type: 'checkbox',
                    name: 'status',
                    placeholder: '',
                    ref: ref(blog.value.status==1?true:false)
                },
                {
                    label: '',
                    type: 'hidden',
                    name: 'id',
                    placeholder: '',
                    ref: ref(blog.value.id)
                },
                {
                    label: '',
                    type: 'hidden',
                    name: 'subtitle',
                    placeholder: '',
                    ref: ref(JSON.stringify(content.value))
                }
            ],
            action: {
                title: 'Save'
            }
        },
        submit: (formData)=>{
            store.dispatch('updateBlog',{formData:formData,callBack: ()=>{}});
        }
    });
};
// key event
const doSave= (e)=> {
    if (!(e.keyCode === 83 && e.ctrlKey)) {
    return;
    }
    e.preventDefault();
    save();
};
// key event
// render
const style = (obj)=>{
    var css = [];
    // size
    if(obj.hasOwnProperty('size')){
        if(obj.size=='small') css=[...css,'text-xs'];
        if(obj.size=='large') css=[...css,'text-xl'];
        if(obj.size=='huge')  css=[...css,'text-4xl'];
    }
    // size
    // style
    if(obj.hasOwnProperty('bold')) css=[...css,'font-bold'];
    if(obj.hasOwnProperty('italic')) css=[...css,'italic'];
    if(obj.hasOwnProperty('underline')) css=[...css,'underline'];
    if(obj.hasOwnProperty('strike')) css=[...css,'line-through'];
    // style
    // align
    if(obj.hasOwnProperty('align')){
        if(obj.align=='center') css=[...css,'text-center'];
        if(obj.align=='right') css=[...css,'text-right'];
        if(obj.align=='justify') css=[...css,'text-justify'];
    }
    // align
    // list
    if(obj.hasOwnProperty('list')){
        if(obj.list=='ordered') css = [...css,'ml-8 list-decimal'];
        if(obj.list=='bullet') css = [...css,'ml-8 list-disc'];
    }
    // list
    return css;
}
const tailwind = (delta)=>{
    if(delta==null) return;
    let html = '';
    let design = {
        div_class: '',
        contents: []
    };
    let data = []; 
    if(delta.hasOwnProperty('ops')){
        delta.ops.forEach( (element,index) => {
            if(element.insert.includes('\n')){
                design.div_class=element.hasOwnProperty('attributes')?style(element.attributes):'';
                let list = element.insert.split("\n");
                let first = true;
                list.forEach(ls => {
                    if(first){
                        design.contents.push({
                            class: '',
                            span: tab(ls)
                        });
                        data.push(design);
                        clear();
                        first=false;
                    }else{
                        design.contents.push({
                            class: '',
                            span: tab(ls)
                        });
                        data.push(design);
                        clear();
                    }
                });
                clear();
            }else{
                design.contents.push({
                    class: element.hasOwnProperty('attributes')?style(element.attributes):'',
                    span: tab(element.insert)
                });
                let nextObj = delta.ops[index+1];
                // if(nextObj.hasOwnProperty('attributes')){
                //     if(nextObj.attributes.hasOwnProperty('list')){
                //         data.push(design);
                //         clear();
                //     }
                // }
            }
        });
    }
    function tab(tab){
        if(tab.includes('\t')){
            let str='';
            let list = tab.split("\t");
            list.forEach(ans => {
                if(ans=='') str+='&nbsp&nbsp&nbsp&nbsp&nbsp';
                else str+=ans;
            });
            return str;
        }else{
            return tab;
        }
    }
    function clear(){
        design = {
            div_class: '',
            contents: []
        }
    };
    return data;
}
// render
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getBlogNavs" :goRoute="goRoute"/>
    <!-- <div v-if="blog" class="mx-8 md:mx-12 text-2xl flex flex-row items-center justify-center h-16">
        {{blog.title}}
    </div> -->
    <div v-show="!preview" class="mx-4 md:mx-12 relative h-64">
        <div v-if="blog" class="mx-8 md:mx-12 text-2xl flex flex-row items-center justify-center h-16">
            {{blog.title}}
        </div>
        <div class="absolute text-black h-11 right-0 flex flex-row items-center cursor-pointer">
            <div @click="preview=true" class="px-1 md:px-4 h-11 flex flex-row items-center cursor-pointer mr-1 text-blue-500">
                <Icon :path="mdiEye" size="35"/>
            </div>
            <div @click="save" class="px-2 md:px-4 h-11 flex flex-row items-center cursor-pointer bg-lime-500">Save</div>
        </div>
        <RichTextEditor :change="change" :content="content" class="bg-white text-black"/>
    </div>
    
    <div class="mt-14"></div>

    
    <div v-show="preview" v-if="blog" class="mx-4 md:mx-12 p-4 md:p-12 bg-gradient-to-tr from-red-400 via-pink-500 to-purple-500">
        <div class="flex flex-row justify-center">
            <div @click="preview=false" class="flex justify-center items-center mb-2 h-10 w-10 bg-red-500 text-2xl rounded-full animate-bounce shadow-md cursor-pointer">x</div>
        </div>
        <div class="block bg-white rounded-2xl pb-6 md:pb-10">
            <div class="flex flex-row mb-6 relative">
                <img :src="store.state.base+blog.img_url" class="w-full rounded-t-2xl overflow-hidden" alt="">
                <div class="absolute bottom-0 flex flex-row justify-start items-center w-full">
                    <div class="bg-indigo-200 opacity-50 px-8 py-2 text-black font-bold text-sm md:text-2xl">{{blog.title}}</div>
                </div>
            </div>

            <div v-for="(item, index) in tailwind(content)" :key="index" class="mx-4 md:mx-8 text-black">
                <div v-if="item.contents[0].span!=''" :class="item.div_class" class="text-sm">
                    <span class="leading-loose" v-html="content.span" :class="content.class" v-for="(content, index) in item.contents" :key="index"></span>
                </div>
                <div v-else >
                    <br>
                </div>
            </div>

        </div>
    </div>

    

    <div class="my-32"></div>
</admin-layout></template>