import axios from 'axios';

const state = {
    loading: false,
    userCourses:[],
};

const getters = { 
    getUserCourses: (state)=>state.userCourses,
    getLoading: (state)=>state.loading,
};

const actions = {
    async getUserCourses({commit,rootState}){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        state.loading=true;
        return axios.get(`${baseUri}${prefix}/student-course`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        })
        .then(data=>{
            commit('setUserCourses',data.data.data);
            state.loading=false;
        })
    },
    async takeTheExam({commit,rootState},formData){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        return axios.post(`${baseUri}${prefix}/scores`,
            formData,
            {
            headers: {
                'Authorization': 'Bearer '+ token,
                }
            }
        );
    },
    async onlineRegister({commit,rootState},formData){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        return axios.post(`${baseUri}${prefix}/online-register`,
            formData,
            {
            headers: {
                'Authorization': 'Bearer '+ token,
                }
            }
        );
    },
};

const mutations = {
    setUserCourses(state,courses){
        state.userCourses = courses;
    }
};

export default {
    state, getters, actions, mutations
}