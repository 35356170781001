<script setup>
    import { ref,onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    import { furiTranslator } from '@/helper/helper';
    import router from '@/router';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue'
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Card from '@/components/admin/Test/card.vue';
    import LoadingQuestion from '@/components/admin/Test/LoadingQuestion.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import { mdiPlus, mdiPlusBoxOutline, mdiLeadPencil, mdiDeleteEmpty, mdiPlayCircleOutline, mdiBookOpenPageVariantOutline, mdiChevronDoubleRight } from '@mdi/js';
    import Icon from '@/components/Icon.vue';

    const route = useRoute();
    const store = useStore();
    const subCategoryId = route.params.id;
    const actions = {
        create: (id,name) =>{
            const navs = [...store.getters.testGetNavs,{
                routeName: 'TestQuestionCreate',
                title: 'Create',
                params: {
                    name: name,
                    id: id
                },
            }];
            store.commit('setNavs',navs);
            router.push({name: 'TestQuestionCreate', params: { name: name, id: id }});
        },        
        edit: item => {
            const navs = [...store.getters.testGetNavs,{
                routeName: 'TestQuestionEdit',
                title: 'Edit',
                params: {
                    item: item,
                },
            }];
            store.commit('setNavs',navs);
            router.push({name: 'TestQuestionEdit', params: { item: JSON.stringify(item) }});
        },
        del: id =>  {
                openModal(FormModel, {
                toggle: ()=>closeModal(),
                model: {
                    title: 'Are you sure you want to delete this Question?',
                    form: null,
                    action: {
                        title: ''
                    }
                },
                submit: ()=>{
                    store.dispatch('deleteQuestion',id);
                }
            });
        }
        
    };
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.testGetNavs[x]];
        }
        store.commit(navs[0].routeName=='ClassRoom'?'setClassNavs':'setNavs',navs);
        router.push({name: name, params: params});
    }
    const add = ()=> actions.create(subCategoryId,'');
    onMounted(()=>{
        if(subCategoryId==null){
            router.push({name: 'Test'});
            return;
        }
        store.commit('setQuestions','reset')
        store.dispatch('getQuestions',subCategoryId);
    });
</script>
<template>
<admin-layout>
    <Navbar :navs="store.getters.testGetNavs" :goRoute="goRoute" />
    <Addbar label="Add Question" :add="add" />
    <div v-if="store.getters.getQuestions.length>0" class="px-8 md:px-12 py-12">
        <div v-for="item,index in store.getters.getQuestions" :key="index" class="py-8">
            <div class="flex items-start flex-row">
                <div :class="{'pt-6px':( item.title.includes('＾')||item.title.includes('^'))}" class="w-12">
                    ( {{ index+1 }} )
                </div>
                <div v-html="furiTranslator(item.title)" class="pb-2 select-none flex-1"></div>
                <!-- action -->
                <div class="hidden md:flex flex-row pr-5">
                    <div @click="actions.edit(item)" class="h-8 cursor-pointer ml-3 py1 px-2 rounded-md border-2 border-green-500 text-green-500 hover:text-green-400 hover:shadow-md select-none">
                        Edit  
                    </div>
                    <div @click="actions.del(item.id)" class="h-8 cursor-pointer ml-3 py1 px-2 rounded-md border-2 border-red-500 text-red-500 hover:text-red-400 hover:shadow-md select-none">
                        Delete
                    </div>
                </div>
                <!-- action -->
            </div>
            <!-- answer box -->
            <div class="px-5 mt-6 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6 select-none">
                <div 
                v-for="(answer,i) in item.multiple"
                v-bind:key="i"
                >
                <button
                    v-if="item.true!=answer"
                    class="flex flex-row w-full h-12 px-3 bg-gray-200 items-center justify-start rounded-md select-none">
                    <div class="pl-2 text-xs text-left text-black">{{answer}}</div>
                </button>
                <button 
                    v-if="item.true==answer"
                    class="flex flex-row w-full h-12 px-3 font-bold bg-green-300 items-center justify-start rounded-md select-none">
                    <div class="pl-2 text-xs　text-left text-black">{{answer}}</div>
                </button>
                </div>
            </div>
            <!-- explain -->
            <div class="text-sm py-4 px-5">
                {{item.explain}}
            </div>
            <!-- action -->
                <div class="flex md:hidden px-5 flex-row justify-center">
                    <div @click="actions.edit(item)" class="flex-1 text-center cursor-pointer  py-3 px-2 rounded-md border-2 border-green-500 text-green-500 hover:text-green-400 hover:shadow-md">
                        Edit
                    </div>
                    <div class="mx-4"></div>
                    <div @click="actions.del(item.id)" class="flex-1 text-center cursor-pointer  py-3 px-2 rounded-md border-2 border-red-500 text-red-500 hover:text-red-400 hover:shadow-md">
                        Delete
                    </div>
                </div>
            <!-- action -->
        </div>
    </div>
    <!-- if No Data -->
    <div v-else-if="store.getters.getQuestions==-1" class="text-center mt-10 font-bold">
        There is no data!
    </div>
    <!-- loading -->
    <div v-else >
        <LoadingQuestion :count="2"/>    
    </div>

    <div class="h-20"></div>
</admin-layout>
</template>