<template>
<div class="fixed bottom-0 w-full">
    <div class="flex flex-row w-full h-10 items-center justify-between md:justify-center pl-4 pr-4 md:pr-10 bg-gray-300 drop-shadow-md dark:bg-side-bg-dark">
        <div class="flex flex-row items-center justify-center w-full">
            Copyright@ 2022  Allright Reverse
        </div>
    </div>
</div>
</template>
<script>

</script>
<style>
    
</style>