<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import BlogCard from '@/components/BlogCard.vue';
    import router from '@/router';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue';
    import { ref, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import { mdiLoading } from '@mdi/js';
    import Icon from '@/components/Icon.vue';
    let store = useStore();let route = useRoute();
    const create = ()=>{
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Create New Blog',
                form: [
                    {
                        label: 'Blog Title',
                        type: 'text',
                        name: 'title',
                        placeholder: 'Burmese Blogger',
                        ref: ref('')
                    },
                    {
                        label: 'Feature Photo',
                        type: 'file',
                        name: 'img_url',
                        placeholder: 'Feature Photo',
                        ref: ref('')
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'user_id',
                        placeholder: '',
                        ref: ref(store.state.auth.id)
                    }
                ],
                action: {
                    title: 'Create Blog'
                }
            },
            submit: (formData)=>{
                store.dispatch('createBlog',{formData:formData,callBack:(blog)=>{
                    const navs = [
                        {
                            routeName: 'Blog',
                            title: 'Blog',
                            params: {},
                        },
                        {
                            routeName: 'BlogCreate',
                            title: 'Create Content',
                            params: {id:blog.id},
                        }
                    ];
                    store.commit('setBlogNavs',navs);
                    router.push({name: 'BlogCreate',params:{id:blog.id}});
                }});
            }
        });
    };
    const actions = {
        detail: (order)=>{
            // testing purpose
            const navs = [
                {
                    routeName: 'Blog',
                    title: 'Blog',
                    params: {},
                },
                {
                    routeName: 'BlogCreate',
                    title: 'Create Content',
                    params: {id:order.id},
                }
            ];
            store.commit('setBlogNavs',navs);
            router.push({name: 'BlogCreate',params:{id:order.id}});
            return;
            // testing purpose
        },
        del: (blog)=>{
            openModal(FormModel, {
                toggle: ()=>closeModal(),
                model: {
                    title: 'Are you sure you want to delete this Blog?',
                    form: null,
                    action: {
                        title: ''
                    }
                },
                submit: ()=>{
                    store.dispatch('deleteBlog',blog.id);
                }
            });
        },
    }
    onMounted(()=>{ 
        store.dispatch('checkUpdate');
        if(store.state.auth.role=='user' || store.state.auth.role=='student'){
            store.commit('toNamed',{name: 'HomePage'});return;
        }
        store.dispatch('getBlogs');
    })
</script>
<template><admin-layout>
    <div class="flex flex-row h-20 items-center pl-8 md:pl-12 text-xl cursor-pointer select-none">
        Blog
    </div>
    <Addbar label="Create Blog" :add="create"/>
    <div v-if="store.getters.getBlogs.length>0" class="mx-8 md:mx-12 mt-8 md:mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-6 cursor-pointer">
        <BlogCard v-for="item,index in store.getters.getBlogs" :key="index" :blog="item" :actions="actions"/>
    </div>
    <div v-else class="my-20 flex flex-row w-full justify-center"> 
        <div class="rounded-sm px-4 py-2 text-lg font-semibold flex flex-row items-center animate-pulse">
            <Icon :path="mdiLoading" size="25" class="animate-spin mr-2"/><div>Processing</div>
        </div>
    </div>
    <div class="my-32"></div>
    <!-- for copy link -->
    <input type="hidden" id="copy_link"/>
</admin-layout></template>