<script setup>
    import { mdiEye, mdiDeleteOutline, mdiFacebook, mdiNavigationVariant, mdiAccountCircle } from '@mdi/js';
    import Icon from '@/components/Icon.vue';
    import Copy from '@/components/Copy.vue';
    import Share from '@/components/Share.vue';
    import {useStore} from 'vuex';
    let store = useStore();
    let props = defineProps({
        blog:{
            type: Object,
            required: true
        },
        actions:{
            type: Object,
            required: true
        },
        user:{
            type: Boolean,
            required: false,
            default: false
        }
    });
    const actions = (blog)=>{
        let action = event.target.getAttribute("action");
        if(action=="del") props.actions.del(blog);
        else if(action=="share") return
        else props.actions.detail(blog);
    };
    // let view = (view)=> view>999? Math.round((view/1000)*100)/100 : view;
    let view = (view)=> view>999? (view/1000).toFixed(1) + ' k' : view;
    const description = "We will write blogs that will benefit students with knowledge of Japanese language.";
</script>
<template>
    <div @click="actions(blog)" class="bg-[#f6f6f6] rounded-xl flex flex-col text-black shadow-md shadow-gray-300">
        <!-- feature -->
        <img :src="store.state.base+blog.img_url" alt="image" class="rounded-t-xl">
        <div class="px-4 flex flex-col mb-4">
            <!-- Title -->
            <div class="overflow-y-hidden py-2 text-xl font-semibold mt-2">{{blog.title}}</div>
            <!-- Viewer -->
            <div class="flex flex-row mt-2 justify-between items-center">
                <div class="flex flex-row items-center justify-center px-4 py-2 w-fit bg-gradient-to-tr from-pink-500 to-pink-200 rounded-full shadow-md shadow-gray-400">
                    <Icon :path="mdiEye" size="25" class="mr-3 text-white"/>
                    <div class="tracking-widest text-green-900"><span class="font-semibold">{{view(blog.view)}}</span></div>
                </div>
                <div v-if="!user" action="del" class="flex flex-row items-center h-full">
                    <Icon action="del" :path="mdiDeleteOutline" size="35" w="10" h="10" class="bg-red-500 text-white p-1 rounded-full cursor-pointer shadow-lg shadow-gray-400"/>
                </div>
            </div>
            <!-- writer -->
            <div class="flex flex-row justify-start items-start mt-4">
                <span v-if="blog.author.photo_url">
                    <img :src="store.state.base+blog.author.photo_url" alt="photo" class="w-14 h-14 rounded-full shadow-md shadow-gray-400">
                </span>
                <span v-else class="flex flex-row items-center justify-center w-14 h-14 rounded-full text-gray-500 shadow-md shadow-gray-400">
                    <Icon :path="mdiAccountCircle" size="40" w="12" h="12"/>
                </span>
                <div class="flex flex-col ml-4 justify-center h-full">
                    <div class="tracking-wide leading-tight">
                        {{blog.author.name}}
                    </div>
                    <div class="text-sm text-gray-500 leading-tight">
                        {{blog.since}}
                    </div>
                </div>
            </div>
            <div v-if="blog.status==1" class="flex justify-end h-fit items-center flex-row mt-2">
                <div class="text-gray-600 text-md select-none">Share</div>
                <Share network="facebook" :title="blog.title" :description="description" :url="store.state.ownBase+`blogs/detail?id=${blog.id}`" class="flex flex-row items-center h-full mx-3">
                    <Icon action="share" :path="mdiFacebook" size="30" w="10" h="10" class="text-blue-700 w-7 h-7 rounded-full shadow-md shadow-gray-400"/>
                </Share>
                <Share network="telegram" :title="blog.title" :description="description" :url="store.state.ownBase+`blogs/detail?id=${blog.id}`" class="text-blue-700 flex flex-row items-center h-full">
                    <Icon action="share" :path="mdiNavigationVariant" size="10" w="" h="" class="text-white bg-sky-400 rounded-full p-2 shadow-md shadow-gray-400"/>
                </Share>
                <Copy action="share" :text="store.state.ownBase+`blogs/detail?id=${blog.id}`" id_name="#copy_link" class="ml-4 shadow-lg shadow-gray-300"/>
            </div>
            <div v-else class="text-gray-600 text-lg select-none text-center mt-4">Not Public</div>
        </div>
    </div>
</template>