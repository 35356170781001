<script setup>
    import { ref,reactive,onMounted } from 'vue';
    import { useStore } from 'vuex';
    import router from '@/router';
    import FormData from 'form-data';
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Card from '@/components/admin/Test/card.vue';
    import LoadingCard from '@/components/admin/Test/LoadingCard.vue';
    import { mdiPlus, mdiPlusBoxOutline, mdiLeadPencil, mdiDeleteEmpty, mdiPlayCircleOutline, mdiChartBar } from '@mdi/js';
    import Icon from '@/components/Icon.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue'
    const store = useStore();
    const actions = {
        detail: (id,name) =>{
            const navs = [
                {
                    routeName: 'Test',
                    title: 'Test',
                    params: {},
                },
                {
                    routeName: 'TestLevel',
                    title: name,
                    params: {
                        name: name,
                        id: id    
                    },
                }
            ];
            store.commit('setNavs',navs);
            router.push({name: 'TestLevel', params: { name: name, id: id }});
        },
        edit: (id,name) => edit(id,name),//alert(name+ ' edit '+id),
        del: id => deleteLevel(id)//,alert('delete '+id)
    };
    const toggleModel = ()=>closeModal();
    const add = ()=>{
        openModal(FormModel, {
            toggle:toggleModel,
            model: {
                title: 'Add New Level',
                form: [
                    {
                        label: 'New Level Name',
                        type: 'text',
                        name: 'name',
                        placeholder: 'N3',
                        ref: ref('')
                    },
                ],
                action: {
                    title: 'Add New Level'
                }
            },
            submit: (fromData)=>{
                // console.log(fromData);
                store.dispatch('addNewLevel',fromData);
            }
        });
    };
    const edit = (id,name)=>{
        openModal(FormModel, {
            toggle:toggleModel,
            model: {
                title: 'Edit Level',
                form: [
                    {
                        label: 'Edit Level Name',
                        type: 'text',
                        name: 'name',
                        autofocus: true,
                        placeholder: name,
                        ref: ref(name)
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'id',
                        placeholder: '',
                        ref: ref(id)
                    },
                ],
                action: {
                    title: 'Update Level'
                }
            },
            submit: (fromData)=>{
                // console.log(fromData);
                store.dispatch('editLevel',fromData);
            }
        });
    };
    const deleteLevel = (id)=>{
        openModal(FormModel, {
            toggle:toggleModel,
            model: {
                title: 'Are you sure you want to delete this Level?',
                form: null,
                action: {
                    title: ''
                }
            },
            submit: ()=>{
                // console.log(id);
                store.dispatch('deleteLevel',id);
            }
        });
    };

    onMounted(()=>{
        store.dispatch('checkUpdate');
        if(store.state.auth.role=='user' || store.state.auth.role=='student'){
            store.commit('toNamed',{name: 'HomePage'});return;
        }
        store.commit('setLevels','reset');
        store.dispatch('getLevels')
    });
</script>
<template>
<admin-layout>
    <div class="flex flex-row h-20 items-center pl-8 md:pl-12 text-xl cursor-pointer">
        Test
    </div>
    <Addbar label="Add Level" :add="add" />
    <div v-if="store.getters.getLevels.length>0" class="px-8 pt-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        <Card :icon="mdiChartBar" title="LEVEL" :datas="store.getters.getLevels" :actions="actions"/>
    </div>
    <div v-else-if="store.getters.getLevels==-1" class="text-center mt-10 font-bold">
        There is no data!
    </div>
    <div v-else class="px-8 pt-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        <LoadingCard :count="3"/>
    </div>
    <div class="h-20"></div>
</admin-layout>
</template>