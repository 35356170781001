<script setup>
import UserLayout from '@/views/layout/UserLayout.vue';
import { mdiLoading } from '@mdi/js';
import { ref,onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import FormData from 'form-data';
import Icon from '@/components/Icon.vue';
import { snaker } from '@/helper/helper';
let store = useStore();
let route = useRoute();
let courseId = route.query.id;
let name = ref(); let phone = ref(); let remark = ref();let loading = ref(false);let success = ref(false);
const submit = ()=>{
    const formData = new FormData();
    formData.append('phone',phone.value);
    formData.append('name',name.value);
    formData.append('remark',remark.value==null?'':remark.value);
    formData.append('course_id',courseId);
    loading.value=true;
    if(name.value==null){
        loading.value=false;
        snaker('Name - အမည် ထည့်သွင်းပါ','error');
        return;
    }else if(phone.value==null){
        loading.value=false;
        snaker('Phone - ဖုန်း ထည့်သွင်းပါ','error');
        return;
    }
    store.dispatch('onlineRegister',formData)
    .then(val=>{    
        snaker('enroll success');
        loading.value=false;
        success.value=true;
    })
    .catch(()=>{
        snaker('server error','error');
        loading.value=false;
    })
}
onMounted(()=>{
    store.dispatch('setDark',0);
});
const labelStyle="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300";
const inputStyle="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white";
</script>
<template><user-layout title="Enroll" back="UserCourses">
    <div v-if="success">
        <div class="flex flex-col items-center mt-6 md:mt-20 text-lg">
            <div class="my-2 md:my-4 text-green-700 bg-green-300 px-6 py-2 rounded-full">စာရင်းသွင်းမှု အောင်မြင်ပါသည်။</div>
            <div class="mt-4 md:my-4 text-gray-500 text-base">မကြာမှီပြန်လည်ဆက်သွယ်ပေးပါမည်။</div>
        </div>
        <div class="flex flex-row items-center justify-center mt-10 md:mt-14">
            <a href="/" type="button" class="text-white bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 shadow-lg shadow-gray-400">
                Home
            </a>
        </div>
        <div class="flex flex-row justify-center mt-8 md:mt-12">
            <router-link to="/" class="text-gray-600 hover:font-bold mr-2 md:mr-6">Home</router-link>|
            <router-link to="/courses" class="text-gray-600 hover:font-bold mx-2 md:mx-6">Courses</router-link>|
            <router-link to="/blogs" class="text-gray-600 hover:font-bold mx-2 md:mx-6">Blogs</router-link>|
            <router-link to="/tests" class="text-gray-600 hover:font-bold mx-2 md:mx-6">Tests</router-link>|
            <router-link to="/about" class="text-gray-600 hover:font-bold ml-2 md:ml-6">About</router-link>
        </div>
    </div>
    <div v-else class="container mx-auto">
        <div class="flex flex-col items-center my-6 text-lg">
            <div class="my-2 md:my-4">အမည်နှင့်ဖုန်းနံပါတ်ကိုထည့်သွင်းပြီး</div>
            <div class="my-2 md:my-4"><span class="font-bold">"submit"</span> ကိုနိပ်၍ စာရင်းပေးသွင်းနိုင်ပါသည်။</div>
        </div>
        <div class="text-gray-500 my-2 text-center">Enroll Information</div>
        <div class="md:mx-20 lg:mx-32 my-6 md:my-12 grid grid-cols-1 gap-x-12 gap-y-6 md:gap-y-12">
            <div>
                <label for="name" :class="labelStyle">Name - အမည်</label>
                <input v-model="name" type="text" name="name" id="name" :class="inputStyle" placeholder="Aung Aung">
            </div>
            <div>
                <label for="phone" :class="labelStyle">Phone - ဖုန်းနံပါတ်</label>
                <input v-model="phone" type="phone" name="phone" id="phone" :class="inputStyle" placeholder="09252393107">
            </div>
            <div>
                <label for="remark" :class="labelStyle">Remark - မှတ်ချက် (မထည့်လည်းရ)</label>
                <input v-model="remark" type="text" name="remark" id="remark" :class="inputStyle" placeholder="course name">
            </div>
        </div>
        <div class="flex flex-row items-center justify-center my-10 md:my-14">
            <button @click="submit" type="button" class="text-white bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 shadow-lg shadow-gray-400 flex flex-row items-center justify-center">
                <span>submit</span> <Icon v-if="loading" :path="mdiLoading" size="20" class="animate-spin select-none ml-2"></Icon>
            </button>
        </div>
    </div>
</user-layout></template>