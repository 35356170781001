<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue';
    import ClassDetailCard from '@/components/admin/ClassRoom/ClasssDetailCard.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import { isEmpty } from '@/helper/helper';
    import { ref, onMounted, computed } from 'vue';
    import { mdiClipboardEditOutline, mdiTrashCanOutline } from '@mdi/js';
    import Icon from '@/components/Icon.vue';
    import router from '@/router';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import DataTable from 'vue3-easy-data-table';
    import 'vue3-easy-data-table/dist/style.css';
    const headers = [
      { text: "ID", value: "id", sortable: true },
      { text: "Name", value: "name", width: 200, sortable: true },
      { text: "Score", value: "score", sortable: true  },
      { text: "Attendance", value: "attr", sortable: true },
      { text: "Payment", value: "payment", width: 200},
      { text: "Action", value: "action"}
    ];
    let store = useStore();
    let route = useRoute();
    let courseId= route.params.id;
    // search feature
    const searchField = ref('name');
    const searchValue = ref('');
    const searchKey = ['user_id','name','payment'];
    // search feature
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.getClassNavs[x]];
        }
        store.commit('setClassNavs',navs);
        router.push({name: name, params: params});
    };
    const getData = computed(()=>{
        if(store.getters.getClassDetail.length>0){
            let userBodies = store.getters.getClassDetail[0].data.map( (val)=>{
                let attr = 0;
                store.getters.getClassDetailAttr.filter( (ans)=>{
                    if(ans.id==val.id){
                        attr = ans.attendance;
                        return;
                    }
                })
                return {id:val.id,score:val.score,attendance:attr,payment:val.payment,name:val.name,course_id:val.course_id,user_id:val.user_id}
            })
            return userBodies;
        }else{
            return [];
        }
        
    });
    const addStudent = ()=>{
        const navs = {
                routeName: 'AddEnroll',
                title: 'Add',
                params: {
                    name: 'Add',
                    id: courseId
                },
            };
        store.commit('setClassNavs',[...store.getters.getClassNavs,navs]);
        router.push({name: 'AddEnroll', params: { name: 'Add', id: courseId }});
    }
    const edit = (item)=>{
        const options = [
            {value:'0',text:'Booking'},
            {value:'1',text:'Payment Success'},
        ];
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Edit Enroll',
                form: [
                    {
                        label: 'payment status',
                        type: 'select',
                        name: 'payment',
                        options: options,
                        ref: ref(item.payment==1?'1':'0')
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'id',
                        placeholder: '',
                        ref: ref(item.id)
                    },
                ],
                action: {
                    title: 'update'
                },
            },
            submit: (formData)=>{
                store.dispatch('updateEnroll',formData);
            }
        });
    };
    const del = (item)=>{
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Are you sure you want to delete this Student?',
                form: null,
                action: {
                    title: 'Delete'
                },
            },
            submit: ()=>{
                store.dispatch('deleteEnroll',item.id);
            }
        });
    };
    onMounted(()=>{
        if(courseId==null){
            router.push({name: 'ClassRoom'});
            return;
        }
        // store.dispatch('getClassDetail',detailId);
    });
const jinn = (ref)=> ref.length==1? 'h-36':ref.length==2? 'h-20': ref.length==3? 'h-12':'';
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getClassNavs" :goRoute="goRoute" />
    <Addbar label="Add Student" :add="addStudent"/>
    <div v-if="getData.length>0" class="px-4 md:px-12 py-8 z-0">
        <div class="flex flex-col md:flex-row justify-between mb-6">
            <div>
                <span class="mr-2">search field:</span>
                <select v-model="searchField" class="rounded-sm dark:bg-gray-600 py-1 pl-2">
                    <option v-for="v,i in searchKey" :key="i">{{v}}</option>
                </select>
            </div>
            <div class="flex flex-row mt-4 lg:mt-0">
                <span class="mr-4">search value: </span>
                <input type="text" v-model="searchValue" class="rounded-sm bg-gray-200 dark:bg-gray-600 py-1 px-2 w-40 md:w-60">
            </div>
        </div>

        <DataTable :headers="headers" :items="getData" :rows-per-page="10" :search-field="searchField" :search-value="searchValue">
            <template #id="{user_id}">
                <div class="flex flex-row items-center justify-start" :class="jinn(getData)">
                    {{user_id}}
                </div>
            </template>
            <template #name="{name}">
                <div class="flex flex-row items-center justify-start w-36">
                    {{name}}
                </div>
            </template>
            <template #score="{score}">
                    {{score}}
            </template>
            <template #attr="{attendance}">
                    {{attendance}} %
            </template>
            <template #payment="{payment}">
                <div class="flex flex-row items-center justify-start my-1">
                    <div v-if="payment=='0'" class="flex flex-row justify-center">
                        <div class="px-2 py-1 bg-yellow-300 dark:bg-yellow-200 rounded-full text-yellow-800 select-none">pending</div>
                    </div>
                    <div v-else class="flex flex-row justify-center">
                        <div class="px-2 py-1 bg-green-300 dark:bg-green-300 rounded-full text-green-800 select-none">success</div>
                    </div>
                </div>
            </template>
            <template #action="item">
                <div class="flex flex-row items-center">
                    <div @click="edit(item)" class="text-green-500 dark:text-green-300 mr-6 cursor-pointer inline-block">
                        <Icon :path="mdiClipboardEditOutline" size="25"/>
                    </div>
                    <div @click="del(item)" class="text-red-400 mr-6 cursor-pointer inline-block">
                        <Icon :path="mdiTrashCanOutline" size="25"/>
                    </div>
                </div>
            </template>
        </DataTable>
    </div>
</admin-layout></template>