<script setup>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { isEmpty } from '@/helper/helper';
import router from '@/router';
import Icon from '@/components/Icon.vue';
import Copy from '@/components/Copy.vue';
import { mdiFacebook } from '@mdi/js';
import Footer from '@/components/Footer.vue';
import CertificateCard from '@/components/admin/Account/CertifacteCard.vue';
let store = useStore();let  route = useRoute();
let error = ref(false);
let userId = route.query.id;
let imageUrl = computed(()=>{
        if(store.getters.getUser.photo_url){
            return store.state.base+store.getters.getUser.photo_url;
        }else{
            return null;
        }
    });
    let fbUrl = computed(()=>{
        if(store.getters.getUser.fb_link){
            return store.getters.getUser.fb_link;
        }else{
            return null;
        }
    });
    let profileUrl = computed(()=>{
        if(store.getters.getUser.id){
            return store.state.ownBase+`profile?id=${store.getters.getUser.id}`;
        }else{
            return 'error';
        }
    });
    let name = computed(()=>{
        if(store.getters.getUser.name){
            return store.getters.getUser.name;
        }else{
            return null;
        }
    });
    let since = computed(()=>{
        if(store.getters.getUser.since){
            return store.getters.getUser.since;
        }else{
            return null;
        }
    });
    let certificates = computed(()=>{
        if(store.getters.getUser.certificates){
            return store.getters.getUser.certificates;
        }else{
            return [];
        }
    });
onMounted(()=>{
    if(isEmpty(route.query)) {
        router.push({path: '/'});
        return;
    }
    // console.log(userId);
    store.dispatch('setDark',0);
    store.commit('setUser',{});
    store.dispatch('getUser',userId)
    .then(function(data){
        store.commit('setUser',data.data.user);   
    }).catch(function(ans){
        if(ans.response.status==404) error.value = true;
    });
});
</script>
<template>
    <div class="overflow-auto scrollbar-hide">
        <div class="container mx-auto">
            <!-- Title & Logo -->
            <div class="text-slate-700 hidden lg:block text-5xl mt-16 mb-4 uppercase text-center tracking-widest leading-normal font-domine font-semibold">Shizuoka Japanese Language Center</div>
            <div class="text-slate-700 hidden md:block lg:hidden text-4xl mt-14 mb-4 uppercase text-center tracking-widest leading-normal font-domine font-semibold">Shizuoka Japanese Language Center</div>
            <div class="text-slate-700 md:hidden text-2xl mt-10 mb-4 uppercase text-center tracking-widest leading-normal font-domine font-semibold">Shizuoka Japanese Language Center</div>
            <div class="flex flex-row justify-center">
                <img src="school.jpg" alt="logo" class="w-1/4 md:w-1/5 lg:w-1/6">
            </div>
            <div v-if="!isEmpty(store.getters.getUser)">  
                <!-- profile row -->
                <div class="flex flex-col md:flex-row justify-center w-full mt-12">
                    <div class="flex flex-row justify-center">
                        <img :src="imageUrl" alt="profile" class="w-36 h-36 md:w-40 md:h-40 lg:w-44 lg:h-44 rounded-md shadow-xl">
                    </div>
                    <div class="flex flex-col justify-center pt-6 md:pt-0 md:ml-6">
                        <span class="flex flex-row mt-2 items-center justify-center md:justify-start">
                            <div class="uppercase font-semibold text-xl md:text-2xl lg:text-3xl text-gray-700 dark:text-white"> {{name}} </div>
                            <a :href="fbUrl" target="_blank" v-if="fbUrl" class="flex flex-col justify-start ml-2">
                                <Icon :path="mdiFacebook" size="30" w="12" class="text-blue-500"/>
                            </a>
                        </span>
                        <span class="dark:text-gray-500 text-gray-600 text-center md:text-left mt-2 mb-3">Register Since: {{since}}</span>
                        <div class="flex flex-col md:flex-row justify-center md:justify-start items-center mt-2">
                            <div class="italic mr-2 text-blue-500 dark:text-blue-300 text-center text-sm md:text-base">
                                {{profileUrl}}
                            </div>
                            <div class="ml-2 mt-2 md:mt-0">
                                <Copy :text="profileUrl" id_name="#profile_link"/>
                            </div>
                            <input type="hidden" id="profile_link"/>
                        </div>
                    </div>
                </div>
                <!-- certis title -->
                <div v-if="certificates.length>0" class="text-center text-lg md:text-xl my-16 mx-8 md:mx-32 select-none">
                    This is to certify that <span class="inline-block font-bold">{{name}}</span> has attended and successfully completed following courses.
                </div>
                <div v-else class="text-center text-xl my-16 mx-8 md:mx-32">
                    no certificate right now!
                </div>
                <!-- certificates -->
                <div class="grid grid-cols-1 md:grid-cols-1 gap-8 md:gap-12 select-none">
                    <div v-for="(item, index) in certificates" :key="index" class="flex flex-row justify-center"><CertificateCard 
                        :admin="false"
                        :index="index"
                        :certisUrl="store.state.base+item.url"
                        :className="item.title"
                        :issueDate="item.issue_date"
                        :edit="()=>{}"
                        :del="()=>{}"
                        :item="item"
                    /></div>
                </div>
            </div>
            <div v-else-if="error" class="flex flex-col items-center justify-center mt-10">
                <div class="font-semibold text-red-500 text-xl">User Id={{userId}} Not Found</div>
                <div @click="router.push({path: '/'})" class="font-semibold text-primary mt-2 px-4 py-2 underline italic cursor-pointer">Return Home?</div>
            </div>
            <div v-else class="text-center text-xl text-gray-500 mt-12 tracking-wider font-domine">L o a d i n g    .   .   . </div>

            <div class="my-32"></div>
        </div>
        <Footer :class="{'fixed bottom-0':isEmpty(store.getters.getUser)}" :small="true"/>
    </div>
</template>