<script setup>
    import UserLayout from '../../layout/UserLayout.vue';
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import BlogCard from '@/components/user/BlogCard.vue';
    import Icon from '@/components/Icon.vue';
    import { mdiLoading } from '@mdi/js';
    import router from '@/router';
    import Footer from '@/components/Footer.vue';
    let store = useStore();
    let nextPageLoad = ref(false);
    const detail = ()=>router.push({path:'/blogs/detail',query:{id:2}});
    const seeMore = ()=>{
        if(!nextPageLoad.value){
            let nextUrl = store.getters.getNextPageUrl;
            nextPageLoad.value=true;
            store.dispatch('nextPage',nextUrl)
            .then(()=>nextPageLoad.value=false);
        }else{
            console.log('already running');
        }
    }
    const actions = {
        detail: (blog)=>{
            router.push({path:'/blogs/detail',query: {id: blog.id}})
        },
        del: (blog)=>{},
    }
    onMounted(()=>{
        store.dispatch('setDark',0);
        if(store.getters.getUserBlogs.length==0){
            store.dispatch('getBlogsFirstPage');
        }
    });
</script>
<template><user-layout title="Blogs" back="HomePage">
    <!-- 2xl:px-56 xl:px-32 md:px-28 sm:px-8 sm:py-8 -->
    <div class="container mx-auto">
        <div class="mt-12 md:mt-16"></div>
        <div v-if="store.getters.getUserBlogs.length>0">
            <BlogCard v-for="item,index in store.getters.getUserBlogs" :key="index" :blog="item" :actions="actions"/>
        </div>
        <div v-else class="flex flex-col items-center pt-20 pb-72">
            <Icon :path="mdiLoading" size="40" w="0" h="0" class="animate-spin text-[#F98E04]"/>
            <div class="text-gray-500 mt-8 animate-pulse">Loading . . .</div>
        </div>
        <div v-if="store.getters.getNextPageUrl" @click="seeMore" class="mt-12 flex flex-row items-center justify-center">
            <div class="flex flex-row items-center px-4 py-2 rounded-lg border-2 border-[#F98E04] cursor-pointer hover:shadow-xl shadow-gray-500 hover:ring-1 hover:font-semibold ring-sky-200 select-none">
                <div>see more</div>
                <Icon v-if="nextPageLoad" :path="mdiLoading" size="30" w="0" h="0" class="ml-2 animate-spin text-[#F98E04]"/>
            </div>
        </div>
        <div class="my-32"></div>
        <!-- for copy link -->
        <input type="hidden" id="copy_link"/>
    </div>
    <Footer :class="{'fixed bottom-0':store.getters.getUserBlogs.length==0}"/>
</user-layout></template>