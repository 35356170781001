<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import { ref,onMounted,computed } from 'vue';
    import { useStore } from 'vuex';
    import router from '@/router';
    import { useRoute } from 'vue-router';
    import { isEmpty } from '@/helper/helper'
    import Navbar from '@/components/admin/Navbar.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue';
    import Icon from '@/components/Icon.vue';
    import Copy from '@/components/Copy.vue';
    import { mdiClipboardEditOutline  , mdiTrashCanOutline, mdiContentCopy, mdiAccountCircle, mdiOpenInNew } from '@mdi/js';
    import DataTable from 'vue3-easy-data-table';
    import 'vue3-easy-data-table/dist/style.css';
    const headers = [
      { text: "ID", value: "id", sortable: true },
      { text: "Name", value: "name", width: 200, sortable: true },
      { text: "Phone", value: "phone" },
      { text: "Facebook Link", value: "fbLink"},
      { text: "Remark", value: "remark", width: 200},
      { text: "Action", value: "action"}
    ];
    const route = useRoute();
    const store = useStore();
    const roleId = route.params.id;
    const roleName = route.params.name;
    // search feature
    const searchField = ref('name');
    const searchValue = ref('');
    const searchKey = ['id','name','phone'];
    // search feature
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.getAccountNavs[x]];
        }
        store.commit('setAccountNavs',navs);
        router.push({name: name, params: params});
    }
    const create = () => {
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: `Create New ${roleName} Account`,
                form: [
                    {
                        label: 'User Name *',
                        type: 'text',
                        name: 'name',
                        placeholder: 'Mg Mg',
                        ref: ref('')
                    },
                    {
                        label: 'Phone *',
                        type: 'text',
                        name: 'phone',
                        placeholder: '09XXXXXXXXX',
                        ref: ref('')
                    },
                    {
                        label: 'Password *',
                        type: 'password',
                        name: 'password',
                        placeholder: '••••••••',
                        ref: ref('12345678')
                    },
                    {
                        label: 'Remark - Optional (မထည့်လည်းရ)',
                        type: 'text',
                        name: 'remark',
                        placeholder: 'New Student',
                        ref: ref('')
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'role_id',
                        placeholder: '',
                        ref: ref(roleId)
                    },
                ],
                action: {
                    title: 'Create Account'
                }
            },
            submit: (fromData)=>{
                // console.log(fromData);
                store.dispatch('createAccount',fromData);
            }
        });
    };
    const edit = (user) => {
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: `Edit ${user.name}'s Profile`,
                form: [
                    {
                        label: 'User Name *',
                        type: 'text',
                        name: 'name',
                        placeholder: 'Mg Mg',
                        ref: ref(user.name)
                    },
                    {
                        label: 'Phone *',
                        type: 'text',
                        name: 'phone',
                        placeholder: '09780132792',
                        ref: ref(user.phone)
                    },
                    {
                        label: 'Password *',
                        type: 'password',
                        name: 'password',
                        placeholder: '••••••••',
                        ref: ref('')
                    },
                    {
                        label: 'Profile Photo - Optional (မထည့်လည်းရ)',
                        type: 'file',
                        name: 'photo_url',
                        placeholder: 'Upload Accounts Photo',
                        ref: ref('')
                    },
                    {
                        label: 'Facebook Url - Optional (မထည့်လည်းရ)',
                        type: 'text',
                        name: 'fb_link',
                        placeholder: 'https://www.facebook.com',
                        ref: ref(user.fb)
                    },
                    {
                        label: 'Remark - Optional (မထည့်လည်းရ)',
                        type: 'text',
                        name: 'remark',
                        placeholder: 'New Student',
                        ref: ref(user.remark)
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'user_id',
                        placeholder: '',
                        ref: ref(user.id)
                    }
                ],
                action: {
                    title: 'Edit Account'
                }
            },
            style: 'h-3/4',
            submit: (fromData)=>{
                // console.log(fromData);
                store.dispatch('updateAccount',fromData);
            }
        });
    };
    const del = (user) => {
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: `Are you sure you want to delete this Account?`,
                form: null,
                action: {
                    title: ''
                }
            },
            submit: ()=>{
                store.dispatch('deleteAccount',user);
            }
        });
    };
    const profile = (id,name)=>{
        const navs = [
            ...store.getters.getAccountNavs,
            {
                routeName: 'AccountProfile',
                title: name,
                params: {
                    name: name,
                    id: id
                },
            }
        ];
        store.commit('setAccountNavs',navs);
        router.push({name: 'AccountProfile', params: { name: name, id: id }});
    };
    const getData = computed(()=>{
        if(!isEmpty(route.params)) {
        let currentUser =  store.getters.getUsers.filter( (val)=>val.id == roleId );
        let userBodies = currentUser[0].data.map( (val)=>{
            return {id:val.id,role_id:val.role_id,name:val.name,photoUrl:val.photo_url,phone:val.phone,fb:val.fb_link,remark:val.remark}
        })
        return userBodies;
        }else{
            return [];
        }
    });
    onMounted(()=>{
        if(isEmpty(route.params)) {
            router.push({name: 'Account'});
            return;
        }
        let currentUser =  store.getters.getUsers.filter( (val)=>val.id == roleId );
        let userBodies = currentUser[0].data.map( (val)=>{
            return {id:val.id,name:val.name,photoUrl:val.photo_url,phone:val.phone,fb:val.fb_link,remark:val.remark}
        })
        // data.value.bodies=userBodies;
    });
const jinn = (ref)=> ref.length==1? 'h-36':ref.length==2? 'h-20': ref.length==3? 'h-12':'';
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getAccountNavs" :goRoute="goRoute" />
    <Addbar label="Create Account" :add="create"/>
    <div class="px-4 md:px-12 py-8 z-0">
        <div class="flex flex-col md:flex-row justify-between mb-6">
            <div>
                <span class="mr-2">search field:</span>
                <select v-model="searchField" class="rounded-sm dark:bg-gray-600 py-1 pl-2">
                    <option v-for="v,i in searchKey" :key="i">{{v}}</option>
                </select>
            </div>
            <div class="flex flex-row mt-4 lg:mt-0">
                <span class="mr-4">search value: </span>
                <input type="text" v-model="searchValue" class="rounded-sm bg-gray-200 dark:bg-gray-600 py-1 px-2 w-40 md:w-60">
            </div>
        </div>

        <DataTable :headers="headers" :items="getData" :rows-per-page="10" :search-field="searchField" :search-value="searchValue" >
            <template #id="{id}">
                <div class="flex flex-row items-center justify-start" :class="jinn(getData)">
                    {{id}}
                </div>
            </template>
            <template #name="{name,photoUrl}">
                <div class="flex flex-row items-center justify-start w-52">
                    <span v-if="photoUrl">
                        <img :src="store.state.base+photoUrl" alt="photo" class="w-12 h-12 rounded-full my-1 shadow-md shadow-gray-400">
                    </span>
                    <span v-else class="flex flex-row items-center justify-center w-12 h-12 rounded-full text-gray-500 my-1 shadow-md shadow-gray-400">
                        <Icon :path="mdiAccountCircle" size="60" w="12" h="12"/>
                    </span>
                    <span class="ml-2">{{ name }}</span>  
                </div>
            </template>
            <template #phone="{phone}">
                <div class="flex flex-row items-center justify-start">
                    {{phone}}
                </div>
            </template>
            <template #fbLink="{fb}">
                <div class="flex flex-row items-center justify-start">
                    <div v-if="fb" class="flex flex-row items-center justify-center">
                        <Copy :text="fb" id_name="#fb_link"/>
                    </div>
                </div>
            </template>
            <template #remark="{remark}">
                <div class="flex flex-row items-center justify-center w-52">
                    {{remark}}
                </div>
            </template>
            <template #action="item">
                <div class="flex flex-row items-center justify-center">
                    <div @click="edit(item)" class="text-green-500 dark:text-green-300 mr-6 cursor-pointer inline-block">
                        <Icon :path="mdiClipboardEditOutline" size="25"/>
                    </div>
                    <div @click="del(item)" class="text-red-400 mr-6 cursor-pointer inline-block">
                        <Icon :path="mdiTrashCanOutline" size="25"/>
                    </div>
                    <div @click="profile(item.id,item.name)" class="mr-6 cursor-pointer inline-block">
                        <Icon :path="mdiOpenInNew" size="25"/>
                    </div>
                </div>
            </template>
        </DataTable>
        <input type="hidden" id="fb_link"/>
    </div>
    <div class="h-20"></div>
</admin-layout></template>