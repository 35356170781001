<script setup>
import Icon from '@/components/Icon.vue';
import { mdiFacebook, mdiAccountCircle } from '@mdi/js';
import Copy from '@/components/Copy.vue';
import VueQR from 'vue-qr/src/packages/vue-qr.vue';
const src = "../../../school.jpg";
let props = defineProps({
    imageUrl:{
        type: String,
        default: null
    },
    profileUrl:{
        type: String,
        required: true,
    },
    fbUrl:{
        type: String,
        default: null
    },
    name:{
        type: String,
        default: null
    },
    since:{
        type: String,
        default: null
    },
});
</script>
<template>
    
    <div class="flex flex-col md:flex-row md:px-20 mt-16">
        <div class="flex-1 flex flex-row justify-center md:justify-center">
            <img v-if="imageUrl" class="w-60 h-60 rounded-full" :src="imageUrl" alt="photo">
            <div v-else class="w-60 h-60 rounded-full flex flex-row items-center justify-center bg-gray-300 dark:bg-gray-500">
                <Icon :path="mdiAccountCircle" size="120" w="26" />
            </div>
        </div>
        <div class="flex-1 flex flex-col justify-center pt-6 md:pt-0">
            <span class="flex flex-row mt-2 items-center justify-center md:justify-start">
                <div class="uppercase font-semibold text-4xl text-gray-700 dark:text-white"> {{name}} </div>
                <a :href="fbUrl" target="_blank" v-if="fbUrl" class="flex flex-col justify-start ml-2">
                    <Icon :path="mdiFacebook" size="30" w="12" class="text-blue-500"/>
                </a>
            </span>
            <span class="dark:text-gray-500 text-center md:text-left mt-2">Register Since: {{since}}</span>
            <div class="flex flex-row justify-center md:justify-start items-center mt-2">
                <div class="italic mr-2 text-blue-500 dark:text-blue-300">
                    {{profileUrl}}
                </div>
                <div>
                    <Copy :text="profileUrl" id_name="#profile_link"/>
                </div>
                <input type="hidden" id="profile_link"/>
            </div>
        </div>
        <div class="flex flex-col justify-center pt-4 md:pt-0">
            <div class="flex flex-row justify-center md:justify-end">
            <div class="border-4 border-purple-400 dark:border-purple-600 rounded-xl w-32 cursor-pointer">
                <VueQR 
                    :bgSrc="src" 
                    :logoSrc="src" 
                    :text="profileUrl" 
                    :size="200" 
                    :margin="20"
                    :dotScale="0.6"
                    class="rounded-lg"
                    >
                </VueQR>
            </div>
            </div>
        </div>
    </div>
</template>