<script setup>
import "jquery/dist/jquery.min.js";
import $ from "jquery";
import { onMounted, ref } from 'vue';
import "./slick/slick.js";
import "./slick/slick-theme.css";
import "./slick/slick.css";
let props = defineProps({
    speed: {
        type: Number,
        required: false,
        default: 5000,
    },
    autoplay: {
        type: Boolean,
        required: false,
        default: true,
    },
});
let items = ref(null);
let current = ref(null);
let first = ref(true);
let change = ()=>{
    let temp = current.value;
    if(window.innerWidth<640){
        current.value=1;
        items.value=1;
    }else if(window.innerWidth<1000){
        current.value=2;
        items.value=2;
    }else{
        current.value=3;
        items.value=3;
    }
    
    if(current.value!=temp){
        if(first.value){
            first.value=false;
        }else{
            $('.autoplay').slick('unslick');
        }
        $('.autoplay').slick({
            slidesToShow: items.value,
            slidesToScroll: 1,
            autoplay: props.autoplay,
            autoplaySpeed: props.speed,
        });
    }
}
onMounted( ()=>{
    window.addEventListener("resize", change);
    $(document).ready(()=>{
        change();
    });
});
</script>
<template>
    <div class="autoplay">
        <slot/>
    </div>
</template>
<!--
Docs 
    1. required slick folder


-->