import { createRouter, createWebHistory } from "vue-router";

import Dashboard from '../views/admin/Dashboard.vue';
// Account
import Account from '../views/admin/account/Account.vue';
import AccountDetail from '../views/admin/account/AccountDetail.vue';
import AccountProfile from '../views/admin/account/AccountProfile.vue';
// Account

// Class Room
import ClassRoom from '../views/admin/class_room/ClassRoom.vue';
import ClassDetail from '../views/admin/class_room/ClassDetail.vue';
import ClassEnroll from '../views/admin/class_room/enroll/ClassEnroll.vue';
import AddEnroll from '../views/admin/class_room/enroll/AddEnroll.vue';
import ClassAttendance from '../views/admin/class_room/attendance/ClassAttendance.vue';
import CreateAttendance from '../views/admin/class_room/attendance/CreateAttendance.vue';
import ClassExam from '../views/admin/class_room/test/ClassExam.vue';
import AddExam from '../views/admin/class_room/test/AddExam.vue';
import ExamRoom from '../views/admin/class_room/test/ExamRoom.vue';
import Announce from '../views/admin/class_room/announce/Announce.vue';
// Class Room
// Order
import EnrollOrders from '../views/admin/order/EnrollOrders.vue';
// Order
// Test
import Test from '../views/admin/test/Test.vue';
import TestLevel from '../views/admin/test/TestLevel.vue';
import TestCategory from '../views/admin/test/TestCategory.vue';
import TestQuestion from '../views/admin/test/TestQuestion.vue';
import TestQuestionCreate from '../views/admin/test/TestQuestionCreate.vue';
import TestQuestionEdit from '../views/admin/test/TestQuestionEdit.vue';
// Test
// blog
import Blog from '../views/admin/blog/Blog.vue';
import BlogCreate from '../views/admin/blog/BlogCreate.vue';
// blog
import Profile from '../views/admin/Profile.vue';
import LoginPage from '../views/LoginPage.vue';

// user
import HomePage from '../views/user/HomePage.vue';
    // tests
    import UserTests from '../views/user/tests/UserTests.vue';
    import UserTestsCategory from '../views/user/tests/UserTestsCategory.vue';
    import UserTestDetail from '../views/user/tests/UserTestDetail.vue';
    // tests
    // blogs
    import UserBlog from '../views/user/blogs/UserBlog.vue';
    import UserBlogDetail from '../views/user/blogs/UserBlogDetail.vue';
    // blogs
    // course
    import UserCourses from '../views/user/course/UserCourses.vue';
    import UserRegistration from '../views/user/course/UserRegistration.vue';
    // course
    // profile
    import UserProfile from '../views/user/profile/UserProfile.vue';
    // profile
    // ClassRooms
    import ClassRooms from '../views/user/class_room/ClassRooms.vue';
    import Room from '../views/user/class_room/Room.vue';
    import UserExamRoom from '../views/user/class_room/UserExamRoom.vue';
    // ClassRooms
    // public profile
    import PublicProfile from '../views/user/profile/PublicProfile.vue';
    // public profile
    // about page
    import About from '../views/user/about/about.vue';
    // about page
// user

const routes = [
// user app
{
    path: '/',
    name: 'HomePage',
    component: HomePage
},
    // tests
    {
        path: '/tests',
        name: 'UserTests',
        component: UserTests
    },
    {
        path: '/tests/category',
        name: 'UserTestsCategory',
        component: UserTestsCategory
    },
    {
        path: '/tests/detail',
        name: 'UserTestDetail',
        component: UserTestDetail
    },
    // tests
    // blogs
    {
        path: '/blogs',
        name: 'UserBlog',
        component: UserBlog
    },
    {
        path: '/blogs/detail',
        name: 'UserBlogDetail',
        component: UserBlogDetail
    },
    // blogs
    // course
    {
        path: '/courses',
        name: 'UserCourses',
        component: UserCourses
    },
    {
        path: '/enroll',
        name: 'UserRegistration',
        component: UserRegistration
    },
    // course
    // profile
    {
        path: '/user-profile',
        name: 'UserProfile',
        component: UserProfile
    },
    // profile
    // ClassRooms
    {
        path: '/user-classes',
        name: 'ClassRooms',
        component: ClassRooms
    },
    {
        path: '/user-room',
        name: 'Room',
        component: Room
    },
    {
        path: '/user-examroom',
        name: 'UserExamRoom',
        component: UserExamRoom
    },
    // ClassRooms
    // profile(public)
    {
        path: '/profile',
        name: 'PublicProfile',
        component: PublicProfile
    },
    // profile(public)
    // about
    {
        path: '/about',
        name: 'About',
        component: About
    },
    // about
// user app
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
    // {
    //     path: '/',
    //     redirect: '/admin/dashboard',
    // },
    {
        path: '/admin/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    // Account
    {
        path: '/admin/account',
        name: 'Account',
        component: Account
    },
    {
        path: '/admin/account/detail',
        name: 'AccountDetail',
        component: AccountDetail
    },
    {
        path: '/admin/account/detail/profile',
        name: 'AccountProfile',
        component: AccountProfile
    },
    // Account

    // Class Room
    {
        path: '/admin/class',
        name: 'ClassRoom',
        component: ClassRoom
    },
    {
        path: '/admin/class/detail',
        name: 'ClassDetail',
        component: ClassDetail
    },
    {
        path: '/admin/class/detail/enroll',
        name: 'ClassEnroll',
        component: ClassEnroll
    },
    {
        path: '/admin/class/detail/enroll/add',
        name: 'AddEnroll',
        component: AddEnroll
    },
    {
        path: '/admin/class/detail/attendance',
        name: 'ClassAttendance',
        component: ClassAttendance
    },
    {
        path: '/admin/class/detail/attendance/create',
        name: 'CreateAttendance',
        component: CreateAttendance
    },
    {
        path: '/admin/class/detail/exam',
        name: 'ClassExam',
        component: ClassExam
    },
    {
        path: '/admin/class/detail/exam/add',
        name: 'AddExam',
        component: AddExam
    },
    {
        path: '/admin/class/detail/exam/room',
        name: 'ExamRoom',
        component: ExamRoom
    },
    {
        path: '/admin/class/detail/announce',
        name: 'Announce',
        component: Announce
    },
    // Class Room
    // Online Registration
    {
        path: '/admin/order',
        name: 'EnrollOrders',
        component: EnrollOrders
    },
    // Online Registration
    // test
    {
        path: '/admin/test',
        name: 'Test',
        component: Test
    },
    {
        path: '/admin/test/level',
        name: 'TestLevel',
        component: TestLevel
    },
    {
        path: '/admin/test/categories',
        name: 'TestCategory',
        component: TestCategory
    },
    {
        path: '/admin/test/questions',
        name: 'TestQuestion',
        component: TestQuestion
    },
    {
        path: '/admin/test/questions/create',
        name: 'TestQuestionCreate',
        component: TestQuestionCreate
    },
    {
        path: '/admin/test/questions/edit',
        name: 'TestQuestionEdit',
        component: TestQuestionEdit
    },
    // Blog
    {
        path: '/admin/blog',
        name: 'Blog',
        component: Blog
    },
    {
        path: '/admin/blog/create',
        name: 'BlogCreate',
        component: BlogCreate
    },
    // Blog
    {
        path: '/admin/profile',
        name: 'Profile',
        component: Profile
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router