<script setup>
    import UserLayout from '../layout/UserLayout.vue';
    import { onMounted } from 'vue';
    import { useStore } from 'vuex';
    import Icon from '@/components/Svg.vue';
    import SliderCarousel from '@/components/SliderCarousel.vue';
    import { mdiArrowRight, mdiDiamondStone, mdiCheckDecagramOutline, mdiStarOutline, mdiStar, mdiCheckboxMarkedCircleOutline } from '@mdi/js';
    import { mdiMapMarkerRadius, mdiPhoneInTalk, mdiEmail, mdiFacebook } from '@mdi/js';
    import Review from '@/components/Review.vue';
    import { snaker, furiTranslator } from '@/helper/helper';
    import Footer from '@/components/Footer.vue';
    import constData from '@/const/home';
    let store = useStore();
    // const getGeo = ()=>store.dispatch('getGeo');
    onMounted(()=>{
        store.dispatch('setDark',0);
        document
        .querySelector('#myFrame')
        .addEventListener('scroll', e => {
            console.log('scroll');
        });
    });
</script>
<template><user-layout>
<div class="container mx-auto mb-32">
    <!-- <div @click="getGeo" class="my-32 text-center">Hello Geo</div> -->
    <!-- hero section -->
    <div class="flex flex-col-reverse md:flex-row mt-12 md:mt-32 z-10 mb-36">
        <div class="flex-1 flex flex-col justify-center mt-12 md:mt-0">
            <div class="text-xl md:text-4xl font-bold leading-loose tracking-widest text-center md:text-left">
                {{constData.hero.title}}
            </div>
            <div class="text-gray-600 text-center md:text-left mt-6">
                <Icon :path="mdiCheckDecagramOutline" size="20" class="text-yellow-700"/> {{constData.hero.subTitle}}
            </div>
            <a :href="constData.hero.link" target="blank" class="flex flex-row mt-8 justify-center md:justify-start">
                <div class="bg-primary px-7 py-4 rounded-xl mr-4 md:mr-6 text-white font-semibold cursor-pointer hover:bg-white hover:text-primary hover:ring-2 hover:ring-primary shadow-lg shadow-gray-400">Get started</div>
            </a>
        </div>
        <div class="flex flex-row flex-1 relative justify-center">
            <div class="rounded-b-[60px] rounded-t-[120px] md:rounded-t-[100px] bg-white w-60 h-72 md:w-96 md:h-104 rotate-[10deg] overflow-hidden z-30 shadow-lg shadow-gray-400">
                <div class="w-60 h-60 md:w-96 md:h-96 rounded-[60px] bg-purple-400 absolute bottom-0 flex flex-col">
                    <div class="w-28 h-28 md:w-32 md:h-32 bg-sky-300 rounded-full absolute -bottom-6 -right-6 z-0"></div>
                </div>
                <img :src="constData.hero.photoUrl" alt="photo" class="h-80 md:h-112 -rotate-[10deg] z-30">
            </div>
            <div class="h-14 w-14 md:h-0 md:w-0 lg:h-20 lg:w-20 rounded-full bg-[#fdda5f] absolute left-6 top-28 md:-left-8 md:top-32 lg:left-12 lg:top-36 z-40 shadow-md shadow-gray-300"></div>
            <div class="sm:h-0 sm:w-0 lg:h-24 lg:w-24 rounded-2xl rotate-[45deg] bg-pink-300 absolute -right-12 top-32 shadow-md shadow-gray-300"></div>
            <Icon :path="mdiDiamondStone" size="30" class="top-32 right-0 lg:right-9 absolute text-gray-500 animate-bounce"/>
        </div>
    </div>
    <!-- course -->
    <div class="flex flex-col lg:flex-row">
        <div class="flex-1 flex flex-row justify-center h-80">
            <img :src="constData.course.photoUrl" alt="course" class="shadow-lg shadow-gray-400 rounded-2xl">
        </div>
        <div class="flex-1 flex flex-row justify-center items-center pt-6 lg:pt-0">
            <div class="flex flex-col items-center lg:items-start">
                <div class="flex flex-row mb-6">
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                </div>
                <div class="text-xl text-center lg:text-left lg:text-3xl leading-loose font-bold select-none">{{constData.course.title}}</div>
                <div class="text-lg text-center lg:text-left lg:text-xl text-primary leading-loose mt-2 select-none">{{constData.course.subTitle}}</div>
                <router-link to="/courses">
                    <div class="px-7 mt-6 py-3 rounded-xl ring-2 ring-primary cursor-pointer text-primary hover:bg-primary hover:text-white font-semibold shadow-lg shadow-gray-400 w-fit select-none">Check Now</div>
                </router-link>
            </div>
        </div>
    </div>
    <!-- blog -->
    <div class="flex flex-col-reverse lg:flex-row mt-32">
        <div class="flex-1 flex flex-row justify-center items-center pt-6 lg:pt-0">
            <div class="flex flex-col items-center lg:items-start">
                <div class="flex flex-row mb-6">
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                </div>
                <div class="text-xl text-center lg:text-left lg:text-3xl leading-loose font-bold">{{constData.blog.title}}</div>
                <div class="text-lg text-center lg:text-left lg:text-xl text-primary leading-loose mt-2">{{constData.blog.subTitle}}</div>
                <router-link to="/blogs">
                    <div class="px-7 mt-6 py-3 rounded-xl ring-2 ring-primary cursor-pointer text-primary hover:bg-primary hover:text-white font-semibold shadow-lg shadow-gray-400 w-fit">Read Now</div>
                </router-link>
            </div>
        </div>
        <div class="flex-1 flex flex-row justify-center h-80">
            <img :src="constData.blog.photoUrl" alt="course" class="shadow-lg shadow-gray-400 rounded-2xl">
        </div>
    </div>
    <!-- test -->
    <div class="flex flex-col lg:flex-row mt-32">
        <div class="flex-1 flex flex-row justify-center h-80 dark:bg-white">
            <img :src="constData.test.photoUrl" alt="course" class="shadow-lg shadow-gray-400 rounded-2xl">
        </div>
        <div class="flex-1 flex flex-row justify-center items-center pt-6 lg:pt-0">
            <div class="flex flex-col items-center lg:items-start">
                <div class="flex flex-row mb-6">
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                    <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
                </div>
                <div class="text-xl text-center lg:text-left lg:text-3xl leading-loose font-bold">{{constData.test.title}}</div>
                <div class="text-lg text-center lg:text-left lg:text-xl text-primary leading-loose mt-2">{{constData.test.subTitle}}</div>
                <router-link to="/tests">
                    <div class="px-7 mt-6 py-3 rounded-xl ring-2 ring-primary cursor-pointer text-primary hover:bg-primary hover:text-white font-semibold shadow-lg shadow-gray-400 w-fit">Test Now</div>
                </router-link>
            </div>
        </div>
    </div>

    <!-- divider -->
    <div class="flex flex-row justify-center my-12 lg:my-20">
        <div class="h-0.5 w-2/3 bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
    </div>

    <!-- our service -->
    <div>
        <div class="flex flex-row justify-center mb-12 lg:mb-4 font-domine font-semibold text-3xl text-primary text-center lg:text-left">Our Service</div>
    </div>
    <!-- seminar -->
    <div class="flex flex-col-reverse lg:flex-row lg:items-end mt-24">
        <div class="flex-1 flex flex-row justify-center items-center pt-6 lg:pt-0">
            <div class="flex flex-col items-center lg:items-start">
                <div class="text-xl text-center lg:text-left lg:text-3xl leading-loose font-bold">SEMINAR</div>
                <div class="text-lg text-center lg:text-left lg:text-xl text-black leading-loose mt-2">Training Visa, Student Visa, IT နှင့်အလုပ်အကိုင်အခွင့်အလမ်းများအတွက်</div>
                <div class="flex flex-col items-start">
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        ဂျပန်နိုင်ငံအသိမှတ်ပြု ITPEC Exam အကြောင်း
                    </div>
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        On job training ဆိုတာ ဘယ်လိုမျိုးလဲ?
                    </div>
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        လုပ်ငန်းအမျိုးအစားအလိုက် ဂျပန်စာ Level လိုအပ်ချက်များ
                    </div>
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        ဂျပန်ယဉ်ကျေးမှုနှင့် ဆောင်ရန် ရှောင်ရန်များ
                    </div>
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        အနာဂတ်အတွက် ရရှိနိုင်မယ့်အခွင့်အရေးများအကြောင်း
                    </div>
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        ကြိုတင်ပြင်ဆင်ထားရန် လိုအပ်ချက်များ
                    </div>
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        ကုန်ကျစရိတ်များ . . .
                    </div>
                </div>
                <a href="https://www.facebook.com/Shizuoka-Japanese-Language-Center-1763915927007452" target="blank">
                    <div class="px-7 mt-6 py-3 rounded-xl ring-2 ring-primary cursor-pointer text-primary hover:bg-primary hover:text-white font-semibold shadow-lg shadow-gray-400 w-fit">
                        Follow
                        <Icon :path="mdiFacebook" w="20" h="20" />
                    </div>
                </a>
            </div>
        </div>
        <div class="flex-1 flex flex-row justify-center items-center">
            <img src="@/assets/seminar.jpg" alt="course" class="shadow-lg shadow-gray-400 rounded-2xl h-104">
        </div>
    </div>

    <!-- agency -->
    <div class="flex flex-col lg:flex-row lg:items-end mt-32">
        <div class="flex-1 flex flex-row justify-center items-center">
            <img src="@/assets/agency.jpg" alt="course" class="shadow-lg shadow-gray-400 rounded-2xl h-104">
        </div>
        <div class="flex-1 flex flex-row justify-center items-center pt-6 lg:pt-0 lg:ml-12">
            <div class="flex flex-col items-center lg:items-start">
                <div class="text-xl text-center lg:text-left lg:text-3xl leading-loose font-bold">JOB OPPORTUNITIES</div>
                <div class="text-lg text-center lg:text-left lg:text-xl text-black leading-loose mt-2">ယုံကြည်စိတ်ချရသော အလုပ်အကိုင် Agency များဖြင့်ချိတ်ဆက်ပေးပါသည် . . </div>
                <div class="flex flex-col items-start">
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        ITPEC စာမွေးပွဲများ ဖြေဆိုနိုင်ရန် သင်ကြားပို့ချပေးခြင်း
                    </div>
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        လုပ်ငန်းနှင့်ပက်သက်သော OJT များ
                    </div>
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        ပညာတော်သင် ကျောင်းလျှောက်ခြင်း
                    </div>
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        Kaigo Scholar ဖြင့် ကျောင်းလျှောက်ထားပေးခြင်း
                    </div>
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        Training visa, Tokutei visa များဖြင့် အလုပ်အကိုင်စေလွှတ်ပေးခြင်း
                    </div>
                    <div class="text-md lg:text-lg text-primary leading-loose mt-2 ml-0 md:ml-12">
                        <Icon :path="mdiCheckboxMarkedCircleOutline" w="20" h="20" class="mr-2" />
                        IT အလုပ်အကိုင် work visa များဖြင့် မိတ်ဆက်ပေးခြင်း
                    </div>
                </div>
                <a href="https://www.facebook.com/Shizuoka-Japanese-Language-Center-1763915927007452" target="blank" class="flex flex-row justify-center mg:justify-end w-full">
                    <div class="px-7 mt-6 py-3 rounded-xl ring-2 ring-primary cursor-pointer text-primary hover:bg-primary hover:text-white font-semibold shadow-lg shadow-gray-400 w-fit">
                        Follow
                        <Icon :path="mdiFacebook" w="20" h="20" />
                    </div>
                </a>
            </div>
        </div>
    </div>
    <!-- divider -->
    <div class="flex flex-row justify-center my-12 lg:my-20">
        <div class="h-0.5 w-2/3 bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
    </div>

    <!-- our partner -->
    <div>
        <div class="flex flex-row justify-center mb-12 lg:mb-4 font-domine font-semibold text-3xl text-primary text-center lg:text-left">Our Partner</div>
    </div>
    <div class="flex flex-row justify-center h-104 dark:bg-white mt-12">
        <img src="@/assets/partner.jpg" alt="course" class="shadow-lg shadow-gray-400 rounded-2xl">
    </div>

</div>


<!-- carousel -->
<div class="container mx-auto overflow-x-hidden">
    <!-- divider -->
    <div class="flex flex-row justify-center my-12 lg:my-20">
        <div class="h-0.5 w-2/3 bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
    </div>

    <!-- our student say -->
    <div>
        <div class="flex flex-row justify-center mb-12 lg:mb-4 font-domine font-semibold text-xl lg:text-3xl text-primary text-center lg:text-left">WHAT OUR STUDENTS SAY</div>
    </div>
    <SliderCarousel class="mt-16 mx-auto">
        <Review/>
    </SliderCarousel>
</div>
<div class="container mx-auto mb-32">
<!-- Google Map -->
    <div class="flex flex-col lg:flex-row justify-between mt-20 w-full overflow-x-hidden">
        <div class="flex-1 lg:mx-6">
            <div class="h-72 flex flex-col px-4 py-4rounded-3xl justify-around">
                <div class="font-semibold mt-4 mb-2 text-xl">Contact us</div>
                <div class="flex flex-row">
                    <Icon :path="mdiMapMarkerRadius" size="35" class="rounded-full bg-primary text-white p-1 mr-2 -mt-1"/>
                    <div class="flex-1">{{constData.contact.address}}</div>
                </div>
                <div class="flex flex-row">
                    <Icon :path="mdiPhoneInTalk" size="35" class="rounded-full bg-primary text-white p-1.5 mr-2 -mt-1"/>
                    <div class="flex-1">{{constData.contact.phone}}</div>
                </div>
                <div class="flex flex-row">
                    <Icon :path="mdiEmail" size="35" class="rounded-full bg-primary text-white p-1.5 mr-2 -mt-1"/>
                    <div class="flex-1">{{constData.contact.mail}}</div>
                </div>
                <div class="flex flex-row">
                    <Icon :path="mdiFacebook" size="40" class="text-primary mr-2 -mt-1.5"/>
                    <div class="flex-1">{{constData.contact.fb}}</div>
                </div>
            </div>
        </div>
        <div class="flex-1 lg:px-6 py-2 mt-12 md:mt-0 relative">
            <!-- <div class="absolute z-10 w-full select-none">
                <div class="flex flex-row w-full mt-36 text-xs bg-gray-300 font-semibold justify-center text-red-500 text-center"><div>shizuoka japanese<br>language center</div></div>
            </div> -->
            <iframe id="myFrame" class="w-full h-72 ring-2 ring-gray-200 rounded-3xl shadow-lg shadow-gray-200" :src="constData.contact.map" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</div>
<Footer/>
    <!-- <div class="h-96 bg-pink-400 text-4xl">
        <div class="text-center pt-40" >Cover Photo Heres</div>
    </div> -->
</user-layout></template>