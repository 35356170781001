<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue';
    import ClassDetailCard from '@/components/admin/ClassRoom/ClasssDetailCard.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import { mdiAccountCircle, mdiPlusCircleOutline, mdiTrashCanOutline, mdiOpenInNew } from '@mdi/js';
    import { isEmpty } from '@/helper/helper';
    import Icon from '@/components/Icon.vue';
    import { ref, onMounted, computed } from 'vue';
    import router from '@/router';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import DataTable from 'vue3-easy-data-table';
    import 'vue3-easy-data-table/dist/style.css';
    const headers = [
      { text: "ID", value: "id", sortable: true },
      { text: "Name", value: "name", width: 200, sortable: true },
      { text: "Phone", value: "phone"},
      { text: "Action", value: "action"}
    ];
    let store = useStore();
    let route = useRoute();
    let courseId = route.params.id;
    // search feature
    const searchField = ref('name');
    const searchValue = ref('');
    const searchKey = ['id','name','phone'];
    // search feature
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.getClassNavs[x]];
        }
        store.commit('setClassNavs',navs);
        router.push({name: name, params: params});
    }
    const getData = computed(()=>{
        if(!isEmpty(store.getters.getUsers)){
            let userBodies = store.getters.getUsers[3].data.map( (val)=>{
                return {id:val.id,role_id:val.role_id,name:val.name,photoUrl:val.photo_url,phone:val.phone,fb:val.fb_link,remark:val.remark}
            })
            return userBodies;
        }else{
            return [];
        }
        
    });
    const isInClass = (userId)=>{
        let user = store.getters.getClassDetail[0].data.filter( val=> val.user_id==userId);
        return user.length>0;
    };
    const add = (id)=>{
        // console.log(id +' courseId='+ courseId);
        const options = [
            {value:'0',text:'Booking'},
            {value:'1',text:'Payment Success'},
        ];
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Add Student',
                form: [
                    {
                        label: 'payment status',
                        type: 'select',
                        name: 'payment',
                        options: options,
                        ref: ref('0')
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'course_id',
                        placeholder: '',
                        ref: ref(courseId)
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'user_id',
                        placeholder: '',
                        ref: ref(id)
                    },
                ],
                action: {
                    title: 'Add Student'
                },
            },
            submit: (formData)=>{
                store.dispatch('addEnroll',formData);
            }
        });
    }
    onMounted(()=>{
        if(courseId==null){
            router.push({name: 'ClassRoom'});
            return;
        }
        store.dispatch('getAccounts');
    });
const jinn = (ref)=> ref.length==1? 'h-36':ref.length==2? 'h-20': ref.length==3? 'h-12':'';
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getClassNavs" :goRoute="goRoute" />
    <div v-if="getData.length>0" class="px-4 md:px-12 py-8">
        <div class="flex flex-col md:flex-row justify-between mb-6">
            <div>
                <span class="mr-2">search field:</span>
                <select v-model="searchField" class="rounded-sm dark:bg-gray-600 py-1 pl-2">
                    <option v-for="v,i in searchKey" :key="i">{{v}}</option>
                </select>
            </div>
            <div class="flex flex-row mt-4 lg:mt-0">
                <span class="mr-4">search value: </span>
                <input type="text" v-model="searchValue" class="rounded-sm bg-gray-200 dark:bg-gray-600 py-1 px-2 w-40 md:w-60">
            </div>
        </div>

        <DataTable :headers="headers" :items="getData" :rows-per-page="10" :search-field="searchField" :search-value="searchValue">
            <template #id="{id}">
                <div class="flex flex-row items-center justify-start">
                    {{id}}
                </div>
            </template>
            <template #name="{name,photoUrl}">
                <div class="flex flex-row items-center justify-start w-44" :class="jinn(getData)">
                    <span v-if="photoUrl">
                        <img :src="store.state.base+photoUrl" alt="photo" class="w-12 h-12 rounded-full my-1 shadow-md shadow-gray-400">
                    </span>
                    <span v-else class="flex flex-row items-center justify-center w-12 h-12 rounded-full text-gray-500 my-1 shadow-md shadow-gray-400">
                        <Icon :path="mdiAccountCircle" size="60" w="12" h="12"/>
                    </span>
                    <span class="ml-2">{{ name }}</span>  
                </div>
            </template>
            <template #phone="{phone}">
                <div class="flex flex-row items-center justify-start">
                    {{phone}}
                </div>
            </template>
            <template #action="item">
                <div class="flex flex-row items-center justify-start">
                    <div @click="add(item.id)" v-if="!isInClass(item.id)" class="text-green-500 mr-6 cursor-pointer inline-block">
                        <Icon :path="mdiPlusCircleOutline" size="25"/>
                    </div>
                    <div v-else class="flex flex-row justify-center"><div  class="text-blue-800 px-2 py-1 bg-amber-500 dark:bg-amber-300 rounded-full w-fit select-none">Inclass</div></div>
                </div>
            </template>
        </DataTable>
    </div>
</admin-layout></template>