<script setup>
import { reactive } from 'vue';
import {mdiClipboardEditOutline,mdiTrashCanOutline,mdiBullhornVariant} from '@mdi/js';
import Icon from '@/components/Icon.vue';

let props = defineProps({
    item:{
        type: Object,
        required: true
    },
    actions:{
        type: Object,
        required: true
    }
});
let data = reactive({
    show: false,
});
const getDescription = (str)=> data.show? str: str.substring(0,30);

const actions = (item)=>{
    let action = event.target.getAttribute("action");
    if(action=="edit") props.actions.edit(item);
    else if(action=="delete") props.actions.del(item);
    else if(action=="other") data.show=!data.show;    
    else props.actions.detail(item);
};
</script>
<template>
    <div @click="actions(item)" class="text-white flex flex-row bg-cyan-800 hover:bg-cyan-700 justify-between rounded-md hover:drop-shadow-2xl cursor-pointer">
        <div class="flex flex-col w-full">
            <div class="flex flex-row justify-end w-full z-10">
                <!-- <div class="absolute text-gray-700 font-semibold bg-yellow-500 px-3 pb-2 pt-1 rounded-tr-md z-10">{{ statusGet(status) }}</div> -->
            </div>
            <div class="text-2xl font-semibold flex flex-row items-center justify-center w-full my-4 mt-12 md:mt-4">
                <Icon :path="mdiBullhornVariant" size="25"/>
                <div class="text-gray-200 ml-2">
                    {{ item.title }}
                </div>
            </div>
            <div class="text-lg flex flex-row items-center justify-start mx-4 md:mx-8">
                <div class="px-3 bg-amber-500 rounded-full mr-3">
                    {{ item.show_until }}
                </div> ထိပြထားမည်
            </div>
            <div class="text-md mx-4 md:mx-8 my-4">
                <div class="text-xl font-semibold mb-3">
                    အကြောင်းအရာ
                </div>
                <div class="text-justify">
                    {{ getDescription(item.content) }} 
                    <div action="other" :class="{'inline-block':!data.show,'mt-2':data.show}" class="text-center text-white cursor-pointer rounded-full py-1 px-3 bg-blue-500 w-fit">
                        {{data.show?'show less':'see more . . .'}}
                    </div>
                </div>
            </div>

            <div class="flex flex-row items-center justify-center md:justify-end pb-6 md:pr-6">
                <div class="flex flex-col justify-end cursor-pointer text-green-300 mr-6">
                    <Icon action="edit" :path="mdiClipboardEditOutline  " size="45" w="w-12" h="h-12" class=""/>
                </div>
                <div class="flex flex-col justify-end cursor-pointer text-red-400">
                    <Icon action="delete" :path="mdiTrashCanOutline " size="45" w="w-12" h="h-12" class=""/>
                </div>
            </div>
        </div>
    </div>       
    
</template>