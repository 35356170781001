<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
    import Icon from '@/components/Icon.vue';
    import { mdiDesktopMac, mdiAccountMultiple, mdiHumanMaleBoardPoll, mdiAbTesting, mdiNoteEditOutline, mdiCartArrowRight } from '@mdi/js';
    import { useStore } from 'vuex';
    let store = useStore();
    const props = defineProps({
        isDrop: {
            type: Boolean,
            default: false
        },
    })
    // Reusable Path
        // Menu-Item
        const items = [
            {
                to: '/admin/dashboard',
                icon: mdiDesktopMac,
                text: "Dashboard",
                callBack: function(){
                    // console.log('this is call back');
                },
            },
            {
                to: '/admin/account',
                icon: mdiAccountMultiple,
                text: "Account",
                callBack: function(){
                    // console.log('this is call back');
                },
            },
            {
                to: '/admin/class',
                icon: mdiHumanMaleBoardPoll,
                text: "Class Room",
                callBack: function(){
                    // console.log('this is call back');
                },
            },
            {
                to: '/admin/order',
                icon: mdiCartArrowRight,
                text: 'Enroll Orders',
                callBack: function(){
                    // console.log('this is call back');
                },
            },
            {
                to: '/admin/test',
                icon: mdiAbTesting,
                text: "Unit Test",
                callBack: function(){
                    // console.log('this is call back');
                },
            },
            {
                to: '/admin/blog',
                icon: mdiNoteEditOutline,
                text: "Blogger",
                callBack: function(){
                    // console.log('this is call back');
                },
            },
        ];
    // Reusable Path
</script>

<template>
    <div :class="{'hidden': !props.isDrop }" class="absolute z-10 md:hidden bg-white opacity-95 divide-y divide-gray-100 rounded shadow w-full dark:bg-gray-700 dark:divide-gray-600 select-none">
        <ul class="py-1 text-sm text-gray-700 dark:text-gray-400 divide-y divide-gray-600">
            <li v-for="item,index in items" :key="index">
                <router-link 
                    class="flex flex-row justify-between items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    :to="item.to"
                    @click="item.callBack"
                >
                    <div class="flex flex-row items-center">
                        <icon :path="item.icon" size="18"/> <span class="ml-2">{{ item.text }}</span>
                    </div>
                    <div v-if="item.to=='/admin/order'" class="mr-2">
                        <div v-if="store.getters.getOrders.filter((val)=>val.status==0).length>0" class="w-7 h-7 text-sm top-0 right-0 p-0.5 bg-red-500 text-white font-bold  rounded-full flex flex-col justify-center items-center">
                            <div class="text-xs">{{store.getters.getOrders.filter((val)=>val.status==0).length}}</div>
                        </div>
                    </div>
                </router-link>
            </li>
        </ul>
    </div>
</template>