import axios from 'axios';
import {openModal,closeModal} from "jenesius-vue-modal";
import {sleep} from "@/helper/helper";
import { mdiCalendarClock, mdiClipboardTextPlay, mdiBullhornVariant, mdiAccountMultiplePlus } from '@mdi/js';

const state = {
    navs: [],
    classes: {
        newClasses: [],
        ongoingClasses: [],
        endClasses: [],
    },
    detail: [],
    detail_attr: [],
    orders: [],
    emptyClassesInUserApp: false,
};

const getters = {
   getClasses: (state)=>state.classes,
   getClassNavs: state => state.navs,
   getClassDetail: state=> state.detail,
   getClassDetailAttr: state=> state.detail_attr,
   getOrders: state=> state.orders,
   getEmptyClassesInUserApp: state=> state.emptyClassesInUserApp,
};

const actions = {
// Class
    async getClasses({commit,rootState,state}){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.get(`${baseUri}${prefix}/courses`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        }
        ).then(function(data){
            if(data.data.data.length>0){
                state.emptyClassesInUserApp=false;
                commit('setClasses',data.data.data);
            }else{
                state.emptyClassesInUserApp=true;
            }
        }).catch(function(error){
            commit('checkError',error);
        });
    },
    async addNewClass({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/courses`,
            formData,
            {
            headers: {
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.data;
            commit('addNewClass',ans);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
                if(message.name) commit('setErrorFormModel',message.name[0]);
                if(message.description) commit('setErrorFormModel',message.description[0]);
                if(message.price) commit('setErrorFormModel',message.price[0]);
                if(message.remark) commit('setErrorFormModel',message.remark[0]);
                if(message.class_time) commit('setErrorFormModel',message.class_time[0]);
                if(message.class_day) commit('setErrorFormModel',message.class_day[0]);
                if(message.period) commit('setErrorFormModel',message.period[0]);
                if(message.start_at) commit('setErrorFormModel',message.start_at[0]);
            }
            //error.response.data.message
        });
    },
    async editClass({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        let params = {};
        for (let key of formData.keys()) params[key]=formData.get(key);
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.put(`${baseUri}${prefix}/courses/${formData.get('id')}`,
        params,headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.message;
            commit('updateClass',ans);
        })
        .catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
                if(message.name) commit('setErrorFormModel',message.name[0]);
                if(message.description) commit('setErrorFormModel',message.description[0]);
                if(message.price) commit('setErrorFormModel',message.price[0]);
                if(message.remark) commit('setErrorFormModel',message.remark[0]);
                if(message.class_time) commit('setErrorFormModel',message.class_time[0]);
                if(message.class_day) commit('setErrorFormModel',message.class_day[0]);
                if(message.period) commit('setErrorFormModel',message.period[0]);
                if(message.start_at) commit('setErrorFormModel',message.start_at[0]);s
            }            
        });
    },
    async deleteClass({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/courses/${id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteClass',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
// Class
// Detail
    async getClassDetail({commit,rootState},id){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.get(`${baseUri}${prefix}/courses/${id}`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        }
        ).then(function(data){
            commit('setClassDetail',data.data.course);
        }).catch(function(error){
            commit('checkError',error);
        });
    },
    async addEnroll({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/enrolls`,
            formData,
            {
            headers: {
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.data;
            commit('addEnroll',ans);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
            }
            //error.response.data.message
        });
    },
// Detail
// Enroll
    async updateEnroll({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        let params = {};
        for (let key of formData.keys()) params[key]=formData.get(key);
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.put(`${baseUri}${prefix}/enrolls/${formData.get('id')}`,
        params,headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.message;
            commit('updateEnroll',ans);
        })
        .catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
    async deleteEnroll({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/enrolls/${id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteEnroll',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
// Enroll
// Attendance
    async createAttendance({commit,rootState},{formData,callBack}){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/attendances`,
            formData,
            {
            headers: {
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.data;
            callBack();
            commit('createAttendance',ans);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
            }
            //error.response.data.message
        });
    },
    async deleteAttendance({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/attendances/${id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteAttendance',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
// Attendance
// Test
    async getTest({commit,rootState},id){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        return axios.get(`${baseUri}${prefix}/tests/${id}`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        });
    },
    async addTest({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/tests`,
            formData,
            {
            headers: {
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.data;
            commit('setTest',ans);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
                if(title) commit('setErrorFormModel',message.title[0]);
                if(description) commit('setErrorFormModel',message.description[0]);
                if(start_at) commit('setErrorFormModel',message.start_at[0]);
                if(dead_line) commit('setErrorFormModel',message.dead_line[0]);
            }
            //error.response.data.message
        });
    },
    async updateTest({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        let params = {};
        for (let key of formData.keys()) params[key]=formData.get(key);
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.put(`${baseUri}${prefix}/tests/${formData.get('id')}`,
        params,headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.message;
            commit('updateTest',ans);
        })
        .catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
    async deleteTest({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/tests/${id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteTest',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
// Test
// announce
    async createAnnounce({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/announces`,
            formData,
            {
            headers: {
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.data;
            commit('createAnnounce',ans);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
                if(title) commit('setErrorFormModel',message.title[0]);
                if(content) commit('setErrorFormModel',message.content[0]);
            }
            //error.response.data.message
        });
    },
    async editAnnounce({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        let params = {};
        for (let key of formData.keys()) params[key]=formData.get(key);
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.put(`${baseUri}${prefix}/announces/${formData.get('id')}`,
        params,headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.data;
            commit('editAnnounce',ans);
        })
        .catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
    async deleteAnnounce({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/announces/${id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteAnnounce',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
// announce
// Enroll Order
    async getEnrollOrder({commit,rootState}){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.get(`${baseUri}${prefix}/online-register`,{
            headers: {
                'Authorization': 'Bearer '+ token,
                'last-id': state.orders.length>0? state.orders[0].id : null
            }
        }).then(function(data){
            commit('setOrders',data.data.data);
            // console.log(data.data.data);
        }).catch(function(error){
            // console.log('not modify');
        });
    },
    async editEnrollOrder({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        let params = {};
        for (let key of formData.keys()) params[key]=formData.get(key);
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.put(`${baseUri}${prefix}/online-register/${formData.get('id')}`,
        params,headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.message;
            // console.log(ans);
            commit('editEnrollOrder',ans);
        })
        .catch((error)=>{
            commit('setLoadFormModel',false);     
        });
    },
    async deleteEnrollOrder({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/online-register/${id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteEnrollOrder',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
        });
    },
// Enroll Order
};

const mutations = {
    setClassNavs: (state,navs)=>{
        state.navs=navs;
    },
// Class
    setClasses: async (state,classes)=>{
        // console.log('start');
        // await sleep(5000);
        // console.log('end');
        let newClasses = typeof classes=='object'? classes.filter( (val)=>val.status<1) : [];
        let ongoingClasses = typeof classes=='object'? classes.filter( (val)=>val.status==1) : [];
        let endClasses = typeof classes=='object'? classes.filter( (val)=>val.status==2) : [];
        if(classes=='reset'){
            state.classes={newClasses: [],ongoingClasses: [],endClasses: []};
        }else if(classes.length>0){
            state.classes={newClasses: newClasses.length>0?newClasses:-1,ongoingClasses: ongoingClasses.length>0?ongoingClasses:-1,endClasses: endClasses.length>0?endClasses:-1};
        }else{
            state.classes={newClasses: -1,ongoingClasses: -1,endClasses: -1};
        }
    },
    addNewClass: (state,course)=>{
        course.status<1?state.classes.newClasses=[course,...(state.classes.newClasses==-1?[]
        :state.classes.newClasses)]:course.status==1?state.classes.ongoingClasses = [course,
        ...(state.classes.ongoingClasses==-1?[]:state.classes.ongoingClasses)]:state.classes
        .endClasses = [course,...(state.classes.endClasses==-1?[]:state.classes.endClasses)];
    },
    updateClass: (state,course)=>{
        let oldData = serchOldCourse(state,course.id);
        if(oldData.status==course.status){
            setCourse(state,course,null);
        }else{
            setCourse(state,oldData,'del');
            setCourse(state,course,null);
        }
    },
    deleteClass: (state,id)=>{
        let oldData = serchOldCourse(state,id);
        setCourse(state,oldData,'del');
    },
// Class
// Detail
    resetClassDetail: (state)=>{
        state.detail_attr = [];
        state.detail= [];
    },
    setClassDetail: (state,detail)=>{
        state.detail_attr = detail.enrolls_att;
        state.detail = [
            {
                id: 1,
                icon: mdiAccountMultiplePlus,
                name: 'ENROLL',
                total: detail.enrolls_scores.length,
                data: detail.enrolls_scores
            },
            {
                id: 2,
                icon: mdiCalendarClock,
                name: 'ATTENDANCE',
                total: detail.attendances.length,
                data: detail.attendances
            },
            {
                id: 3,
                icon: mdiClipboardTextPlay,
                name: 'EXAM',
                total: detail.tests.length,
                data: detail.tests
            },
            {
                id: 4,
                icon: mdiBullhornVariant,
                name: 'ANNOUNCE',
                total: detail.announces.length,
                data: detail.announces
            },
        ];
    },
    addEnroll: (state,enroll)=>{
        enroll.score=0;
        state.detail[0].data = [enroll,...state.detail[0].data];
        enroll.attendance=0;
        state.detail_attr = [enroll,...state.detail_attr];
    },
// Detail
// Enroll
    updateEnroll: (state,enroll)=>{
        state.detail[0].data.filter( (val,index)=> {
            if(val.id==enroll.id){
                enroll.score = val.score;
                state.detail[0].data[index]=enroll;
                return;
            }
        });
        state.detail_attr.filter( (val,index)=> {
            if(val.id==enroll.id){
                enroll.attendance = val.attendance;
                state.detail_attr[index]=enroll;
            }
        })
    },
    deleteEnroll: (state,id)=>{
        let unset = state.detail[0].data.filter( val=>val.id!=id );
        state.detail[0].data=unset;
        unset = state.detail_attr.filter( val=>val.id!=id );
        state.detail_attr=unset;
    },
// Enroll
// Attendance
    createAttendance: (state,attr)=>{
        state.detail[1].data = [ ...state.detail[1].data,attr ];
    },
    deleteAttendance: (state,id)=>{        
        let unset = state.detail[1].data.filter( val=>val.id!= id );
        state.detail[1].data = unset;
    },
// Attendance
// Test
    setTest: (state,test)=>{
        test.subcategory_id= parseInt(test.subcategory_id);
        state.detail[2].data = [ ...state.detail[2].data,test ];
    },
    updateTest: (state,test)=>{
        state.detail[2].data.filter( (val,index)=>{
            if(val.id==test.id){
                state.detail[2].data[index] = test;
                return;
            }
        });
    },
    deleteTest: (state,id)=>{
        state.detail[2].data = state.detail[2].data.filter( val=>val.id!=id );
    },
// Test
// Announce
    createAnnounce: (state,announce)=>{
        state.detail[3].data = [ ...state.detail[3].data,announce ];
    },
    editAnnounce: (state,announce)=>{
        state.detail[3].data.filter( (val,index)=>{
            if(val.id==announce.id){
                state.detail[3].data[index] = announce;
                return;
            }
        });
    },
    deleteAnnounce: (state,id)=>{
        state.detail[3].data= state.detail[3].data.filter( val=>val.id!=id );
    },
// Announce
// Orders
    setOrders: (state,orders)=>{
        state.orders = orders;
    },
    editEnrollOrder: (state,order)=>{
        state.orders.filter((val,index)=>{
            if(order.id===val.id) {
                state.orders[index]=order;
                return;
            }
        });
        // state.orders = orders;
    },
    deleteEnrollOrder: (state,id)=>{
        state.orders = state.orders.filter(val=>val.id!=id);
    },
// Orders
};
function isObject(value){
    return typeof value=='object';
}
function serchOldCourse(state,id){
    return [
    ...isObject(state.classes.newClasses)? state.classes.newClasses.filter( a=>a.id==id ):[],
    ...isObject(state.classes.ongoingClasses)? state.classes.ongoingClasses.filter( a=>a.id==id ):[],
    ...isObject(state.classes.endClasses)? state.classes.endClasses.filter( a=>a.id==id ):[]][0];
}
function setCourse(state,course,del){
    let status = course.status;
    if(status<1) {
        let unset = isObject(state.classes.newClasses)? state.classes.newClasses.filter( a=>a.id!=course.id ):[];
        del? state.classes.newClasses = [...unset].length>0?[...unset]:-1 : state.classes.newClasses = [ course,...unset];
    }else if(status==1) {
        let unset = isObject(state.classes.ongoingClasses)? state.classes.ongoingClasses.filter( a=>a.id!=course.id ):[];
        del? state.classes.ongoingClasses = [...unset].length>0?[...unset]:-1 : state.classes.ongoingClasses = [ course,...unset];
    }else{
        let unset = isObject(state.classes.endClasses)? state.classes.endClasses.filter( a=>a.id!=course.id ):[];
        del? state.classes.endClasses = [...unset].length>0?[...unset]:-1 : state.classes.endClasses = [ course,...unset];
    }
}

export default {
    state, getters, actions, mutations
}


// addNewClass
// if(course.status<1){
//     state.classes.newClasses = [course,...(state.classes.newClasses==-1?[]:state.classes.newClasses)];
// }else if(course.status==1){s
//     state.classes.ongoingClasses = [course,...(state.classes.ongoingClasses==-1?[]:state.classes.ongoingClasses)];
// }else{
//     state.classes.endClasses = [course,...(state.classes.endClasses==-1?[]:state.classes.endClasses)];
// }