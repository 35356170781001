<script setup>
    import { reactive,onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    import router from '@/router';
    import FormData from 'form-data';
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Card from '@/components/admin/Test/card.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import { mdiCheckCircleOutline, mdiCloseCircleOutline, mdiLoading } from '@mdi/js';
    import Icon from '@/components/Icon.vue';

    const route = useRoute();
    const store = useStore();
    const item = JSON.parse( route.params.item?? '{"title":null}' );

    let data = reactive({
        title: item.title?? '',
        multiple: item.multiple? item.multiple.map((ans)=>{
            return {value: ans,isTrue: ans==item.true};
        }):[{value: '',isTrue: false},{value: '',isTrue: false},{value: '',isTrue: false},{value: '',isTrue: false}],
        explain: item.explain?? '',
        errorMsg: '',
    });
    const check = index=>{
        data.multiple.forEach((ans)=>ans.isTrue=false);
        data.multiple[index].isTrue=true;
        // console.log(index);

    };


    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.testGetNavs[x]];
        }
        store.commit('setNavs',navs);
        router.push({name: name, params: params});
    }
    const save = ()=>{
        data.errorMsg='';
        let formData = new FormData(); 
        if(data.title){
            formData.append('title',data.title);
        }else{
            data.errorMsg='မေးခွန်းရေးထည့်ပေးပါ ';
            return; 
        }
        let correct = data.multiple.filter((ans)=>ans.isTrue==true);
        if(correct.length==1){
            formData.append('correct_ans',correct[0].value);
        }else{
            data.errorMsg='အဖြေမှန်တစ်ခု သတ်မှတ်ပေးပါ';
            return;
        }
        formData.append('id',item.id);
        formData.append('subcategory_id',item.subcategory_id);
        if( data.multiple.filter((ans)=>ans.value=='').length==0 ){
            let multiple = [];
            data.multiple.forEach((e)=> multiple=[...multiple,e.value] );
            formData.append('multiple',JSON.stringify(multiple));
        }else{
            data.errorMsg='multiple choice ရွေးစရာအားလုံးထည့်ပေးပါ';
            return;
        }
        formData.append('explain',data.explain);
        // duplicate check for multiple choice
        let isDulplicate = false;
        data.multiple.filter( (val)=> {
            let val_len = data.multiple.filter((ans)=>ans.value==val.value);
            if(val_len.length>1){
                data.errorMsg=`multiple choice မှာ "${val.value}" က တစ်ခုထက်ပိုနေပါတယ်။`;
                isDulplicate=true;
                return;
            }
        });
        if(isDulplicate) return;
        // duplicate check for multiple choice
        
        // Test
        // let params = {};
        // for (let key of formData.keys()) params[key]=formData.get(key);
        // console.log(params);
        // Test
        store.dispatch('editQuestion',formData);
    };

    const questionLead = question=>{
      var myArray = question.split('｜');
      var html='';
      html+=`<div class="flex flex-row items-center flex-wrap text-sm leading-loose">`;
      myArray.forEach(e => {
        //   ★
        if(e.includes('！')){
          let subStr = e.substring(1);
          let mySubArray = subStr.split('');
          mySubArray.forEach(s =>{
            html+=`<div class="text-blue-500 font-bold underline">${s}</div>`;
          });
        }else if(e.includes('＊')){
            html+=`<div class="underline ">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</div>`;
            html+=`<div class="">&nbsp</div>`;
            html+=`<div class="underline ">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</div>`;
            html+=`<div class="">&nbsp</div>`;
            html+=`<div class="font-bold underline">&nbsp&nbsp★&nbsp&nbsp</div>`;
            html+=`<div class="">&nbsp</div>`;
            html+=`<div class="underline ">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</div>`;
        }else if(e.includes('＾')){
            html+=`<div class="flex flex-row items-center mx-2">`;
            let subStr = e.substring(1);
            // ｜＾学（がっ）校（こう）｜
            let mySubArray = subStr.split('）');
            mySubArray.forEach(x=>{
                if(x.includes('（')){
                    let subFuri = x.split('（');
                    if(subFuri.length==2){
                        html+=`<div class="flex flex-col items-center leading-furi pb-4">`;
                            html+=`<div class="text-x9">${subFuri[1]}</div>`;
                            html+=`<div class="">${subFuri[0]}</div>`;
                        html+=`</div>`;
                    }
                    // html+=`${subFuri.length==2}`;
                }
            });
            html+=`</div>`;
        }else{
            html+=`<div class="">${e}</div>`;
        }
      });
      html+=`</div>`;
      return html;
    };
    onMounted(()=>{
        if(item.title==null){
            router.push({name: 'Test'});
        }
    });
</script>
<template>
<admin-layout>
    <Navbar :navs="store.getters.testGetNavs" :goRoute="goRoute" />
    <!-- preview -->
    <div class="px-8 md:px-12 py-6 flex flex-col items-start justify-start pr-8 md:pr-12 text-xl bg-slate-300 dark:bg-side-bg-dark select-none">
        <div class="animate-pulse text-sm flex flex-row justify-center items-center w-full mb-3 font-mono font-bold">Preview</div>
        <div class="flex flex-row">
            <div :class="{'pt-6px':data.title.includes('＾')}" class="mr-2">
                ( ? )
            </div>
            <div v-html="questionLead(data.title)" class="pb-2 select-none font-semibold flex-1"></div>
        </div>
        <!-- answer box -->
        <div class="w-full px-5 mt-6 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6 select-none">
            <div 
            v-for="(answer,i) in data.multiple"
            v-bind:key="i"
            >
                <div v-if="answer.value">
                    <button
                        v-if="!answer.isTrue"
                        class="flex flex-row w-full h-12 px-3 bg-gray-200 items-center justify-start rounded-md select-none">
                        <div class="pl-2 text-xs text-left text-black">{{answer.value}}</div>
                    </button>
                    <button 
                        v-if="answer.isTrue"
                        class="flex flex-row w-full h-12 px-3 font-bold bg-green-300 items-center justify-start rounded-md select-none">
                        <div class="pl-2 text-xs　text-left text-black">{{answer.value}}</div>
                    </button>
                </div>
            </div>
        </div>
        <!-- explain -->
        <div class="text-sm py-4 px-5">
            {{data.explain}}
        </div>
    </div>
    <!-- preview -->

    <div class="px-8 md:px-12 py-12">
        <div class="mb-6">
            <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Add Question!</label>
            <textarea v-model="data.title" name="title" id="title" cols="30" rows="3" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="｜＾天（てん）気（き）｜がいいから　｜！散歩｜　さんぽしましょう。｜＊｜"></textarea>
        </div>
        <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Multiple Choose</label>
        <div v-for="item,index in data.multiple" :key="index" class="mb-6">
            <div class="flex flex-row items-center">
                <input v-model="item.value" type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Answer">
                <div @click="check(index)">
                    <div v-if="item.isTrue">
                        <Icon :path="mdiCheckCircleOutline" size="35" w="16" h="16" class="ml-2 text-green-500 hover:text-green-400 cursor-pointer"/>
                    </div>
                    <div v-else>
                        <Icon :path="mdiCloseCircleOutline" size="35" w="16" h="16" class="ml-2 text-red-500 hover:text-red-400 cursor-pointer"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- explain -->
        <div class="mb-6">
            <label for="explain" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Explain!</label>
            <textarea v-model="data.explain" name="explain" id="explain" cols="30" rows="1" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="write reason about correct answer"></textarea>
        </div>
        <div v-if="data.errorMsg" class="font-bold text-black bg-red-500 py-2 pl-4">
            <span class="animate-pulse">{{data.errorMsg}}</span>
        </div>
        <div class="flex flex-row justify-center">
            <button @click="save" class="flex flex-row items-center justify-center mt-6 px-10 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                <span class="mr-2">Update</span> <Icon v-if="store.state.loadFormModel" :path="mdiLoading" size="20" class="animate-spin"></Icon>
            </button>
        </div>
    </div>

    <div class="h-20"></div>
</admin-layout>
</template>

<!-- v-on:click="$store.state[year][type][mondai].questions[index].pick=answer" -->