<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import Icon from '@/components/Icon.vue';
    import { mdiClipboardEditOutline  , mdiTrashCanOutline, mdiFacebook, mdiEye } from '@mdi/js';
    import { isEmpty } from '@/helper/helper';
    import CertificateCard from '@/components/admin/Account/CertifacteCard.vue';
    import ProfileAvator from '@/components/admin/Account/ProfileAvator.vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue'
    import { ref,computed,onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    import router from '@/router';
    const store = useStore();
    const route = useRoute();
    const userId = route.params.id;
    const userName = route.params.name;
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.getAccountNavs[x]];
        }
        store.commit('setAccountNavs',navs);
        router.push({name: name, params: params});
    }
    let imageUrl = computed(()=>{
        if(store.getters.getUser.photo_url){
            return store.state.base+store.getters.getUser.photo_url;
        }else{
            return null;
        }
    });
    let fbUrl = computed(()=>{
        if(store.getters.getUser.fb_link){
            return store.getters.getUser.fb_link;
        }else{
            return null;
        }
    });
    let profileUrl = computed(()=>{
        if(store.getters.getUser.id){
            return store.state.ownBase+`profile?id=${store.getters.getUser.id}`;
        }else{
            return 'error';
        }
    });
    let name = computed(()=>{
        if(store.getters.getUser.name){
            return store.getters.getUser.name;
        }else{
            return null;
        }
    });
    let since = computed(()=>{
        if(store.getters.getUser.since){
            return store.getters.getUser.since;
        }else{
            return null;
        }
    });
    let certificates = computed(()=>{
        if(store.getters.getUser.certificates){
            return store.getters.getUser.certificates;
        }else{
            return [];
        }
    });
    const addCertis = ()=>{
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: `Create New Account`,
                form: [
                    {
                        label: 'Course Title *',
                        type: 'text',
                        name: 'title',
                        placeholder: 'N5+N4 Course',
                        ref: ref('')
                    },
                    {
                        label: 'Upload Certificate Photo *',
                        type: 'file',
                        name: 'file',
                        placeholder: 'Upload Photo',
                        ref: ref('')
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'user_id',
                        placeholder: '',
                        ref: ref(userId)
                    },
                ],
                action: {
                    title: 'Add Certificae'
                }
            },
            submit: (formData)=>{
                // console.log(fromData);
                store.dispatch('addCertificate',formData);
            }
        });
    }
    const edit = (item)=>{
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: `Update Certificate`,
                form: [
                    {
                        label: 'Course Title (Optional)',
                        type: 'text',
                        name: 'title',
                        placeholder: 'N5+N4 Course',
                        ref: ref(item.title)
                    },
                    {
                        label: 'Certificate Photo  (Optional)',
                        type: 'file',
                        name: 'file',
                        placeholder: 'Upload Photo',
                        ref: ref('')
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'id',
                        placeholder: '',
                        ref: ref(item.id)
                    },
                ],
                action: {
                    title: 'Update Certificae'
                }
            },
            submit: (formData)=>{
                // console.log(fromData);
                store.dispatch('updateCertificate',formData);
            }
        });
    };
    const del = (id)=>{
        // store.commit('notify',{ message:'Hello', type:'error'});
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Are you sure you want to delete this Certificate?',
                form: null,
                action: {
                    title: 'Delete Certificae'
                }
            },
            submit: ()=>{
                store.dispatch('deleteCertificate',id);
            }
        });
    }
    onMounted(()=>{
        if(isEmpty(route.params)) {
            router.push({name: 'Account'});
            return;
        }
        store.commit('setUser',{});
        store.dispatch('getUser',userId)
        .then(function(data){
            store.commit('setUser',data.data.user);   
        }).catch(function(error){
            // commit('checkError',error);  
        });
    });
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getAccountNavs" :goRoute="goRoute" />
    <Addbar label="Add Certificate" :add="addCertis"/>
    <div class="flex flex-col mx-8 md:mx-12 select-none">
        <ProfileAvator 
            :profileUrl="profileUrl" 
            :fbUrl="fbUrl" 
            :imageUrl="imageUrl" 
            :name="name"
            :since="since"
        />
    </div>

    <div v-if="certificates.length>0" class="text-center text-xl my-16 mx-8 md:mx-32 select-none">
        This is to certify that <span class="inline-block font-bold">{{name}}</span> has attended and successfully completed following courses.
    </div>
    <div v-else class="text-center text-xl my-16 mx-8 md:mx-32">
        no certificate right now!
    </div>

    <!-- Certificate Card -->

    <!-- End Certificate Card -->

    <div class="grid grid-cols-1 md:grid-cols-1 gap-4 md:gap-8 mx-8 md:mx-32 select-none">
        <div v-for="(item, index) in certificates" :key="index"><CertificateCard 
            :index="index"
            :certisUrl="store.state.base+item.url"
            :className="item.title"
            :issueDate="item.issue_date"
            :edit="edit"
            :del="del"
            :item="item"
        /></div>
    </div>
    <div class="my-20"></div>
</admin-layout></template>