<script setup>
    import { ref, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import router from '@/router';
    import { useRoute } from 'vue-router';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue'
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Card from '@/components/admin/Test/card.vue';
    import LoadingCard from '@/components/admin/Test/LoadingCard.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import { mdiPlus, mdiPlusBoxOutline, mdiLeadPencil, mdiDeleteEmpty, mdiPlayCircleOutline, mdiBookOpenPageVariantOutline, mdiChevronDoubleRight } from '@mdi/js';
    import Icon from '@/components/Icon.vue';
    const route = useRoute();
    const store = useStore();
    const levelId = route.params.id;
    const levelName = route.params.name;
    const levelNavs = route.params.navs;
    // dev purpose
    const categories = [
        {
            id: 1,
            name: 'Grammer',
            level_id: 3,
            total: 20
        },
        {
            id: 2,
            name: 'Vocab',
            level_id: 3,
            total: 32
        },
        {
            id: 3,
            name: 'Reading',
            level_id: 3,
            total: 15
        },
        {
            id: 4,
            name: 'Listening',
            level_id: 3,
            total: 16
        }
    ];
    // dev purpose
    const actions = {
        detail: (id,name) =>{
            const navs = [...store.getters.testGetNavs,{
                routeName: 'TestCategory',
                title: name,
                params: {
                    name: name,
                    id: id    
                },
            }];
            store.commit('setNavs',navs);
            router.push({name: 'TestCategory', params: { name: name, id: id }});
        },
        edit: (id,name) => edit(id,name), //alert('edit '+id),
        del: id => deleteLevel(id),//alert('delete '+id)
    };
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.testGetNavs[x]];
        }
        store.commit('setNavs',navs);
        router.push({name: name, params: params});
    }
    onMounted(()=>{
        if(levelId==null){
            router.push({name: 'Test'});
            return;
        }
        store.commit('setCategories','reset');
        store.dispatch('getCategories',levelId);
    });
    const add = ()=>{
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Add New Category',
                form: [
                    {
                        label: 'New Category Name',
                        type: 'text',
                        name: 'name',
                        placeholder: 'Kanji',
                        ref: ref('')
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'level_id',
                        placeholder: '',
                        ref: ref(levelId)
                    },
                ],
                action: {
                    title: 'Add New Category'
                }
            },
            submit: (fromData)=>{
                // console.log(fromData.get('name'));
                store.dispatch('addNewCategory',fromData);
            }
        });
    };
    const edit = (id,name)=>{
        // console.log(id + name); return;
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Edit Category',
                form: [
                    {
                        label: 'Edit Category Name',
                        type: 'text',
                        name: 'name',
                        autofocus: true,
                        placeholder: name,
                        ref: ref(name)
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'id',
                        placeholder: '',
                        ref: ref(id)
                    },
                ],
                action: {
                    title: 'Update Category'
                }
            },
            submit: (fromData)=>{
                // console.log(fromData);
                store.dispatch('editCategory',fromData);
            }
        });
    };
    const deleteLevel = (id)=>{
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Are you sure you want to delete this Category?',
                form: null,
                action: {
                    title: ''
                }
            },
            submit: ()=>{
                // console.log(id);
                store.dispatch('deleteCategory',id);
            }
        });
    };
</script>
<template>
<admin-layout>
    <Navbar :navs="store.getters.testGetNavs" :goRoute="goRoute" />
    <Addbar label="Add Category" :add="add" />
    <div v-if="store.getters.getCategories.length>0" class="px-8 pt-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        <Card :icon="mdiBookOpenPageVariantOutline" :title="levelName" :datas="store.getters.getCategories" :actions="actions"/>
    </div>
    <div v-else-if="store.getters.getCategories==-1" class="text-center mt-10 font-bold">
        There is no data!
    </div>
    <div v-else class="animate-pulse px-8 pt-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        <LoadingCard :count="3"/>
    </div>
    <div class="h-20"></div>
</admin-layout>
</template>