// this is helper file by me(Arkar Mann Aung)
// created at May 27 2022
// internal use only
// import { useStore } from 'vuex';
import store from '@/store/index';
export function isEmpty(obj){
    return Object.keys(obj).length === 0;
};

export function sleep(ms){
    return new Promise(_ => setTimeout(_, ms));
}
export function formatDate(date) {
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 101).toString().substring(1);
    var day = (date.getDate() + 100).toString().substring(1);
    return year + "-" + month + "-" + day;
}
export function isJsonStr(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export function snaker(message,type='success'){
    store.commit('notify',{message: message,type: type});
}

export function furiTranslator(context){
    let splitWithJapanese = context.split('｜');
    let doubleSplit=[];
    splitWithJapanese.forEach((value,index) => {
        // double check for eng char
        if(value.includes('|')){
            let nestSplit = value.split('|');
            doubleSplit=[...doubleSplit,...nestSplit];
        }else{
            doubleSplit.push(value)
        }
    });
    // create html element
    let html='';
    html+=`<div class="flex flex-row items-center flex-wrap text-sm leading-loose">`;
    doubleSplit.forEach(e => {
        //   _ _ ★ _
        if( (e.includes('！')||e.includes('!')) && (e[0]=='！'|| e[0]=='!') ){
            let subStr = e.substring(1);
            let mySubArray = subStr.split('');
            html+=`<div class="text-blue-500 font-semibold underline">${subStr}</div>`;
            // mySubArray.forEach(s =>{
            // });
        }else if( e.includes('＊')||e.includes('*') ){
            html+=`<div class="underline ">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</div>`;
            html+=`<div class="">&nbsp</div>`;
            html+=`<div class="underline ">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</div>`;
            html+=`<div class="">&nbsp</div>`;
            html+=`<div class="font-bold underline">&nbsp&nbsp★&nbsp&nbsp</div>`;
            html+=`<div class="">&nbsp</div>`;
            html+=`<div class="underline ">&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</div>`;
        // }else if(e.includes('＾')){
        }else if( (e.includes('＾')||e.includes('^')) && (e[0]=='＾'|| e[0]=='^') ){
            html+=`<div class="flex flex-row items-center ml-2">`;
            let subStr = e.substring(1);
            // ｜＾学（がっ）校（こう）｜ ( )
            let mySubArray = []
            let multiSplit=[];
            if(subStr.includes('）')){
                mySubArray = subStr.split('）');
                mySubArray.forEach((value,index) => {
                    // double check for eng char
                    if(value.includes(')')){
                        let nestSplit = value.split(')');
                        multiSplit=[...multiSplit,...nestSplit];
                    }else{
                        multiSplit.push(value);
                    }
                });
            }else if(subStr.includes(')')){
                multiSplit = subStr.split(')');
            }
            multiSplit.forEach(x=>{
                if( x.includes('（')){
                    let subFuri = x.split('（');
                    if(subFuri.length==2){
                        html+=`<div class="flex flex-col items-center leading-furi pb-4">`;
                            html+=`<div class="text-x9">${subFuri[1]}</div>`;
                            html+=`<div class="">${subFuri[0]}</div>`;
                        html+=`</div>`;
                    }
                }else if(x.includes('(')){
                    let subFuri = x.split('(');
                    if(subFuri.length==2){
                        html+=`<div class="flex flex-col items-center leading-furi pb-4">`;
                            html+=`<div class="text-x9">${subFuri[1]}</div>`;
                            html+=`<div class="">${subFuri[0]}</div>`;
                        html+=`</div>`;
                    }
                }
            });
            html+=`</div>`;

        }else{
            let subFuri = e.split(' ');
            subFuri.forEach((value)=>{
                html+=`<div class="mx-2">${value}</div>`;
            })
        }
    });




    html+=`</div>`;
    return html;
}