<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue';
    import ClassDetailCard from '@/components/admin/ClassRoom/ClasssDetailCard.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import { ref, onMounted } from 'vue';
    import router from '@/router';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import { mdiAccountTie, mdiAccountSchool, mdiAccountMultiple, mdiAccountStar, mdiSleep } from '@mdi/js';
    let store = useStore();
    let route = useRoute();
    let classId= route.params.id;
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.getClassNavs[x]];
        }
        store.commit('setClassNavs',navs);
        router.push({name: name, params: params});
    }
    const detail = (id,name)=>{
        // check with id=1? 'ClassEnroll'
        let routeName = '';
        if(id==1) routeName='ClassEnroll';
        else if(id==2) routeName='ClassAttendance';
        else if(id==3) routeName='ClassExam';
        else if(id==4) routeName='Announce';
        else return;
        const navs = {
                routeName: routeName,
                title: name,
                params: {
                    name: name,
                    id: classId    
                },
            };
        store.commit('setClassNavs',[...store.getters.getClassNavs,navs]);
        router.push({name: routeName, params: { name: name, id: classId }});
    };
    onMounted(()=>{
        if(classId==null){
            router.push({name: 'ClassRoom'});
            return;
        }
        store.dispatch('getClassDetail',classId);
    });
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getClassNavs" :goRoute="goRoute" />
    <div class="px-8 pt-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 select-none">
        <ClassDetailCard :datas="store.getters.getClassDetail" :detail="detail"/>
    </div>
</admin-layout></template>