<script setup>
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import Icon from '@/components/Svg.vue';
import { mdiFacebook, mdiNavigationVariant, mdiShareVariant } from '@mdi/js';
import Share from '@/components/Share.vue';
let store = useStore();
let props = defineProps({
    blog:{
        type: Object,
        required: true
    },
    actions:{
        type: Object,
        required: true
    },
});
    const style = (obj)=>{
        var css = [];
        // size
        if(obj.hasOwnProperty('size')){
            if(obj.size=='small') css=[...css,'text-xs'];
            if(obj.size=='large') css=[...css,'text-xl'];
            if(obj.size=='huge')  css=[...css,'text-4xl'];
        }
        // size
        // style
        if(obj.hasOwnProperty('bold')) css=[...css,'font-bold'];
        if(obj.hasOwnProperty('italic')) css=[...css,'italic'];
        if(obj.hasOwnProperty('underline')) css=[...css,'underline'];
        if(obj.hasOwnProperty('strike')) css=[...css,'line-through'];
        // style
        // align
        if(obj.hasOwnProperty('align')){
            if(obj.align=='center') css=[...css,'text-center'];
            if(obj.align=='right') css=[...css,'text-right'];
            if(obj.align=='justify') css=[...css,'text-justify'];
        }
        // align
        // list
        if(obj.hasOwnProperty('list')){
            if(obj.list=='ordered') css = [...css,'ml-8 list-decimal'];
            if(obj.list=='bullet') css = [...css,'ml-8 list-disc'];
        }
        // list
        return css;
    }
    const tailwind = (delta)=>{
        if(delta==null) return;
        let html = '';
        let design = {
            div_class: '',
            contents: []
        };
        let data = []; 
        if(delta.hasOwnProperty('ops')){
            delta.ops.forEach( (element,index) => {
                if(element.insert.includes('\n')){
                    design.div_class=element.hasOwnProperty('attributes')?style(element.attributes):'';
                    let list = element.insert.split("\n");
                    let first = true;
                    list.forEach(ls => {
                        if(first){
                            design.contents.push({
                                class: '',
                                span: tab(ls)
                            });
                            data.push(design);
                            clear();
                            first=false;
                        }else{
                            design.contents.push({
                                class: '',
                                span: tab(ls)
                            });
                            data.push(design);
                            clear();
                        }
                    });
                    clear();
                }else{
                    design.contents.push({
                        class: element.hasOwnProperty('attributes')?style(element.attributes):'',
                        span: tab(element.insert)
                    });
                    let nextObj = delta.ops[index+1];
                    // if(nextObj.hasOwnProperty('attributes')){
                    //     if(nextObj.attributes.hasOwnProperty('list')){
                    //         data.push(design);
                    //         clear();
                    //     }
                    // }
                }
            });
        }
        function tab(tab){
            if(tab.includes('\t')){
                let str='';
                let list = tab.split("\t");
                list.forEach(ans => {
                    if(ans=='') str+='&nbsp&nbsp&nbsp&nbsp&nbsp';
                    else str+=ans;
                });
                return str;
            }else{
                return tab;
            }
        }
        function clear(){
            design = {
                div_class: '',
                contents: []
            }
        };
        return data;
    }
let view = (view)=> view>999? (view/1000).toFixed(1) + ' k' : view;
const detail = ()=>props.actions.detail(props.blog);
onMounted(()=>{
    // console.log(props.blog);
});
const description = "We will write blogs that will benefit students with knowledge of Japanese language.";
</script>
<template>
    <div class="flex flex-col md:flex-row shadow-lg my-10 rounded-xl">
        <div class="md:w-[350px]">
            <img :src="store.state.base+blog.img_url" alt="image" class="rounded-t-xl md:rounded-tr-none md:rounded-l-xl md:h-[262px]">
        </div>
        <div class="flex-1 md:h-[262px] bg-[#f3f7ff] rounded-b-xl md:rounded-bl-none md:rounded-r-xl px-6 py-2 flex flex-col justify-evenly">
            <div class="flex flex-row justify-between text-[#6484AA] font-dmmo">
                <div><span class="text-sm">View</span> <span class="font-semibold">{{view(blog.view)}}</span></div>
                <div>2h ago</div>
            </div>
            <div class="flex flex-row text-[#f98d06] text-sm">
                Posted By <span class="font-semibold mx-1">{{blog.author.name}}</span> at Shizouka
            </div>
            <div class="flex flex-row h-8 text-[#2E4879] text-2xl font-semibold overflow-y-hidden mb-2">
                {{blog.title}}
            </div>
            <div class="flex flex-col h-20 overflow-y-scroll scrollbar-hide bg-[#e4ecfc] rounded-xl">
                <div v-for="(item, index) in tailwind(JSON.parse(blog.subtitle))" :key="index" class="mx-4 md:mx-8 text-black">
                    <div v-if="item.contents[0].span!=''" :class="item.div_class" class="text-sm">
                        <span class="leading-loose" v-html="content.span" :class="content.class" v-for="(content, index) in item.contents" :key="index"></span>
                    </div>
                    <div v-else >
                        <br>
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-between items-center mt-2">
                <div class="flex flex-row justify-evenly items-center">
                    <div class="hidden lg:block text-lg font-dmmo text-gray-500">Share Now</div>
                    <Icon :path="mdiShareVariant" size="25" class="lg:hidden text-gray-500 mr-2"/>
                    <Share network="facebook" :title="blog.title" :description="description" :url="store.state.ownBase+`blogs/detail?id=${blog.id}`" class="flex flex-row items-center h-full">
                        <Icon :path="mdiFacebook" size="30" class="text-blue-500 mr-2 lg:mr-4 lg:ml-4"/>
                    </Share>
                    <Share network="telegram" :title="blog.title" :description="description" :url="store.state.ownBase+`blogs/detail?id=${blog.id}`" class="text-blue-700 flex flex-row items-center h-full">
                        <Icon :path="mdiNavigationVariant" size="25" class="bg-sky-500 text-white rounded-full p-0.5"/>
                    </Share>
                </div>
                <div @click="detail" class="px-4 md:lg lg:text-xl text-white bg-primary rounded-lg py-2 hover:font-semibold select-none cursor-pointer">Read More</div>
            </div>
        </div>
    </div>
</template>