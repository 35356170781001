import axios from 'axios';
import { mdiAccountTie, mdiAccountSchool, mdiAccountMultiple, mdiAccountStar, mdiSleep } from '@mdi/js';
import {openModal,closeModal} from "jenesius-vue-modal";
import { sleep } from '@/helper/helper';

const state = {
    navs: [],
    users: [],
    user: {},
};

const getters = {
    getUsers: state => state.users,
    getAccountNavs: state => state.navs,
    getUser: state => state.user,
}; 

const actions = {
// Account Page
    async getAccounts({commit,rootState}){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.get(`${baseUri}${prefix}/user-get`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        }
        ).then(function(data){
            commit('setAccounts',data.data);   
        }).catch(function(error){
            commit('checkError',error);
        });
    },
    async getAboutData({commit,rootState}){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        return axios.get(`${baseUri}${prefix}/about-data`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        });
    },
    async createAccount({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/user-register`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            // console.log(data.data.user_data);
            let ans = data.data.user_data;
            commit('addNewAccounts',ans);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
                if(message.name) commit('setErrorFormModel',message.name[0]);
                if(message.phone) commit('setErrorFormModel',message.phone[0]);
                if(message.password) commit('setErrorFormModel',message.password[0]);
                if(message.photo_url) commit('setErrorFormModel',message.photo_url[0]);
                if(error.response.status==413) commit('setErrorFormModel','your file too lerge!');
            }
        });
    },
    async updateAccount({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/user-update`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            // console.log(data.data.user_data);
            let ans = data.data.user_data;
            commit('updateAccount',ans);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                console.log(error.response.status);
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
                if(message.name) commit('setErrorFormModel',message.name[0]);
                if(message.phone) commit('setErrorFormModel',message.phone[0]);
                if(message.password) commit('setErrorFormModel',message.password[0]);
                if(message.photo_url) commit('setErrorFormModel',message.photo_url[0]);
                if(error.response.status==413) commit('setErrorFormModel','your file too lerge!');
            }
        });
    },
    async deleteAccount({commit,rootState},user){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/user-delete/${user.id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteAccount',user);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
// Account Page
// Account Detail Page
    async getUser({commit,rootState},id){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        return axios.get(`${baseUri}${prefix}/user/${id}`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        }
        );
    },
// Account Detail Page
// Account Profile Page
    async addCertificate({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/certificates`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            // console.log(data.data);
            let ans = data.data.data;
            commit('addCertificate',ans);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
                if(message.title) commit('setErrorFormModel',message.title[0]);
                if(message.file) commit('setErrorFormModel',message.file[0]);
                if(error.response.status==413) commit('setErrorFormModel','your file too lerge!');
            }
        });
    },
    async updateCertificate({commit,rootState},formData){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/update-certificates`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            // console.log(data.data + formData.get('id'));
            let ans = data.data.user_data;
            commit('updateCertificate',ans);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
                if(message.title) commit('setErrorFormModel',message.title[0]);
                if(message.file) commit('setErrorFormModel',message.file[0]);
                if(error.response.status==413) commit('setErrorFormModel','your file too lerge!');
            }
        });
    },
    async deleteCertificate({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/certificates/${id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteCertificate',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
// Account Profile Page
};

const mutations = {
    setAccountNavs: (state,navs)=>{
        state.navs=navs;
    },
// Account Page
    async setAccounts(state,accounts){
        // console.log('before');
        // await new Promise(r => setTimeout(r, 0));
        // console.log('After');
        let admin = accounts.admin.filter(val=> val.phone!='developer');
        let staff = accounts.staff;
        let lecturer = accounts.lecturer;
        let student = accounts.student;
        let users = [
            {
                id: 1,
                icon:mdiAccountStar,
                name: 'ADMIN',
                total: admin.length,
                data: admin
            },
            {
                id: 2,
                icon:mdiAccountTie,
                name: 'STAFF',
                total: staff.length,
                data: staff
            },
            {
                id: 3,
                icon:mdiAccountSchool,
                name: 'LECTURER',
                total: lecturer.length,
                data: lecturer
            },
            {
                id: 4,
                icon:mdiAccountMultiple,
                name: 'STUDENT',
                total: student.length,
                data: student
            },
        ];
        state.users=users;
    },
    addNewAccounts(state,newAccount){
        state.users.forEach( (value,index) => {
            if(value.id==newAccount.role_id){
                state.users[index].total = value.total+1;
                state.users[index].data = [newAccount,...value.data];
            }
        });
    },
    updateAccount(state,update){
        state.users.forEach( (value,index)=>{
            if(value.id==update.role_id){
                // let remain = value.data.filter((ans)=>ans.id!=update.id);
                // state.users[index].data = [update,...remain];
                value.data.forEach((val,ind)=>{
                    if(val.id==update.id){
                        state.users[index].data[ind]=update;
                        return;
                    }
                })
            }
        });
    },
    deleteAccount(state,user){
        state.users.forEach( (value,index)=>{
            if(value.id==user.role_id){
                let remain = value.data.filter((ans)=>ans.id!=user.id);
                state.users[index].data = remain;
            }
        });
    },
// Account Page
// Account Detail Page
    setUser(state,user){
        state.user=user;
    },
    addCertificate(state,certis){
        state.user.certificates = [certis,...state.user.certificates];
    },
    updateCertificate(state,certis){
        let noChange = state.user.certificates.filter( (ans)=> ans.id!=certis.id );
        let old = state.user.certificates.filter( (ans)=> ans.id==certis.id );
        certis.issue_date = old[0].issue_date;
        state.user.certificates = [certis,...noChange];
    },
    deleteCertificate(state,id){
        let update = state.user.certificates.filter( (ans)=> ans.id!=id );
        state.user.certificates = update;
    },
// Account Detail Page 
};

export default {
    state,
    getters,
    actions,
    mutations
}