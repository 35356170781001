<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import ClassCard from '@/components/admin/ClassRoom/ClasssCard.vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue'
    import ClassTestCard from '@/components/admin/ClassRoom/ClassTestCard.vue';
    import { mdiChartBar } from '@mdi/js';
    import Icon from '@/components/Icon.vue';
    import { ref, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import router from '@/router';
    let store = useStore();
    let route = useRoute();
    let classId= route.params.id;
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.getClassNavs[x]];
        }
        store.commit('setClassNavs',navs);
        router.push({name: name, params: params});
    };
    const addQuestions = () => {
        const navs = {
                routeName: 'AddExam',
                title: 'Add',
                params: {
                    name: 'Add',
                    id: classId    
                },
            };
        store.commit('setClassNavs',[...store.getters.getClassNavs,navs]);
        router.push({name: 'AddExam', params: { name: 'Add', id: classId }});
    };
    const actions = {
        detail: (id,name) =>{
            // subcategory_id
            let temp = store.getters.getClassDetail[2].data.filter( val=> val.id==id);
            let subCateId = temp[0].subcategory_id;
            const navs = [...store.getters.getClassNavs,{
                routeName: 'TestQuestion',
                title: name,
                params: {
                    name: name,
                    id: subCateId
                },
            }];
            // alert('detail '+ name);
            store.commit('setNavs',navs);
            router.push({name: 'TestQuestion', params: { name: name, id: subCateId }});
        },
        edit: (id,name) => {
            let test = store.getters.getClassDetail[2].data.filter( val=> val.id==id);
            let old = test[0];
            openModal(FormModel, {
                toggle: ()=>closeModal(),
                model: {
                    title: 'Edit Exam',
                    form: [
                        {
                            label: 'Exam Title',
                            type: 'text',
                            name: 'title',
                            placeholder: '',
                            ref: ref(old.title)
                        },
                        {
                            label: 'Exam Description',
                            type: 'text',
                            name: 'description',
                            placeholder: 'How to Apply',
                            ref: ref(old.description)
                        },
                        {
                            label: 'Exam will start',
                            type: 'date',
                            name: 'start_at',
                            ref: ref(old.start_at)
                        },
                        {
                            label: 'Exam Dead line',
                            type: 'date',
                            name: 'dead_line',
                            ref: ref(old.dead_line)
                        },
                        {
                            label: '',
                            type: 'hidden',
                            name: 'id',
                            placeholder: '',
                            ref: ref(id)
                        }
                    ],
                    action: {
                        title: 'Add Exam'
                    },
                },
                submit: (formData)=>{
                    store.dispatch('updateTest',formData);
                }
            });
        },
        del: id => {
            openModal(FormModel, {
                toggle: ()=>closeModal(),
                model: {
                    title: 'Are you sure you want to delete this Exam?',
                    form: null,
                    action: {
                        title: 'Delete Test'
                    },
                },
                submit: ()=>{
                    store.dispatch('deleteTest',id);
                }
            });
        },
        exam_room: (id,name)=>{
            const navs = [...store.getters.getClassNavs,{
                routeName: 'ExamRoom',
                title: name,
                params: {
                    name: name,
                    id: id
                },
            }];
            // alert('detail '+ name);
            store.commit('setClassNavs',navs);
            router.push({name: 'ExamRoom', params: { name: name, id: id }});
        }
    };
    onMounted(()=>{
        if(classId==null){
            router.push({name: 'ClassRoom'});
            return;
        }
    });
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getClassNavs" :goRoute="goRoute" />
    <Addbar label="Add Questions" :add="addQuestions"/>
    <div v-if="store.getters.getClassDetail.length>0" class="px-8 pt-12">
        <div v-for="data,index in store.getters.getClassDetail[2].data" :key="index" class="mb-4">
            <ClassTestCard :id="data.id" :title="data.title" :description="data.description" :start_at="data.start_at" :dead_line="data.dead_line" :actions="actions"/>
        </div>
    </div>
    <div class="my-32"></div>
</admin-layout></template>

