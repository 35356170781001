<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue';
    import ClassDetailCard from '@/components/admin/ClassRoom/ClasssDetailCard.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import { isEmpty } from '@/helper/helper';
    import { ref, onMounted, computed } from 'vue';
    import { mdiClipboardEditOutline, mdiTrashCanOutline } from '@mdi/js';
    import Icon from '@/components/Icon.vue';
    import router from '@/router';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import DataTable from 'vue3-easy-data-table';
    import 'vue3-easy-data-table/dist/style.css';
    const headers = [
      { text: "Attr Id", value: "id", sortable: true },
      { text: "Date (YY-MM-DD)", value: "date"},
      { text: "Action", value: "action"}
    ];
    let store = useStore();
    let route = useRoute();
    let courseId= route.params.id;
    // search feature
    const searchField = ref('att_date');
    const searchValue = ref('');
    const searchKey = ['att_date'];
    // search feature
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.getClassNavs[x]];
        }
        store.commit('setClassNavs',navs);
        router.push({name: name, params: params});
    };
    const getData = computed(()=>{
        if(store.getters.getClassDetail.length>0){
            let userBodies = store.getters.getClassDetail[1].data.map( (val)=>{
                return {
                    id:val.id,
                    att_date:val.att_date,
                    course_id:val.course_id,
                    user_id:val.user_id
                }
            })
            return userBodies;
        }else{
            return [];
        }
        
    });
    const create = ()=>{
        const navs = {
                routeName: 'CreateAttendance',
                title: 'Create',
                params: {
                    name: 'Create',
                    id: courseId
                },
            };
        store.commit('setClassNavs',[...store.getters.getClassNavs,navs]);
        router.push({name: 'CreateAttendance', params: { name: 'Create', id: courseId }});
    }
    const del = (item)=>{
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Are you sure you want to delete this Attendance?',
                form: null,
                action: {
                    title: 'Delete'
                },
            },
            submit: ()=>{
                store.dispatch('deleteAttendance',item.id);
            }
        });
    };
    onMounted(()=>{
        if(courseId==null){
            router.push({name: 'ClassRoom'});
            return;
        }
        
        // store.dispatch('getClassDetail',detailId);
    });
const jinn = (ref)=> ref.length==1? 'h-36':ref.length==2? 'h-20': ref.length==3? 'h-12':'';
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getClassNavs" :goRoute="goRoute" />
    <Addbar label="Create Attendance" :add="create"/>
    <div v-if="getData.length>0" class="px-4 md:px-12 py-8 z-0">
        <div class="flex flex-col md:flex-row justify-between mb-6">
            <div>
                <span class="mr-2">search field:</span>
                <select v-model="searchField" class="rounded-sm dark:bg-gray-600 py-1 pl-2">
                    <option v-for="v,i in searchKey" :key="i">{{v}}</option>
                </select>
            </div>
            <div class="flex flex-row mt-4 lg:mt-0">
                <span class="mr-4">search value: </span>
                <input type="text" v-model="searchValue" class="rounded-sm bg-gray-200 dark:bg-gray-600 py-1 px-2 w-40 md:w-60">
            </div>
        </div>

        <DataTable :headers="headers" :items="getData" :rows-per-page="10" :search-field="searchField" :search-value="searchValue">
            <template #id="{id}">
                <div class="flex flex-row items-center justify-start">
                    {{id}}
                </div>
            </template>
            <template #date="{att_date}">
                <div class="flex flex-row items-center justify-start" :class="jinn(getData)">
                    {{att_date}}
                </div>
            </template>
            <template #action="item">
                <div @click="del(item)" class="text-red-400 mr-6 cursor-pointer inline-block">
                    <Icon :path="mdiTrashCanOutline" size="25"/>
                </div>
            </template>
        </DataTable>
    </div>
</admin-layout></template>