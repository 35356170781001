import axios from 'axios';

const state = {
    userTests:[],
};

const getters = {
    getUserTests: (state)=>state.userTests
};

const actions = {
    async getLevelWithCate({commit,rootState}){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        return axios.get(`${baseUri}${prefix}/level-with-categories`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        });
    },
};

const mutations = {
    setUserTests(state,tests){
        state.userTests = tests;
    }
};

export default {
    state, getters, actions, mutations
}