const index = {
    hero: {
        title: 'Welcome to Our Training School.',
        subTitle: 'Boost your business with better technology',
        link: 'https://m.me/integratedSolution.mm',
        photoUrl: require("@/assets/tchel.png")
    },
    course: {
        title: 'Quality education for superior human resources.',
        subTitle: 'With many better experiences after attending the course',
        photoUrl: require("@/assets/course.jpg")
    },
    blog: {
        title: 'Gain knowledge by reading Japanese language blogs.',
        subTitle: 'Blogs related to the course help the teaching',
        photoUrl: require("@/assets/blog.jpg")
    },
    test: {
        title: "Let's try to answer the questions according to your favorite level.",
        subTitle: "ကြိုက်နှစ်သက်ရာ Level အလိုက် မေးခွန်းများကို စမ်းသပ်ဖြေဆိုကြရအောင် . . .",
        photoUrl: require("@/assets/test.jpg")
    },
    contact: {
        address: 'No.206(3A), Baho Road, Sanchaung Yangon.',
        phone: '09780132792',
        mail: 'mm.integratedsolution@gmail.com',
        fb: 'Integrated Solution',
        map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3819.3744357041396!2d96.12539052530312!3d16.807770651416526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c1eb3840abb0f3%3A0x9bcbed350c9a8b63!2z4YCX4YCf4YCt4YCv4YCc4YCZ4YC64YC4LCDhgJvhgJThgLrhgIDhgK_hgJThgLosIOGAmeGAvOGAlOGAuuGAmeGArA!5e0!3m2!1smy!2ssg!4v1658565418205!5m2!1smy!2ssg'
    }
};

export default index;