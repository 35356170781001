<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import {useStore} from 'vuex';
import Icon from '@/components/Icon.vue';
import router from '@/router';
import { mdiAccountCircle, mdiChevronDown, mdiChevronUp, mdiAccount, mdiLogout, mdiMonitorDashboard } from '@mdi/js';
let store = useStore();
    const props = defineProps({
        isDrop: {
            type: Boolean,
            default: false
        },
        userName: {
            type: String,
            default: "Jhon Doe"
        },
        toggle: {
            type: Function,
            default: null,
        }
    })
    // Reusable Path
        // Menu
        const menuIcon = mdiAccountCircle;
        const menuToggle = props.toggle;
        const userName = props.userName;
        // Menu-Item
        const items = [
            {
                to: '/admin/profile',
                icon: mdiAccount,
                text: "Profile",
                callBack: function(){
                    props.toggle();
                },
            },
            {
                to: '/',
                icon: mdiMonitorDashboard,
                text: "User View",
                callBack: function(){
                    props.toggle();
                },
            },
            {
                to: '/login',
                icon: mdiLogout,
                text: "Log Out",
                callBack: function(){
                    props.toggle();
                    store.commit('setAuth');
                    store.commit('setUserCourses',[]);
                    router.push({path:'/'});
                },
            },
        ];
    // Reusable Path
</script>

<template>
    <div class="select-none">
        <div class="flex flex-row h-14 w-44 items-center justify-center">
            <button 
                @click="menuToggle"
                class="flex items-center justify-between h-14 w-full py-2 pl-3 pr-4 font-medium text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-gray-400 dark:hover:text-white dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
            > <icon :path="menuIcon" size="24" class="mr-2"/> <span class="truncate">{{store.state.auth.name}}</span>
            <icon :path="!props.isDrop?mdiChevronDown:mdiChevronUp" size="18" />
            </button>
        </div>
        <!-- Dropdown menu -->
        <div class="relative">
            <div :class="{'hidden': !props.isDrop }" class="absolute z-10 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                <ul class="py-1 text-sm text-gray-700 dark:text-gray-400">
                    <li v-for="item,index in items" :key="index">
                        <router-link 
                            class="flex flex-row justify-start items-center px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            :to="item.to"
                            @click="item.callBack"
                        >
                            <icon :path="item.icon" size="18"/> <span class="ml-1">{{ item.text }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>