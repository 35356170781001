<script setup>
    import { watch,computed, ref, onMounted, onBeforeUnmount } from 'vue';
    import Icon from '@/components/Icon.vue';
    import ProfileMenu from '@/components/admin/menuBar/ProfileMenu.vue';
    import SideBar from '@/components/admin/sideBar/SideBar.vue';
    import MobileMenu from '@/components/admin/menuBar/MobileMenu.vue';
    import { mdiMenu, mdiAccount, mdiCogOutline, mdiEmail, mdiLogout, mdiLightbulbOnOutline, mdiMoonWaxingCrescent, mdiChevronDown, mdiChevronUp } from '@mdi/js';
    import { useStore } from 'vuex';
    const store = useStore();
    let data = ref({
        isProfileDrop: false,
        isMenuDrop: false,
    });
    const profileDropdown = ref(null);
    const menuDropdown = ref(null);
    const forceClose = event => {
        if (!profileDropdown.value.$el.contains(event.target)) data.value.isProfileDrop = false;
        if (!menuDropdown.value.$el.contains(event.target)) data.value.isMenuDrop = false;
    }

    onMounted(() => window.addEventListener('click', forceClose));

    onBeforeUnmount(() => window.removeEventListener('click', forceClose));

    function theme(){
        store.dispatch('setDark',store.state.dark==1?0:1);
    }
    function profileToggle(){
        data.value.isProfileDrop = !data.value.isProfileDrop;
    }
    function menuToggle(){
        data.value.isMenuDrop = !data.value.isMenuDrop;
    }
</script>

<template>
<div class="fixed top-0 w-full z-40">
    <div class="flex flex-row w-full h-14 items-center justify-between md:justify-end pl-4 pr-4 md:pr-10 bg-white drop-shadow-md dark:bg-side-head">
        <icon ref="menuDropdown" @click="menuToggle" :path="mdiMenu" size="30" class="border-2 border-gray-500 rounded-md w-9 h-9 cursor-pointer md:hidden"/>
        <div class="flex flex-row items-center justify-end">
            <ProfileMenu ref="profileDropdown" :isDrop="data.isProfileDrop" :toggle="profileToggle"/>
            <div @click="theme" class="cursor-pointer pl-2 md:pl-8">
                <div v-if="$store.state.dark" class="text-yellow-300">
                    <Icon :path="mdiLightbulbOnOutline" size="20"/>
                </div>
                <div v-else class="rotate-45">
                    <Icon :path="mdiMoonWaxingCrescent" size="20"/>
                </div>
            </div>
        </div>
    </div>
    <div class="relative">
        <MobileMenu :isDrop="data.isMenuDrop"/>
    </div>
</div>
</template>