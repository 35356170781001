<script setup>
    import { ref, onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import Icon from '@/components/Icon.vue';
    import { mdiDesktopMac, mdiAccountMultiple, mdiHumanMaleBoardPoll, mdiAbTesting, mdiNoteEditOutline, mdiAccountCircleOutline, mdiCartArrowRight, mdiBellOutline } from '@mdi/js';
    import { useStore } from 'vuex';
    let store = useStore();
    // onMounted(()=>alert(useRoute().path));
    const currentRoute = useRoute().path;
    let data = ref({
        title: ['YOUR SCHOOL','NAME'],
        menu: [
            {
                id: 0,
                title: 'General',
                tabs: [
                    {
                        to: '/admin/dashboard',
                        icon: mdiDesktopMac,
                        text: 'Dashboard',
                        active: true,
                    }
                ]
            },
            {
                id: 1,
                title: 'Manage',
                tabs: [
                    {
                        id: 0,
                        to: '/admin/account',
                        icon: mdiAccountMultiple,
                        text: 'Account',
                        active: false,
                    },
                    {
                        id: 1,
                        to: '/admin/class',
                        icon: mdiHumanMaleBoardPoll,
                        text: 'Class Room',
                        active: false,
                    },
                    {
                        id: 2,
                        to: '/admin/order',
                        icon: mdiCartArrowRight,
                        text: 'Enroll Orders',
                        active: false,
                    },
                    {
                        id: 3,
                        to: '/admin/test',
                        icon: mdiAbTesting,
                        text: 'Unit Test',
                        active: false,
                    },
                    {
                        id: 4,
                        to: '/admin/blog',
                        icon: mdiNoteEditOutline,
                        text: 'Blogger',
                        active: false,
                    },
                ]
            },
            {
                id: 2,
                title: 'Profile',
                tabs: [
                    {
                        id: 0,
                        to: '/admin/profile',
                        icon: mdiAccountCircleOutline,
                        text: 'Profile',
                        active: false,
                    }
                ]
            },
        ]
    });
</script>

<template>
    <div class="hidden md:block relative z-50 select-none">
        <div class="fixed top-0 flex flex-col h-screen w-60 bg-side-bg-light dark:bg-side-bg-dark select-none">
            <div class="flex flex-row h-14 justify-start items-center text-white bg-side-head pl-4 select-none">
                <span class="font-bold pr-2">{{ data.title[0] }}</span> {{ data.title[1] }} 
            </div>

            <div 
                v-for="item,index in data.menu" :key="index"
                class="flex flex-col text-white"
            >
                <div class="flex flex-row h-10 items-center text-sm font-thin pl-4 select-none">
                    {{ item.title }}
                </div>
                
                <router-link 
                    :to="tab.to" v-for="tab in item.tabs" 
                    :key="tab.id" 
                    class="flex flex-row h-12 items-center justify-between pl-4 hover:bg-side-hover cursor-pointer"
                    :class="{'bg-side-hover': currentRoute.includes( tab.to )}"
                >
                    <div class="flex flex-row items-center">
                        <Icon :path="tab.icon" size="20"/>
                        <div class="pl-3">{{ tab.text }}</div>
                        <!-- special for order noti -->
                    </div>
                    <div v-if="tab.to=='/admin/order'" class="mr-4">
                        <div v-if="store.getters.getOrders.filter((val)=>val.status==0).length>0" class="w-7 h-7 text-sm top-0 right-0 p-0.5 bg-red-500 text-white font-bold rounded-full flex flex-col justify-center items-center">
                            <div class="">{{store.getters.getOrders.filter((val)=>val.status==0).length}}</div>
                        </div>
                    </div>
                </router-link>
                <hr v-if="index != data.menu.length-1" class="border-t border-gray-500 dark:border-gray-700 py-2">
            </div>
        </div>
    </div>
</template>
<!--  
<router-link 
    class="flex flex-row justify-start items-center px-2 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
    :to="item.to"
    @click="item.callBack"
>
    <icon :path="item.icon" size="18"/> <span class="ml-1">{{ item.text }}</span>
</router-link>

-->