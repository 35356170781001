<script setup>
import { reactive } from 'vue';
import Icon from '@/components/Icon.vue';
import Copy from '@/components/Copy.vue';
import { mdiClipboardEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { useStore } from 'vuex';
let store = useStore();
let data = reactive({
    show: false,
});

const getDescription = (str)=> data.show? props.description: props.description.substring(0,30);

const props = defineProps({
    id: {
        type: Number,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
    status: {
        type: Number,
        required: true,
    },
    price: {
        type: Number,
        required: true,
    },
    remark: {
        type: String,
        required: false,
        default: ''
    },
    class_time: {
        type: String,
        required: true,
    },
    class_day: {
        type: String,
        required: true,
    },
    period: {
        type: String,
        required: true,
    },
    start_at: {
        type: String,
        required: true,
    },
    actions: {
        type: Object,
        required: true,
    }
});
const statusGet = (status)=> status==-1? 'သင်တန်းလက်ခံပြီ' : status==0? 'လူပြည့်ပါပြီ' : status==1? 'သင်တန်းစနေပြီ' : 'သင်တန်းပြီးပြီ';
const actions = (index,name)=>{
    let action = event.target.getAttribute("action");
    if(action=="edit") props.actions.edit(index,name);
    else if(action=="delete") props.actions.del(index);
    else if(action=="copy") null;
    else if(action=="other") data.show=!data.show;
    else props.actions.detail(index,name);
};
</script>

<template>
    <div @click="actions(id,name)" class="text-white flex flex-row bg-cyan-800 hover:bg-cyan-700 justify-between rounded-md hover:drop-shadow-2xl cursor-pointer">
        <div class="flex flex-col w-full">
            <div class="flex flex-row justify-end w-full z-0">
                <div class="absolute text-gray-700 font-semibold bg-yellow-500 px-3 pb-2 pt-1 rounded-tr-md z-10">{{ statusGet(status) }}</div>
            </div>
            <div class="text-2xl font-semibold flex flex-row justify-center w-full my-4 mt-12 md:mt-4">
                <div class="text-gray-200">{{ name }}</div>
            </div>
            <div class="flex flex-col md:flex-row">
                <div class="flex flex-1 flex-col mx-4 md:mx-8">
                    <div class="flex flex-col md:flex-row items-center justify-between">
                        <div class="text-lg mr-2 bg-purple-500 pt-2 pb-3 px-4 rounded-full tracking-wider w-full md:w-fit text-center">
                            သင်တန်းကြေး
                        </div>
                        <div class="mt-2 md:mt-1 md:hidden">
                            <span>{{ price }} Ks</span>
                        </div>
                        <div class="flex flex-row">
                            <span class="hidden md:block mt-2 md:mt-1">{{ price }} Ks</span>
                            <span v-if="remark" class="text-lg md:ml-2 flex flex-row items-center justify-end">( {{remark}} )</span>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row items-center justify-between mt-4">
                        <div class="text-lg mr-2 bg-orange-500 pt-2 pb-3 px-4 rounded-full tracking-wider w-full md:w-fit text-center">
                            သင်တန်းချိန်
                        </div>
                        <div class="mt-2 md:mt-1 md:hidden">
                            <span>{{ class_day }}</span>
                        </div>
                        <div class="flex flex-row">
                            <span class="hidden md:block mt-2 md:mt-1">{{ class_day }}</span>
                            <span class="text-lg md:ml-2 flex flex-row items-center justify-end">( {{class_time}} )</span>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col mx-4 md:mx-8">
                    <div class="flex flex-col md:flex-row items-center justify-between mt-4 md:mt-0">
                        <div class="text-lg mr-2 bg-blue-500 pt-2 pb-3 px-4 rounded-full tracking-wider w-full md:w-fit text-center">
                            သင်တန်းကာလ
                        </div>
                        <div class="text-lg md:ml-2 flex flex-row items-center justify-end">
                            {{period}}
                        </div>
                    </div>              
                    <div class="flex flex-col md:flex-row items-center justify-between  mt-4">
                        <div class="text-lg mr-2 bg-rose-500 pt-2 pb-3 px-4 rounded-full tracking-wider w-full md:w-fit text-center">
                            စတင်မည့်ရက်
                        </div>
                        <div class="text-lg md:ml-2 flex flex-row items-center justify-end">
                            {{start_at}}
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="text-md mx-4 md:mx-8 my-4">
                <div class="text-xl font-semibold">
                    အသေးစိပ်အချက်အလက်
                </div>
                <div class="text-justify">
                    {{ getDescription(description) }} 
                    <div action="other" :class="{'inline-block':!data.show,'mt-2':data.show}" class="text-center text-white cursor-pointer rounded-full py-1 px-3 bg-blue-500 w-fit">
                        {{data.show?'show less':'see more . . .'}}
                    </div>
                </div>
            </div>

            <div class="flex flex-row items-center justify-center md:justify-end pb-6 md:pr-6">
                <div action="copy" class="flex flex-col justify-end cursor-pointer mr-6">
                    <Copy action="copy" :text="store.state.ownBase+`enroll?id=${id}`" id_name="#class_link"/>
                </div>
                <div class="flex flex-col justify-end cursor-pointer text-green-300 mr-6">
                    <Icon action="edit" :path="mdiClipboardEditOutline  " size="45" w="w-12" h="h-12" class=""/>
                </div>
                <div class="flex flex-col justify-end cursor-pointer text-red-400">
                    <Icon action="delete" :path="mdiTrashCanOutline " size="45" w="w-12" h="h-12" class=""/>
                </div>
            </div>

        </div>
    </div>
</template>