const index = {
    about: {
        info: 'လူကြီးမင်းတို့၏ သင်တန်းကျောင်းများ အတွက် ပိုမိုကောင်းမွန်တဲ့ အနာဂါတ်တွေကို ယူဆောင်လာပေးမယ့် School Management System လေးကို မိတ်ဆက်ပေးလိုက်ပါပြီ။ ကျောင်းသားများ online Register ပြုလုပ်ခြင်း၊ သက်ဆိုင်ရာ သင်တန်းများတွင်စာရင်းသွင်းခြင်း၊ attendance များကောက်ခံခြင်း၊ သင်တန်းအတွက် မေးခွန်းများ ထည့်သွင်း၍ သက်ဆိုင်ရာသင်တန်းများနှင့်ချိတ်ဆက်ခြင်း၊ မေးခွန်းများကို အလိုအလျှောက် စာစစ်ပြီး ရလဒ်ထုတ်ပြန်ခြင်း၊ အတန်းအလိုက် ကြေငြာချက်ထုတ်ပြန်ခြင်း၊ သင်တန်းသားများ လွယ်လင့်တစ်ကူလေ့လာနိုင်စေရန် အတွက် blog များရေးသားခြင်း၊ သင်တန်းသား အယောက်တိုင်းစီတိုင်းအတွက် public profile ပြုလုပ်ပေးထားခြင်း၊ သင်တန်းပြီးဆုံးပါက သင်တန်းသားများအတွက် e-certificate များအားသင်တန်းသားများ၏ public profile များတွင်ထည့်သွင်းပေးခြင်း စတဲ့ စနစ်များစွာပါဝင်မှာဖြစ်ပါတယ်။',
        photoUrl: require('@/assets/about.jpg'),
    },
    founder: [
        {
            title: 'Founder Name',
            subTitle: 'Founder Of Your School',
            imageUrl: require('@/assets/founder_image.jpg')
        },
        {
            title: 'Founder Name',
            subTitle: 'Founder Of Your School',
            imageUrl: require('@/assets/founder_image.jpg')
        },
    ]
}

export default index;