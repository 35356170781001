<script setup>
    import UserLayout from '../../layout/UserLayout.vue';
    import { onMounted, computed } from 'vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    import Icon from '@/components/Icon.vue';
    import { mdiLoading } from '@mdi/js';
    import router from '@/router';
    import Footer from '@/components/Footer.vue';
    let store = useStore();
    let route = useRoute();
    let categoryId = route.params.id;
    let categoryName = route.params.name;
    let lessons = computed(()=>{
        if(store.getters.getSubCategories.length>0){
            let haveData = store.getters.getSubCategories.filter(val=>val.total>0);
            let totalMoreThanOne = haveData.map(val=>{
                return {
                    id: val.id,
                    name: val.name,
                }
            })
            if(totalMoreThanOne.length>0){
                return totalMoreThanOne;
            }else{
                return -1;
            }
        }else if(store.getters.getSubCategories==-1){
            return -1;
        }else{
            return [];
        }
    });
    let detail = (item)=>{
        router.push({name: 'UserTestDetail',params: {id:item.id,name:item.name}});
    };
     const rounded = 'rounded-md md:rounded-xl';
    // const rounded = 'rounded-tl-md md:rounded-tl-xl rounded-br-xl md:rounded-br-3xl rounded-tr-sm rounded-bl-sm';
    const color = 'bg-primary text-white text-lg';
    // const color = 'bg-gradient-to-tr from-pink-400 to-purple-200 text-gray-800 text-lg';
    onMounted(async()=>{        
        if(categoryId==null){
            router.push({name: 'UserTests'});
            return;
        }
        store.commit('setSubCategories','reset');
        store.dispatch('getSubCategories',categoryId);
    });
</script>
<template><user-layout :title="categoryName" :centerTitle="true">
    <div class="container mx-auto h-screen">
        <div class="text-center font-semibold text-lg pt-6 text-gray-500">{{categoryName}}</div>
        <div class="text-center text-lg pt-6 text-gray-500">ကြိုက်နှစ်သက်ရာ Lesson ကိုစမ်းသပ်ဖြေဆိုနိုင်ပါတယ်။</div>
        <div v-if="lessons.length>0" class="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12 mt-12 md:mt-20">
            <div 
                v-for="item,index in lessons" :key="index"
                @click="detail(item)"
                :class="[rounded,color]"
                class="text-center py-4 md:py-8 shadow-lg shadow-gray-400 cursor-pointer select-none">
                    {{item.name}}
            </div>
        </div>
        <div v-else-if="lessons==-1" class="text-gray-700 text-center font-semibold animate-pulse my-20 text-lg">အချက်အလက်မရှိပါ</div>
        <div v-else>
            <div class="flex flex-col items-center pt-20">
                <Icon :path="mdiLoading" size="40" w="0" h="0" class="animate-spin text-[#F98E04]"/>
                <div class="text-gray-500 mt-8 animate-pulse">Loading . . .</div>
            </div>
        </div>
    </div>
    <Footer :class="{'fixed bottom-0':lessons.length==0}"/>
</user-layout></template>