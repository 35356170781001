import axios from 'axios';
import {sleep} from '@/helper/helper';

const state = {
    blogs:[],
    nextPageUrl: null,
};

const getters = {
    getUserBlogs: (state)=>state.blogs,
    getNextPageUrl: (state)=>state.nextPageUrl,
};

const actions = {
    async getBlogsFirstPage({commit,rootState}){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        return axios.get(`${baseUri}${prefix}/blogs-pages`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        })
        .then((data)=>{
            // console.log(data.data.data);
            commit('setBlogPage',data.data.data);
        });
    },
    async nextPage({commit,rootState},url){
        let token = rootState.auth.token;
        return axios.get(url,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        })
        .then((data)=>{
            commit('setBlogPage',data.data.data);
        });
    },
    async getBlogsDetail({commit,rootState},id){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        return axios.get(`${baseUri}${prefix}/blogs/${id}`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        });
    },
};

const mutations = {
    setBlogPage(state,pages){
        state.blogs = [...state.blogs,...pages.data];
        state.nextPageUrl = pages.next_page_url;
    }
};

export default {
    state, getters, actions, mutations
}