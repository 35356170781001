<script setup>
import { mdiStar,mdiStarOutline, mdiFormatQuoteOpen, mdiFormatQuoteClose } from '@mdi/js';
import Icon from '@/components/Svg.vue';
const reviews = [
    {
        name: "Ei Thandar Aung",
        imgUrl: require('@/components/review/images/eithandaraung.jpg'),
        date: "January, 27, 2021",
        content: "ဂျပန် anime လေးတွေကြည့်ရင်းနဲ့ဂျပန်စာကိုစိတ်၀င်စားလို့ N5N4 တန်းထဲက shizuoka မှာတတ်ဖြစ်ခဲ့တယ် sensei နှစ်ယောက်လုံးရဲ့အသင်ပြကောင်းမှုစိတ်ရှည်မှု‌ကြောင့်  N3 ကိုလဲshizuokaမှာပဲဆက်တတ်ဖြစ်ခဲ့ပါတယ် နောက် level တွေလဲ sensei တို့ဆီမှာတတ်ရင်းကြိုးစားသွားပါ့မယ်",
    },
    {
        name: "Khaing Khaing Nyein",
        imgUrl: require('@/components/review/images/khaing.jpg'),
        date: "January, 25, 2021",
        content: "တကယ်ကို စေတနာပါပါနဲ့ သင်ပေးတဲ့ဆရာမပါ Grammar သင်ရင် ဖတ်စာအုပ်ထဲကစာကို ဖတ်ပြနေသလိုကြီးပဲမဟုတ်ပဲနားလည်လွယ်အောင်ရှင်းပြပေးလို့ စာပိုမှတ်မိပါတယ် စာအများကြီးဆိုတာနဲ့ ဟာဒီနေ့ သင်တန်းရှိပါလား ဆိုတဲ့ ဝန်လေးတဲ့စိတ်မရှိပဲ သင်တန်းနေ့တိုင်းတက်ချင်အောင် သင်ပေးတဲ့ဆရာမပါစာမေးရင်လည်းအမြဲသေချာပြောပြ ရှင်းပြပေးပါတယ်",
    },
    {
        name: "Thu",
        imgUrl: require('@/components/review/images/thu.jpg'),
        date: "September, 19, 2019",
        content: "ကိုယ့်အမတွေရေးတော့ ကိုယ်လည်းမနေနိုင်လို့ review လေးရေးပါရစေ 😍 ဒီကျောင်းလေးစဖွင့်ကတည်းကတက်လာခဲ့တာ ကိုယ့်ရဲ့မိသားစုကျောင်းလေးဆိုလည်းမမှားပါဘူး သင်တန်းတက်ရမှာပျင်းတဲ့ကိုယ်တောင်ဒီကျောင်း   လေးရောက်ပြီးမှကြိုးစားချင်စိတ်ရှိလာခဲ့ရတယ် စာသင်ရင်းလည်းsensei ရဲ့ဂျပန်နိုင်ငံအတွေ့   အကြုံလေးတွေပါပြောပြပေးတော့ ဂျပန်စာကိုပိုပြီး  စိတ်ဝင်စားတာပေါ့ 😍",
    },
    {
        name: "Hninn Inzali",
        imgUrl: require('@/components/review/images/hninninzali.jpg'),
        date: "January, 25, 2021",
        content: "ဒီသင်တန်းလေးကို N5 ကတည်းက ညီမစတတ်ခဲ့တာ အခု N3 ရောက်တဲ့အထိပါပဲ သင်တန်းလေးရဲ့ထူးခြားချက်က ဆရာမနဲ့တပည့်ဆိုတဲ့ ပုံစံမျိုးမဟုတ်ပဲ ညီမအရင်းလိုမောင်နှမအရင်းတွေလို စိတ်ရှည်ရှည်နဲ့ရှင်းပြပေးလို့ သင်ကြားမှု့ကိုပိုပြီးသဘောကျမိတယ်။ sensei တို့ဘက်ကပဲစာရှင်းပြတဲ့ပုံစံမျိုးမဟုတ်ပဲ ကျောင်းသူကျောင်းသားတွေနဲ့ question & answer လေးတွေလုပ်ပေးလို့လဲတော်တော်အဆင်ပြေပါတယ် 🥰 ထူးချွန်ကျောင်းသားတွေကိုလည်း scholarship ပေးတဲ့သင်တန်းလေးဖြစ်တဲ့အတွက် ကြိုးစားချင်စိတ်ကိုပိုပြီးဖြစ်စေခဲ့တယ်",
    },
    {
        name: "Magaret",
        imgUrl: require('@/components/review/images/magaret.jpg'),
        date: "January, 23, 2021",
        content: "ဂျပန်ရောက်နေတဲ့သူငယ်ချင်းကပြောပြလို့ Shizuoka က Sensei သင်ပြပေးတာဂျပန်မာ sensei ရဲ့သင်ပြပေးမှုတွေတကယ်အသုံးဝင်တယ်တဲ့စိတ်ဝင်စားလို့ N4,N3 package တန်းတက်ဖို့စာရင်းပေးခဲ့ပေမဲ့သင်တန်းလေးကမဖွင့်ဖြစ်ခဲ့ဘူး😔 covid တွင်းမို့အပြင်မာသွားတက်ချင်ပေမဲ့ N4 online class(Grammer,kanji) ပိုင်းသင်ပြပေးတဲ့အတန်းတက်ခွင်ခဲ့ရခဲ့တယ် sensei ကအရမ်းသဘောကောင်းပါတယ်စာရှင်းပြတဲ့အခါမာလဲ အဓိပ္ပါယ်တူတဲ့ meaning တွေကိုအသေးစိတ်ဘယ်နေရာကသုံးသင့်လဲသေချာရှင်းပြပါတယ် exam တွေလဲဖြေရအိမ်စာတွေလဲထပ်ရပြီး မသိတဲ့အရာရှိရင်လဲ အချိန်မရွေး sensei ဆီကမေးလို့ရပါတယ်",
    },
    {
        name: "Mal Puy",
        imgUrl: require('@/components/review/images/malpuy.jpg'),
        date: "June, 17, 2020",
        content: "Shizuoka JLC မှာစ,တက်တုန်းက ကိုယ်လဲသူများတွေလိုပဲ သင်ကြားပေးတဲ့အပိုင်းမှာနားလည်ပါ့မလားဆိုပြီး စိုးရိမ်ပူပန်မှုတွေရှိခဲ့ပါတယ်။ ဒါပေမဲ့အဲ့ဒီအပိုင်းက sensei ရဲ့မျက်နှာလေးနဲ့တင် စိုးရိမ်မှုတွေအကုန်ပျောက်သွားတာပဲ။ စာသင်တဲ့အပိုင်း- ဒီအပိုင်းကတော့ပြောစရာမလိုအောင်ကောင်းပါတယ်...Grammar, Kanji, Vocabulary ဘာတစ်ခုမှပြောစရာမလိုအောင် အခြေခံကအစ one by one သေချာသင်ပေးသွားတာပါ။ နားလည်ရခက်တဲ့ Grammar တွေဆိုရင် sensei တစ်ခါရှင်းပြပေးပြီးရင် စာအုပ်ပြန်ကြည့်စရာမလိုအောင် ခေါင်းထဲရိုက်ထည့်ပြီးသင်ပြပေးသွားတာ၊kanji ဆိုရင် စာရွက်လေးတွေနဲ့တစ်လုံးချင်း မှတ်မိတဲ့အထိတိုင်ပေးတာ၊ vocabulary တွေကို အဓိပ္ပါယ်သေချာပြောပြပြီးနှုတ်တိုက်ချပေးသွားတာတွေကအစမှတ်မိနေပါတယ်။",
    },
    {
        name: "YuMyat Htun",
        imgUrl: require('@/components/review/images/yumyat.jpg'),
        date: "September, 17, 2019",
        content: "ဂျပန်စာကို အခြေခံကနေစပြီး စနစ်တကျ သင်ချင်သူများအတွက် တစ်ကယ်အဆင်ပြေတဲ့ သင်တန်းလေးမို့လို့ recommend​ ပေးပါတယ်။ ဆရာမတွေက စိတ်ရှည်တဲ့အပြင် အသင်အပြလည်း အရမ်းကောင်းပါတယ်။ သင်ခန်းစာ တစ်ခုပြီးတိုင်း test စစ်လို့ စာတွေလည်း ကျက်ရပါတယ်။😂 exam practice တွေလုပ်ပြီးလေ့ကျင့်ပေးတာကြောင့် စာမေးပွဲအတွက် ready လုပ်ပြီးသားဖြစ်သွားတယ်။ ဒီသင်တန်းကနေ n4 Level ကို အောင်မြင်ခဲ့ပြီး ယခုလက်ရှိ n3 တန်းတက်နေတာပါ။ သင်တန်းတစ်ခုတိုင်းမှာလည်း scholar ပေးတဲ့အတွက် ကြုိးစားတဲ့ကျောင်းသားတွေအတွက် အကျိုးရှိပါတယ်။",
    },
    {
        name: "Phu Ma Lay",
        imgUrl: require('@/components/review/images/phuma.jpg'),
        date: "September, 17, 2019",
        content: "I ❤ my school. All teacher are very kindful, friendly and skillful with japanese language. And then,we are knowing the japanese of culture, life and job condition because teacher are come back from that country. Now I am attending the JLPT N3 level at this school with scholar. I am passed JLPT N5 and N4 with this school of time schedules and sufficient exam practice courses. The receptionist girl is also warmly 😘. 先生 ありがとう❤💛💚.",
    },
    {
        name: "Thant Kyi Sin Aung",
        imgUrl: require('@/components/review/images/kyisin.jpg'),
        date: "October, 1, 2021",
        content: "Shizuoka JLCမှာ N5ကိုတက်ခဲ့ပါတယ် တာဝန်ခံဆရာမတွေက တကယ်ကို သင်ကြားမှုအပိုင်းမှာကောင်းပြီး စာသင်ရတာ မပျင်းပဲ ပျော်စရာကောင်းပါတယ် အပတ်တိုင်း စာမေးပွဲတွေဖြေရလို့ စာပုံမှန်လုပ်ရပြိး စာမရမှာလည်း မစိုးရိမ်ရပါဘူးရှင့် ပြီးတော့ စာမရလို့ ဆန်းဆေးတွေကို မေးရင်လည်း သေသေချာချာ နားလည်တဲ့အထိ ရှင်းပြပေးပါတယ် အရမ်းသဘောကျတဲ့ သင်တန်းတစ်ခုပါ Kyi Phyu Senseiက သင်တန်းတွေအပြင် ပညာဒါနတွေလည်း အများကြီးလုပ်ပေးပါတယ် ဂျပန်စာသင်တန်းကောင်းကောင်းကို ရွေးချယ်ပေးပါဆိုရင် Shizuoka JLCကို လုံးဝ recommendပေးပါတယ် 💞",
    },
    {
        name: "Nang Hein Thiri Shein",
        imgUrl: require('@/components/review/images/thirishein.jpg'),
        date: "September, 12, 2021",
        content: "စာအသင်အပြကောင်းပြီး စိတ်ရှည်ရမယ် ပုံမှန်သင်တန်းမှာသာမက ပညာဒါနအတန်းမျိုးမှာပါ သေသေချာချာစေတနာနဲ့ သင်ပေးတာမျိုးလိုချင်တယ်ဆိုရင်တော့ Shizuoka ကိုသာရွေးလိုက်ပါ 👍🏻ဝတရှိက sensei နဲ့ ​ပြင်ပကသင်တန်းတခုမှာဆုံဖူးပြီးseisei အသင်အပြကောင်းတာကိုသိခဲ့ရတာပါ ကိုဗစ်ကြောင့် stay at home ဖြစ်သွားတာကြောင့်ဂျပန်စာနဲ့မပြတ်သွားရအောင် online ကနေတက်ဉီးမှဆိုပြီး sensei ရဲ့ n2 online class မှာ တက်ဖြစ်ခဲ့တာပါ သေသေချာချာကို စိတ်ရှည်လက်ရှည် စာရှင်းပြပေးတာမို့ နားလည်လွယ်ပါတယ်",
    },
    {
        name: "Zin Mar Nwe",
        imgUrl: require('@/components/review/images/zinmar.png'),
        date: "January, 29, 2021",
        content: "ကြည်ဖြူဆန်ဆေးနဲ့ စတင်သင်ကြားခွင့်ရခဲ့တာကတော့ jsatမှာပါ။ အရမ်းစိတ်ရှည်ပြီးတော့ စာသင်အရမ်းကောင်းလို့ ဆန်းဆေးနဲ့သင်ရမယ်ဆိုပျော်နေခဲ့သူထဲ ကိုယ်ပါခဲ့တယ်။ စာသင်တဲ့အခါမှာလည်းကျောင်းသားတွေကို ရှင်းလင်းလွယ်ကူတဲ့ပုံစံနဲ့ နားလည်လွယ်ကူအောင်သင်ကြားပေးပါတယ်။ နားမလည်တာရှိရင် စိတ်ရှည်ရှည်နဲ့ဖြေကြားပေးပါတယ်။ဘယ်ချိန်ပဲဖြစ်ဖြစ် စာနဲ့ပတ်သက်ပီး ကြိုက်တဲ့အချိန်မေးပါဆိုပီး အရမ်းသဘောကောင်းလွန်းတဲ့ဆန်းဆေးပါ။ တခြားသူငယ်ချင်းတွေ သင်တန်းစုံစမ်းခဲ့ရင် လမ်းညွှန်ပေးခိုင်းရင် ညီမကတော့ ကြည်ဖြူဆန်းဆေးတည်ထောင်ထားတဲ့ shizuokaကိုပဲ အမြဲဆက်သွယ်ခိုင်းခဲ့သူပါ။",
    },
    {
        name: "Ngwe Ya",
        imgUrl: require('@/components/review/images/ngweya.jpg'),
        date: "January, 27, 2021",
        content: "N3 တက်ဖို့သင်တန်းကောင်းလိုက်ရှာနေတဲ့အချိန်မှာ သူငယ်ချင်းတစ်ယောက်ရဲ့တိုက်တွန်းမှုနဲ့ Kyi Phyu sensei သင်တဲ့ Online Class မှာတက်ခဲ့တယ်။grammer တွေကို အလွတ်ကျက်စရာမလိုပဲ နားလည်လွယ်အောင် စိတ်ထဲမှာစွဲမြဲနေအောင် ဂရုတစိုက်သင်ကြားပေးတယ်။စာအသင်လဲ​ ကောင်းသလို ​exam တွေလဲ စစ်ပေးတော့ စာလဲသေချာလိုက်လုပ်ဖြစ်တယ်။သင်တန်းကြေး နည်းနည်းပဲပေးရတော့ အဲလောက်ကောင်းမယ်ဆိုတာ မထင်မိခဲ့ဘူး။ကျောင်းသားတိုင်းကို စေတနာအပြည့်အဝထားပြီးသင်ပေးတဲ့ Kyi Phyu Sensei ဒီထက်မကအောင်မြင်ပါစေ။😍😍",
    },
    {
        name: "Hnin EiEi Lwin",
        imgUrl: require('@/components/review/images/neel.jpg'),
        date: "January, 28, 2021",
        content: "တက်ခဲ့တဲ့ဂျပန်စာသင်တန်းကျောင်းတွေထဲမှာShizuokaကိုအကြိုက်ဆုံးပါပဲ n5ကိုအခြားသင်တန်းနှစ်ခုလောက်မှာတက်ခဲ့ဖူးပင်မယ့်shizuokaလောက်စိတ်တိုင်းမကျခဲ့ပါဘူး N4ဆက်တက်ဖို့ကိုသင်တန်းတွေစုံစမ်းရင်းအစ်ကိုကလဲshizuokaမှာတက်တော့ကောင်းတယ်လို့recommendပေးလို့တက်ဖြစ်တာပါအဲ့ဒါနဲ့N4ကိုonline cassစတက်ဖြစ်ခဲ့ပါတယ် senseiကစာသင်တာအရမ်းကောင်းပြီးတော့နားလည်လွယ်လို့ဒီတစ်ခါတော့သင်တန်းရွေးတာမှန်သွားပါတယ် 😁 senseiကစာသင်နေရင်းနဲ့လဲဂျပန်နိုင်ငံရဲ့အခြားဗဟုသုတရစရာတွေလဲပြောပြပါတယ် ",
    },
    {
        name: "Ya Min",
        imgUrl: require('@/components/review/images/yamin.jpg'),
        date: "January, 26, 2021",
        content: "先生ကအရမ်းသဘောကောင်း စိတ်ရှည်ပြီး စာရှင်းပြတာလည်း အရမ်းနားလည်လွယ်ပါတယ် Listening Readingတွေကိုလည်း သေသေချာချာစိတ်ရှည်စွာနဲ့သင်ပြပေးလို့ အရင်ကမသိ နားမလည်တဲ့ဟာတွေ ဘယ်နေရာမှာဘယ်လိုမှတ်ရမယ် ဆိုတာတွေပါနားလည်လာခဲ့ပါတယ် ကျောင်းသားဟောင်းတွေအတွက်လည်း ပြန်လည်ပြီးလေ့လာနိုင်လို့ အရမ်းအဆင်ပြေပါတယ် ဂျပန်ရဲ့ယဉ်ကျေးမှုတွေ ဓလေ့ထုံးစံတွေပါ ‌ပြောပြပေးလို့ ဗဟုသုတလည်းရပါတယ်ရှင့်",
    },
    {
        name: "Cho Zin",
        imgUrl: require('@/components/review/images/chozin.jpg'),
        date: "January, 27, 2021",
        content: "တစ်ကယ့်ကိုစေတနာအပြည့်နဲ့ကျောင်းသား/ကျောင်းသူ‌တွေအပေါ် သင်ပေးခဲ့လို့ တစ်ကယ့်ကိုကျေးဇူးအများကြီးတင်ပါတယ်ဆန်းဆေး 🙏🙏🙏 ဆန်းဆေးရဲ့စာသင်ကြားပုံကတော့အထူးပြောစရာဂိုမရှိတော့တာပါနော် တက်ဖူးသူတိုင်းလည်းသိတယ် မတက်ဖူးသူတွေလည်းတက်ကြည့်ပါဆန်းဆေးမှဆန်းဆေးဖြစ်သွားမှာအသေအချာ👍👍👍",
    },
    {
        name: "Htwe",
        imgUrl: require('@/components/review/images/htwe.jpg'),
        date: "November, 26, 2019",
        content: "Learning new language might seem difficult but the teaching methods at Shizuoka will make it easy. Especially because of kind and very supportive sensei Kyi Phyu and sensei Thu Thu.",
    },
];
</script>
<template>
    <div class="flex flex-col justify-center px-8" v-for="review,i in reviews" :key="i">
        <div class="p-4 bg-gray-200 rounded-md">
            <div class="flex flex-row justify-center mb-6">
                <Icon :path="mdiStar" size="30" class="text-amber-500 mr-2"/>
                <Icon :path="mdiStar" size="30" class="text-amber-500 mr-2"/>
                <Icon :path="mdiStar" size="30" class="text-amber-500 mr-2"/>
                <Icon :path="mdiStar" size="30" class="text-amber-500 mr-2"/>
                <Icon :path="mdiStarOutline" size="30" class="text-amber-500 mr-2"/>
            </div>
            <div class="text-justify italic">
                <Icon :path="mdiFormatQuoteOpen" size="20" class="mb-4"/>
                {{review.content}} 
                <Icon :path="mdiFormatQuoteClose" size="20" class="mb-4 ml-1"/>
            </div>
            <div class="flex flex-row justify-start mt-6">
                <img :src="review.imgUrl" alt="img" class="w-12 h-12 rounded-full mr-4 shadow-md shadow-gray-500">
                <div class="flex flex-col justify-center">
                    <div class="text-semibold leading-tight">{{review.name}}</div>
                    <div class="text-gray-500 text-sm leading-tight">{{review.date}}</div>
                </div>
            </div>
        </div>
    </div>
</template>