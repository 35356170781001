<script setup>
import Icon from '@/components/Icon.vue';
import {mdiFolder,mdiCheckboxMarkedCircle} from '@mdi/js';
let props = defineProps({
    id:{
        type: Number,
        required: true
    },
    name:{
        type: String,
        required: true
    },
    total:{
        required: true
    },
    check:{
        type: Boolean,
        default: true,
    },
    action:{
        type: Function,
        required: true
    }
});

const filter = (id,name)=> props.check? ()=>{}: props.action(id,name);
</script>
<template>
    <div @click="filter(id,name)" class="flex flex-col items-center p-2 cursor-pointer hover:border-2 rounded-xl border-gray-200 dark:border-gray-700">
        <div class="relative z-0">
            <div class="z-0 absolute flex flex-row justify-center items-center w-full h-full font-semibold text-blue-900">{{total}}</div>
            <div v-if="check" class="z-0 absolute flex flex-row justify-end items-end pb-2 w-full h-full font-semibold text-green-500">
                <Icon :path="mdiCheckboxMarkedCircle" size="25" h="8" w="8" class="bg-white rounded-full"/>    
            </div>
            <Icon :path="mdiFolder" size="70" h="10" w="10" :class="check?'text-yellow-500':'text-blue-500'"/>
        </div>
        <div class="text-center text-gray-700 dark:text-gray-200">
            {{name}}
        </div>
    </div>
</template>