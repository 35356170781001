<script setup>
import AdminLayout from '@/views/layout/AdminLayout.vue';
import Addbar from '@/components/admin/Addbar.vue';
import Navbar from '@/components/admin/Navbar.vue';
import {openModal,closeModal} from "jenesius-vue-modal";
import FormModel from '@/components/FormModel.vue';
import { ref,onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { formatDate } from '@/helper/helper';
import router from '@/router';
import ClassAnnounceCard from '@/components/admin/ClassRoom/ClassAnnounceCard.vue';
// temp
import {mdiClipboardEditOutline,mdiTrashCanOutline,mdiBullhornVariant} from '@mdi/js';
import Icon from '@/components/Icon.vue';
// temp
let route = useRoute();
let courseId = route.params.id;
let store = useStore();closeModal
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.getClassNavs[x]];
        }
        store.commit('setClassNavs',navs);
        router.push({name: name, params: params});
    }
    const create = ()=>{
        let day = new Date();
        day.setDate(day.getDate() +2);
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Create Announce',
                form: [
                    {
                        label: 'Title',
                        type: 'text',
                        name: 'title',
                        placeholder: 'Announce Exam',
                        ref: ref('')
                    },
                    {
                        label: 'Content',
                        type: 'text',
                        name: 'content',
                        placeholder: 'About Exam',
                        ref: ref('')
                    },
                    {
                        label: 'Show Until',
                        type: 'date',
                        name: 'show_until',
                        placeholder: '',
                        ref: ref(formatDate(day))
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'course_id',
                        placeholder: '',
                        ref: ref(courseId)
                    },
                ],
                action: {
                    title: 'Create Announce'
                },
            },
            submit: (fromData)=>{
                store.dispatch('createAnnounce',fromData);
            }
        });
    }
    const actions = {
        detail: (item)=>console.log(item.id),
        edit: (item)=>{
            openModal(FormModel, {
                toggle: ()=>closeModal(),
                model: {
                    title: 'Create Announce',
                    form: [
                        {
                            label: 'Title',
                            type: 'text',
                            name: 'title',
                            placeholder: 'Announce Exam',
                            ref: ref(item.title)
                        },
                        {
                            label: 'Content',
                            type: 'text',
                            name: 'content',
                            placeholder: 'About Exam',
                            ref: ref(item.content)
                        },
                        {
                            label: 'Show Until',
                            type: 'date',
                            name: 'show_until',
                            placeholder: '',
                            ref: ref(item.show_until)
                        },
                        {
                            label: '',
                            type: 'hidden',
                            name: 'id',
                            placeholder: '',
                            ref: ref(item.id)
                        },
                    ],
                    action: {
                        title: 'Edit Announce'
                    },
                },
                submit: (fromData)=>{
                    store.dispatch('editAnnounce',fromData);
                }
            });
        },
        del: (item)=>{
            openModal(FormModel,{
                toggle: ()=>closeModal(),
                model: {
                    title: 'Are you sure you want to delete this Announce?',
                    form: null,
                    action: {
                        title: ''
                    },
                },
                submit: ()=>{
                    store.dispatch('deleteAnnounce',item.id);
                }
            })
        },
    };
    onMounted(()=>{
        if(courseId==null){
            router.push({name: 'ClassRoom'});
            return;
        }
    });
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getClassNavs" :goRoute="goRoute" />
    <Addbar label="Create Announce" :add="create"/>
    
    <div v-if="store.getters.getClassDetail.length>0" class="px-8 pt-8 grid grid-cols-1 md:grid-cols-2 gap-4 select-none">
        <div v-for="(item, index) in store.getters.getClassDetail[3].data" :key="index">
            <ClassAnnounceCard :item="item" :actions="actions"/>
        </div>
    </div>
    <div class="my-32"></div>
</admin-layout></template>