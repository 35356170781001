<script setup>
    import UserLayout from '../../layout/UserLayout.vue';
    import { mdiAccountCircle, mdiPencilOutline, mdiLoading, mdiFacebook, mdiNavigationVariant, mdiWebCheck } from '@mdi/js';
    import Copy from '@/components/Copy.vue';
    import Share from '@/components/Share.vue';
    import { useStore } from 'vuex';
    import { ref, onMounted } from 'vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue';
    import FormData from 'form-data';
    import Icon from '@/components/Icon.vue';
    import router from '@/router';
    let store = useStore();let name = ref(null);let id = ref(null);let file = ref(null);let photo_url = ref(null);let phone = ref(null);let old_password = ref(null);let new_password = ref(null);let fb_link = ref(null);
    let loading = ref(false);
    let errMsg = ref('');
    let successMsg = ref('');
    function onImageChoose(ev){
        const file = ev.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            model.value.image = reader.result;
            model.value.image_url = reader.result;
        }
        reader.readAsDataURL(file);
    }
    const filePick = (event) => {
        photo_url.value=URL.createObjectURL(event.target.files[0]);
        file.value = event.target.files[0];
    }
    const save = ()=>{
        errMsg.value = '';successMsg.value = '';loading.value=true;
        let formData = new FormData();
        if(file.value) formData.append('photo_url', file.value);
        if(name.value) formData.append('name', name.value);
        if(phone.value && store.state.auth.phone!=phone.value) formData.append('phone', phone.value);
        if(old_password.value) formData.append('old_password', old_password.value);
        if(new_password.value) formData.append('new_password', new_password.value);
        if(fb_link.value) formData.append('fb_link', fb_link.value);
        store.dispatch('updateProfile',formData)
        .then((ans)=>{
            loading.value=false;
            successMsg.value=ans.data.message;
            let auth = {
                id: ans.data.user_data.id,
                phone: ans.data.user_data.phone,
                name: ans.data.user_data.name,
                token: store.state.auth.token,
                fb_link: ans.data.user_data.fb_link,
                photo_url: ans.data.user_data.photo_url,
                role: store.state.auth.role,
                updated_at: ans.data.user_data.updated_at,
            }
            store.commit('setAuth',auth);
        })
        .catch((error)=>{
            loading.value=false;
            if(error.response){
                let message = error.response.data.message;
                if(message) errMsg.value = message;
                if(message.phone) errMsg.value = message.phone[0];
                if(message.new_password) errMsg.value = message.new_password[0];
                if(message.old_password) errMsg.value = message.old_password[0];
                if(error.response.status==413) errMsg.value = 'your file too lerge!';
            }
        })
    }
    const logout = ()=>{
        store.commit('setAuth');
        store.commit('setUserCourses',[]);
        router.push({path:'/'});
    }
    const toRoom = () => router.push({name: 'ClassRooms'});
    const toAdmin = () => router.push({name: 'Dashboard'});
    onMounted( ()=>{
        store.dispatch('setDark',0);
        store.dispatch('checkUpdate');
        store.dispatch('getUserCourses');
        name.value = store.state.auth.name;
        id.value = store.state.auth.id;
        photo_url.value = store.state.base+store.state.auth.photo_url;
        phone.value = store.state.auth.phone;
        fb_link.value = store.state.auth.fb_link;
    });
    const titleStyle="mb-4 text-xl font-medium text-gray-900 dark:text-white";
    const labelStyle="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300";
    const inputStyle="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white";
    const labelFileStyle="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300";
    const inputFileStyle="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400";

    const description = (name)=>{
        return `This is ${name}'s profile page create by Shizuoka Language Center. Who is ${name} check it now!`;
    };
    const profile = ()=>{
        router.push({path: '/profile',query: {id: id.value}});
    };
</script>
<template><user-layout title="Profile" back="HomePage">
    <div class="container mx-auto">
        <!-- photo -->
        <div class="mt-12 md:mt-12 flex flex-col justify-center items-center">
            <div class="relative">
                <label for="photo_url" class="absolute h-12 w-12 bg-[#fc5f2b] dark:bg-[#4097aa] rounded-full bottom-0 right-0 flex flex-row justify-center items-center text-white cursor-pointer shadow-lg shadow-gray-400">
                    <Icon :path="mdiPencilOutline" size="30"/>
                </label>
                <span v-if="photo_url">
                    <img :src="photo_url" alt="photo" class="h-40 w-40 md:h-52 md:w-52 rounded-full shadow-lg shadow-gray-400">
                </span>
                <span v-else class="flex flex-row items-center justify-center h-40 w-40 md:h-52 md:w-52 rounded-full bg-white text-gray-400">
                    <Icon :path="mdiAccountCircle" size="200" w="14" h="14"/>
                </span>
                <!-- for image -->
                <input @change="filePick" class="hidden" name="photo_url" id="photo_url" type="file">
            </div>
            <div class="text-2xl mt-6">{{name}}</div>
        </div>
        <!-- photo -->
        <div class="flex justify-center h-12 items-center flex-row mt-8">
            <div class="text-gray-600 text-lg select-none">Share with</div>
            <Share network="facebook" :title="name +'\'s Profile'" :description="description(name)" :url="store.state.ownBase+`profile?id=${id}`" class="flex flex-row items-center h-full mx-3">
                <Icon action="share" :path="mdiFacebook" size="42" w="10" h="10" class="text-blue-700 w-10 h-10 rounded-full shadow-md shadow-gray-400"/>
            </Share>
            <Share network="telegram" :title="name +'\'s Profile'" :description="description(name)" :url="store.state.ownBase+`profile?id=${id}`" class="text-blue-700 flex flex-row items-center h-full">
                <Icon action="share" :path="mdiNavigationVariant" size="20" w="10" h="10" class="text-white bg-sky-400 rounded-full p-2 shadow-md shadow-gray-400"/>
            </Share>
            <!-- <Copy action="share" text="store.state.ownBase+`blogs/detail?id=${blog.id}`" id_name="#copy_link" class="ml-4 shadow-lg shadow-gray-300"/> -->
        </div>
        <div class="flex flex-row justify-center">
            <div @click="profile" class="w-fit mt-6 px-6 py-3 rounded-full flex flex-row items-center bg-primary text-white shadow-md shadow-gray-500 select-none cursor-pointer">
                <Icon :path="mdiWebCheck" size="25" class="mr-2"/> My Public Profile
            </div>
        </div>
        <div class="flex flex-row justify-center my-12">
            <button @click="logout" class="relative inline-flex items-center justify-center p-0.5 mb-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800">
                <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                    Log Out
                </span>
            </button>
            <button v-if="store.state.auth.role=='admin'||store.state.auth.role=='staff'||store.state.auth.role=='lecturer'" @click="toAdmin" type="button" class="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 ml-4 md:ml-8">
                Admin Panel
            </button>
            <button v-if="store.getters.getUserCourses.length>0" @click="toRoom" type="button" class="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 ml-4 md:ml-8">
                Class Room
            </button>
            <button v-else-if="store.getters.getLoading" type="button" class="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 ml-4 md:ml-8">
                Loading . . .
            </button>
        </div>
        <div class="text-gray-500 my-2 text-center">Account Information</div>
        <div class="mb-12 grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-6 md:gap-y-12">
            <div>
                <label for="name" :class="labelStyle">User Name</label>
                <!-- v-model="" -->
                <input v-model="name" type="text" name="name" id="name" :class="inputStyle">
            </div>
            <div>
                <label for="phone" :class="labelStyle">User Phone</label>
                <input v-model="phone" type="phone" name="phone" id="phone" :class="inputStyle">
            </div>
            <div>
                <label for="old_password" :class="labelStyle">Old Password</label>
                <input v-model="old_password" type="password" name="old_password" id="old_passwords" :class="inputStyle">
            </div>
            <div>
                <label for="new_password" :class="labelStyle">New Password</label>
                <input v-model="new_password" type="password" name="new_password" id="new_password" :class="inputStyle">
            </div>
            <div>
                <label for="fb_link" :class="labelStyle">FaceBook Account Link</label>
                <input v-model="fb_link" type="url" name="fb_link" id="fb_link" :class="inputStyle">
            </div>
        </div>
        <div v-if="errMsg" class="flex flex-row items-center font-semibold px-6 py-6 text-red-800 text-lg bg-red-200">
            {{errMsg}}
        </div>
        <div v-if="successMsg" class="flex flex-row items-center font-semibold px-6 py-6 text-green-800 text-lg bg-green-200">
            {{successMsg}}
        </div>
        <div class="flex flex-row items-center justify-center my-12 md:my-20">
            <button @click="save" type="button" class="text-white bg-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 shadow-lg shadow-gray-400 flex flex-row items-center justify-center">
                <span class="mr-2">Save Change</span> <Icon v-if="loading" :path="mdiLoading" size="20" class="animate-spin select-none"></Icon>
            </button>
        </div>
        <div class="h-12"></div>
    </div>
    <!-- <div class="my-32"></div> -->
</user-layout></template>