<script setup>
    import Icon from '@/components/Icon.vue';
    import { mdiMenu, mdiArrowLeft } from '@mdi/js';
    import { ref,onMounted,onBeforeUnmount } from 'vue';
    import MDropdown from '@/components/user/MDropdown.vue';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    import router from '@/router';
    const isDrop = ref(false);
    let store = useStore();
    let currentRoute = useRoute().path;
    onMounted(() => window.addEventListener('click', forceClose));
    onBeforeUnmount(() => window.removeEventListener('click', forceClose));
    const dropdownEle = ref(null);
    const dropdownMIcon = ref(null);
    let props = defineProps({
        title:{
            type: String,
            required: false,
            default: ''
        },
        centerTitle:{
            type: Boolean,
            required: false,
            default: true
        },
        back:{
            type: String,
            required: false,
            default: null
        }
    });
    const forceClose = event => {
        if(currentRoute=='/'){
            if (!dropdownEle.value.$el.contains(event.target)&&!dropdownMIcon.value.$el.contains(event.target)) isDrop.value = false;
        }
    }
    const back = ()=>{
        if (props.back!=null) {
            router.push({name: props.back});
        } else {
            router.back();
        }
    }
    const isLogin = store.state.auth.token!='';
</script>
<template>
    <div v-if="currentRoute=='/'" class="bg-white fixed top-0 z-50 w-full font-dmmo text-primary font-medium shadow-lg">
        <!-- home view -->
        <div class="flex flex-row items-center justify-between px-8 lg:px-24 h-20 lg:h-28">
            <!-- logo -->
            <div class="h-20 w-20 lg:h-28 lg:w-28 p-2 lg:p-3">
                <img src="@/assets/school.jpg" class="" alt="shizuoka">
            </div>
            <!-- Nav -->
            <div class="hidden lg:flex flex-row items-start">
                <div class="mt-2 flex flex-row">
                    <router-link to="/" class="flex flex-col items-center ">
                        <div class="nav">Home</div>
                        <div v-if="currentRoute == '/'" class="w-3/4 h-1 rounded-full bg-[#F98E04] mt-1"></div>
                    </router-link>
                    <router-link to="/courses" class="flex flex-col items-center ">
                        <div class="nav">Courses</div>
                        <div v-if="currentRoute.includes('/courses')" class="w-3/4 h-1 rounded-full bg-[#F98E04] mt-1"></div>
                    </router-link>
                    <router-link to="/blogs" class="flex flex-col items-center ">
                        <div class="nav">Blog</div>
                        <div v-if="currentRoute.includes('/blogs')" class="w-3/4 h-1 rounded-full bg-[#F98E04] mt-1"></div>
                    </router-link>
                    <router-link to="/tests" class="flex flex-col items-center ">
                        <div class="nav">Tests</div>
                        <div v-if="currentRoute.includes('/tests')" class="w-3/4 h-1 rounded-full bg-[#F98E04] mt-1"></div>
                    </router-link>
                    <router-link to="/about" class="flex flex-col items-center ">
                        <div class="nav">About Us</div>
                        <div v-if="currentRoute.includes('/about')" class="w-3/4 h-1 rounded-full bg-[#F98E04] mt-1"></div>
                    </router-link>
                </div>
                <router-link :to="isLogin?'/user-profile':'/login'" :class="{'font-semibold': currentRoute.includes('/login')}" class="nav rounded-lg bg-primary px-6 py-2 text-white">{{isLogin?'Profile':'Login'}}</router-link>
            </div>
            <!-- mobile -->
            <div class="flex lg:hidden flex-col justify-center p-1 text-primary ring-primary ring-2 rounded-md cursor-pointer select-none">
                <Icon @click="isDrop=!isDrop" ref="dropdownMIcon" :path="mdiMenu" size="35" w="10" h="10" />
            </div>
        </div>
        <MDropdown :callBack="()=>isDrop=false" :isDrop="isDrop" ref="dropdownEle"/>
    </div>
    <div v-else class="bg-white fixed top-0 z-40 w-full font-dmmo text-primary font-medium shadow-lg">
        <!-- bg-pink-500 -->
        <div class="relative flex flex-row bg-white lg:bg-white items-center lg:justify-between px-1 lg:px-24 h-14 lg:h-28">
            <!-- logo -->
            <div class="hidden lg:block h-28 w-28 p-0 lg:p-3">
                <img src="@/assets/school.jpg" class="" alt="shizuoka">
            </div>
            <div @click="back" class="z-50 lg:hidden text-primary flex flex-col justify-center p-2 select-none cursor-pointer">
                <Icon :path="mdiArrowLeft" size="30" w="" h=""/>
            </div>
            <!-- Nav -->
            <div class="hidden lg:flex flex-row items-center">
                <div class="mt-2 flex flex-row">
                    <router-link to="/" class="flex flex-col items-center ">
                        <div class="nav">Home</div>
                        <div v-if="currentRoute == '/'" class="w-3/4 h-1 rounded-full bg-[#F98E04] mt-1"></div>
                    </router-link>
                    <router-link to="/courses" class="flex flex-col items-center ">
                        <div class="nav">Courses</div>
                        <div v-if="currentRoute.includes('/courses')" class="w-3/4 h-1 rounded-full bg-[#F98E04] mt-1"></div>
                    </router-link>
                    <router-link to="/blogs" class="flex flex-col items-center ">
                        <div class="nav">Blog</div>
                        <div v-if="currentRoute.includes('/blogs')" class="w-3/4 h-1 rounded-full bg-[#F98E04] mt-1"></div>
                    </router-link>
                    <router-link to="/tests" class="flex flex-col items-center ">
                        <div class="nav">Tests</div>
                        <div v-if="currentRoute.includes('/tests')" class="w-3/4 h-1 rounded-full bg-[#F98E04] mt-1"></div>
                    </router-link>
                    <router-link to="/about" class="flex flex-col items-center ">
                        <div class="nav">About Us</div>
                        <div v-if="currentRoute.includes('/about')" class="w-3/4 h-1 rounded-full bg-[#F98E04] mt-1"></div>
                    </router-link>
                </div>
                <router-link :to="isLogin?'/user-profile':'/login'" :class="{'font-semibold': currentRoute.includes('/login')}" class="nav rounded-lg bg-primary px-6 py-2 text-white">{{isLogin?'Profile':'Login'}}</router-link>
            </div>
            <!-- mobile -->
            <div :class="{'justify-center':centerTitle}" class="absolute z-40 flex flex-row w-full items-center lg:hidden select-none text-primary text-lg h-14 px-10">
                {{title}}
            </div>
        </div>
    </div>
</template>