import axios from 'axios';

const state = {
   
};

const getters = {
   
};

const actions = {
    async getGeo({commit,rootState}){
        let baseUrl='http://geonode.themimu.info/geoserver/wfs?srsName=EPSG%3A4326&typename=geonode%3Ammr_polbnda_adm1_mimu_250k&outputFormat=json&version=1.0.0&service=WFS&request=GetFeature';
        const response = await fetch(baseUrl);
        const data = await response.json();
        console.log(data.features[0].type);
        // axios.get(`${baseUrl}`,{

        // }
        // ).then(function(ans){
        //     console.log(ans.features[0].type);
        //     // commit('setAccounts',data.data);   
        // }).catch(function(error){
        //     // commit('checkError',error);
        // });
    },
};

const mutations = {
    

};

export default {
    state, getters, actions, mutations
}