<script setup>
import AdminLayout from '../../../layout/AdminLayout.vue';
import Navbar from '@/components/admin/Navbar.vue'
import router from '@/router';
import { ref,onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import DataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css'; 
    const headers = [
    { text: "Name", value: "name", sortable: true },
    { text: "Score", value: "score", sortable: true },
    { text: "Status", value: "status"}
];
let store = useStore();let route = useRoute();
const testId = route.params.id;
let table = ref({
    headers:['No.','Name','Score','Status'],
    bodys:[]
})
const goRoute = (name,params,index)=>{
    let navs=[];
    for(let x=0;x<index+1;x++){
        navs = [...navs,store.getters.getClassNavs[x]];
    }
    store.commit('setClassNavs',navs);
    router.push({name: name, params: params});
};
onMounted(()=>{
    if(testId==null){
        router.push({name: 'ClassRoom'});
        return;
    }
    store.dispatch('getTest',testId)
    .then(function(data){
        let list = data.data.message.scores.map(val=>{
            return {user_name: val.user_name,score: val.score};
        });
        table.value.bodys=list;
    }).catch(function(error){
        console.log(error);
    });
    // console.log(testId);
});
const jinn = (ref)=> ref.length==1? 'h-36':ref.length==2? 'h-20': ref.length==3? 'h-12':'';
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getClassNavs" :goRoute="goRoute" />
    <div class="h-20 flex flex-row bg-slate-300 dark:bg-side-bg-dark select-none items-center justify-center text-xl">list of examiner</div>
    <div class="px-8 md:px-12 py-8 z-0">
        <DataTable :headers="headers" :items="table.bodys" :rows-per-page="10">
            <template #name="{user_name}">
                <div class="flex flex-row items-center justify-start">
                    {{user_name}}
                </div>
            </template>
            <template #score="{score}">
                <div class="flex flex-row items-center justify-start">
                    {{score}}
                </div>
            </template>
            <template #status="{score}">
                <div class="flex flex-row items-center justify-start my-1" :class="jinn(table.bodys)">
                    <div v-if="score>=19" class="">
                        <div class="w-16 py-1 bg-green-300 dark:bg-green-300 rounded-full text-green-800 select-none text-center">passed</div>
                    </div>
                    <div v-else class="">
                        <div class="w-16 py-1 bg-red-300 dark:bg-red-200 rounded-full text-yellow-800 select-none text-center">fail</div>
                    </div>
                </div>
            </template>
        </DataTable>
    </div>
</admin-layout></template>

<!--
created_at: "2022-06-11T12:56:35.000000Z"
enroll_id: 19
id: 1
score: 45
test_id: 11
updated_at: "2022-06-11T12:56:35.000000Z"
user_name: "Arkar Mann Aung"
-->