<script setup>
import { reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { mdiCog } from '@mdi/js'
const sectionBgBase = 'bg-gradient-to-tr'
const sectionBgLogin = `${sectionBgBase} from-purple-400 via-pink-500 to-red-500`
const sectionBgLoginDark = `${sectionBgBase} from-purple-900 via-pink-900 to-red-900`
const sectionBgError = `${sectionBgBase} from-pink-400 via-red-500 to-yellow-500`
const sectionBgErrorDark = `${sectionBgBase} from-pink-900 via-red-900 to-yellow-900`

const store = useStore();
let data = reactive({
    // phone: '09001',
    // password: 'admin001',
    phone: 'admin',
    password: '12345678',
    bg: 'login',
});
const props = defineProps({
  bg: {
    type: String,
    required: false,
    default: 'login',
    validator: value => ['login', 'error'].includes(value)
  }
})
function submit() {
    // alert(`hello ${data.phone} ${data.password}`);
    store.dispatch('login',{
        phone: data.phone,
        password: data.password,
    });
}
const colorClass = computed(() => {
  switch (data.bg) {
    case 'login':
      return store.state.dark==1? sectionBgLoginDark : sectionBgLogin
    case 'error':
      return store.state.dark==1? sectionBgErrorDark : sectionBgError
  }

  return ''
})
const color = "bg-gradient-to-br to-primary via-sky-400 from-pink-400";
</script>
<template>
    <div 
        class="flex h-screen items-center justify-center"
        :class="color"
    >
        <div class="h-screen font-sans login bg-cover" :class="{'opacity-50':store.getters.loginOnLoad}">
        <div class="container mx-auto h-full flex flex-1 justify-center items-center">
            <div class="w-full max-w-lg">
            <div class="flex flex-row justify-center -ml-2">
                <img src="logo.png" alt="logo" class="w-32 h-32">
            </div>
            <div class="leading-loose">
                <div class="max-w-sm m-4 p-10 bg-primary bg-opacity-100 rounded shadow-xl">
                    <p class="text-white text-center text-lg font-bold">LOGIN</p>
                    <div class="">
                        <label class="block text-sm text-white" for="phone">Phone Number</label>
                        <input v-model="data.phone" class="w-full px-5 py-1 text-gray-700 bg-gray-300 rounded focus:outline-none focus:bg-white" type="phone" id="phone"  placeholder="09xxxxxxxxx" aria-label="phone" required>
                    </div>
                    <div class="mt-2">
                        <label class="block  text-sm text-white">Password</label>
                        <input v-model="data.password" class="w-full px-5 py-1 text-gray-700 bg-gray-300 rounded focus:outline-none focus:bg-white"
                        type="password" id="password" placeholder="Enter Your Password" arial-label="password" required>
                    </div>
                    <div v-if="store.getters.loginErrorMsg" class="text-red-500 opacity-70 bg-white mt-3 rounded-lg text-center text-sm py-2 px-2">
                        {{store.getters.loginErrorMsg}}
                    </div>
                    <div class="mt-4 items-center flex justify-center">
                        <button @click="submit" class="px-4 py-1 text-white font-light tracking-wider bg-gray-900 hover:bg-gray-800 rounded"
                        type="submit">Enter</button>
                    </div>
                    <div class="text-center">
                        <a href="/" class="inline-block right-0 align-baseline font-light text-gray-300 text-sm text-500 mt-2 hover:text-red-400">
                            Return Home?
                        </a>
                    </div>

                </div>

            </div>
            </div>
        </div>
        </div>

    </div>
</template>