<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import ClassCard from '@/components/admin/ClassRoom/ClasssCard.vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue'
    import LoadingCard from '@/components/admin/ClassRoom/LoadingCard.vue';
    import { ref, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import router from '@/router';
    let store = useStore();
    const add = ()=>{
        const options = [
            {value:'-1',text:'သင်တန်းလက်ခံပြီ'},
            {value:'0',text:'လူပြည့်ပါပြီ'},
            {value:'1',text:'သင်တန်းစနေပြီ'},
            {value:'2',text:'သင်တန်းပြီးပြီ'}
        ];
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Add New Class',
                form: [
                    {
                        label: 'New Class Name',
                        type: 'text',
                        name: 'name',
                        placeholder: 'Basic + N5 + N4 Course',
                        ref: ref('')
                    },
                    {
                        label: 'description',
                        type: 'text',
                        name: 'description',
                        placeholder: 'about class',
                        ref: ref('')
                    },
                    {
                        label: 'class status',
                        type: 'select',
                        name: 'status',
                        options: options,
                        ref: ref('-1')
                    },
                    {
                        label: 'Price',
                        type: 'text',
                        name: 'price',
                        placeholder: '250000',
                        ref: ref('')
                    },
                    {
                        label: 'Remark',
                        type: 'text',
                        name: 'remark',
                        placeholder: 'စာအုပ်ဖိုးအပါ',
                        ref: ref('')
                    },
                    {
                        label: 'Class Time',
                        type: 'text',
                        name: 'class_time',
                        placeholder: '1:00 PM - 4:00 PM',
                        ref: ref('')
                    },
                    {
                        label: 'Class Day',
                        type: 'text',
                        name: 'class_day',
                        placeholder: 'Tue to Fri',
                        ref: ref('')
                    },
                    {
                        label: 'Course Period',
                        type: 'text',
                        name: 'period',
                        placeholder: '3 Month',
                        ref: ref('')
                    },
                    {
                        label: 'Class Start At',
                        type: 'date',
                        name: 'start_at',
                        placeholder: '2022-06-01',
                        ref: ref('')
                    },
                ],
                action: {
                    title: 'Add New Class'
                },
            },
            style: 'h-3/4',
            submit: (fromData)=>{
                store.dispatch('addNewClass',fromData);
            }
        });
    };    
    const actions = {
        detail: (id,name) =>{
            const navs = [
                {
                    routeName: 'ClassRoom',
                    title: 'Class',
                    params: {},
                },
                {
                    routeName: 'ClassDetail',
                    title: name,
                    params: {
                        name: name,
                        id: id    
                    },
                }
            ];
            // alert('detail '+ name);
            store.commit('setClassNavs',navs);
            router.push({name: 'ClassDetail', params: { name: name, id: id }});
        },
        edit: (id,name) => {
            let listOne = typeof store.getters.getClasses.newClasses=='object'? store.getters.getClasses.newClasses.filter( a=>a.id==id ):[];
            let listTwo = typeof store.getters.getClasses.ongoingClasses=='object'? store.getters.getClasses.ongoingClasses.filter( a=>a.id==id ):[];
            let listThree = typeof store.getters.getClasses.endClasses=='object'? store.getters.getClasses.endClasses.filter( a=>a.id==id ):[];
            let oldData = [...listOne,...listTwo,...listThree][0];
            const options = [
                {value:'-1',text:'သင်တန်းလက်ခံပြီ'},
                {value:'0',text:'လူပြည့်ပါပြီ'},
                {value:'1',text:'သင်တန်းစနေပြီ'},
                {value:'2',text:'သင်တန်းပြီးပြီ'}
            ];
            openModal(FormModel, {
                toggle: ()=>closeModal(),
                model: {
                    title: 'Update Class',
                    form: [
                        {
                            label: 'Update Class Name',
                            type: 'text',
                            name: 'name',
                            placeholder: 'Basic + N5 + N4 Course',
                            ref: ref(name)
                        },
                        {
                            label: 'Update description',
                            type: 'text',
                            name: 'description',
                            placeholder: 'about class',
                            ref: ref(oldData.description)
                        },
                        {
                            label: 'class status',
                            type: 'select',
                            name: 'status',
                            options: options,
                            ref: ref(oldData.status)
                        },
                        {
                            label: 'Price',
                            type: 'text',
                            name: 'price',
                            placeholder: '250000',
                            ref: ref(oldData.price)
                        },
                        {
                            label: 'Remark',
                            type: 'text',
                            name: 'remark',
                            placeholder: 'စာအုပ်ဖိုးအပါ',
                            ref: ref(oldData.remark)
                        },
                        {
                            label: 'Class Time',
                            type: 'text',
                            name: 'class_time',
                            placeholder: '1:00 PM - 4:00 PM',
                            ref: ref(oldData.class_time)
                        },
                        {
                            label: 'Class Day',
                            type: 'text',
                            name: 'class_day',
                            placeholder: 'Tue to Fri',
                            ref: ref(oldData.class_day)
                        },
                        {
                            label: 'Course Period',
                            type: 'text',
                            name: 'period',
                            placeholder: '3 Month',
                            ref: ref(oldData.period)
                        },
                        {
                            label: 'Class Start At',
                            type: 'date',
                            name: 'start_at',
                            placeholder: '2022-06-01',
                            ref: ref(oldData.start_at)
                        },
                        {
                            label: '',
                            type: 'hidden',
                            name: 'id',
                            placeholder: '',
                            ref: ref(id)
                        },
                    ],
                    action: {
                        title: 'Update Class'
                    },
                },
                style: 'h-3/4',
                submit: (fromData)=>{
                    store.dispatch('editClass',fromData);
                }
            });
        },  //alert(name+ ' edit '+id),
        del: id => {
            openModal(FormModel, {
                toggle: ()=>closeModal(),
                model: {
                    title: 'Are you sure you want to delete this Class?',
                    form: null,
                    action: {
                        title: 'Update Class'
                    },
                },
                submit: ()=>{
                    store.dispatch('deleteClass',id);
                }
            });
        }
    };
    onMounted(()=>{
        store.dispatch('checkUpdate');
        if(store.state.auth.role=='user' || store.state.auth.role=='student'){
            store.commit('toNamed',{name: 'HomePage'});return;
        }
        store.commit('setClasses','reset');
        store.dispatch('getClasses');
    });
</script>
<template><admin-layout>
    <div class="flex flex-row h-20 items-center pl-8 md:pl-12 text-xl cursor-pointer">
        Class
    </div>
    <Addbar label="Create Class" :add="add"/>

    <div class="px-8 pt-8 grid grid-cols-1 md:grid-cols-1 gap-4 select-none">

        <div class="text-xl font-bold tracking-wider">အတန်းအသစ်များ</div>
        <div v-if="store.getters.getClasses.newClasses.length>0">
            <div v-for="data,index in store.getters.getClasses.newClasses" :key="index" class="mb-4">
                <ClassCard :id="data.id" :name="data.name" :description="data.description" :status="parseInt(data.status)" :price="parseInt(data.price)" :remark="data.remark" :class_time="data.class_time" :class_day="data.class_day" :period="data.period" :start_at="data.start_at" :actions="actions"/>        
            </div>
        </div>
        <div v-else-if="store.getters.getClasses.newClasses==-1" class="text-center py-2">
            There is no data!
        </div>
        <div v-else>
            <LoadingCard :count="1"/>
        </div>


        <div class="text-xl font-bold tracking-wider mt-4">သင်ကြားဆဲဲသင်တန်းများ</div>
        <div v-if="store.getters.getClasses.ongoingClasses.length>0">
            <div v-for="data,index in store.getters.getClasses.ongoingClasses" :key="index" class="mb-4">
                <ClassCard :id="data.id" :name="data.name" :description="data.description" :status="parseInt(data.status)" :price="parseInt(data.price)" :remark="data.remark" :class_time="data.class_time" :class_day="data.class_day" :period="data.period" :start_at="data.start_at" :actions="actions"/>
            </div>
        </div>
        <div v-else-if="store.getters.getClasses.ongoingClasses==-1" class="text-center py-2">
            There is no data!
        </div>
        <div v-else>
            <LoadingCard :count="1"/>
        </div>


        <div class="text-xl font-bold tracking-wider mt-4">ပြီးဆုံးသောသင်တန်းများ</div>
        <div v-if="store.getters.getClasses.endClasses.length>0">
            <div v-for="data,index in store.getters.getClasses.endClasses" :key="index" class="mb-4">
                <ClassCard :id="data.id" :name="data.name" :description="data.description" :status="parseInt(data.status)" :price="parseInt(data.price)" :remark="data.remark" :class_time="data.class_time" :class_day="data.class_day" :period="data.period" :start_at="data.start_at" :actions="actions"/>        
            </div>
        </div>
        <div v-else-if="store.getters.getClasses.endClasses==-1" class="text-center py-2">
            There is no data!
        </div>
        <div v-else>
            <LoadingCard :count="1"/>
        </div>
        
        <input type="hidden" id="class_link"/>
    </div>
    <!-- changes for blank space too high -->
    <div class="my-32"></div>  
</admin-layout></template>