<script setup>
let props = defineProps({
    network:{
        type: String,
        required: true,
    },
    url:{
        type: String,
        required: true,
    },
    title:{
        type: String,
        required: true,
    },
    description:{
        type: String,
        required: true,
    },
})
// const description="We will write blogs that will benefit students with knowledge of Japanese language.";
const hashtags = "Japanese School,Japanese Language";
</script>

<template>
    <ShareNetwork
        :network="network"
        :url="url"
        :title="title"
        :description="description"
        :quote="description"
        :hashtags="hashtags"
    >
        <slot/>
    </ShareNetwork>
</template>