<script setup>
    import UserLayout from '../../layout/UserLayout.vue';
    import Icon from '@/components/Icon.vue';
    import { mdiEye, mdiLoading } from '@mdi/js';
    import { onMounted, ref, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import router from '@/router';
    let store = useStore();let route = useRoute();
    let id = route.query.id;
    let blog = ref(null);
    const style = (obj)=>{
        var css = [];
        // size
        if(obj.hasOwnProperty('size')){
            if(obj.size=='small') css=[...css,'text-xs'];
            if(obj.size=='large') css=[...css,'text-xl'];
            if(obj.size=='huge')  css=[...css,'text-4xl'];
        }
        // size
        // style
        if(obj.hasOwnProperty('bold')) css=[...css,'font-bold'];
        if(obj.hasOwnProperty('italic')) css=[...css,'italic'];
        if(obj.hasOwnProperty('underline')) css=[...css,'underline'];
        if(obj.hasOwnProperty('strike')) css=[...css,'line-through'];
        // style
        // align
        if(obj.hasOwnProperty('align')){
            if(obj.align=='center') css=[...css,'text-center'];
            if(obj.align=='right') css=[...css,'text-right'];
            if(obj.align=='justify') css=[...css,'text-justify'];
        }
        // align
        // list
        if(obj.hasOwnProperty('list')){
            if(obj.list=='ordered') css = [...css,'ml-8 list-decimal'];
            if(obj.list=='bullet') css = [...css,'ml-8 list-disc'];
        }
        // list
        return css;
    }
    const tailwind = (delta)=>{
        if(delta==null) return;
        let html = '';
        let design = {
            div_class: '',
            contents: []
        };
        let data = []; 
        if(delta.hasOwnProperty('ops')){
            delta.ops.forEach( (element,index) => {
                if(element.insert.includes('\n')){
                    design.div_class=element.hasOwnProperty('attributes')?style(element.attributes):'';
                    let list = element.insert.split("\n");
                    let first = true;
                    list.forEach(ls => {
                        if(first){
                            design.contents.push({
                                class: '',
                                span: tab(ls)
                            });
                            data.push(design);
                            clear();
                            first=false;
                        }else{
                            design.contents.push({
                                class: '',
                                span: tab(ls)
                            });
                            data.push(design);
                            clear();
                        }
                    });
                    clear();
                }else{
                    design.contents.push({
                        class: element.hasOwnProperty('attributes')?style(element.attributes):'',
                        span: tab(element.insert)
                    });
                    let nextObj = delta.ops[index+1];
                    // if(nextObj.hasOwnProperty('attributes')){
                    //     if(nextObj.attributes.hasOwnProperty('list')){
                    //         data.push(design);
                    //         clear();
                    //     }
                    // }
                }
            });
        }
        function tab(tab){
            if(tab.includes('\t')){
                let str='';
                let list = tab.split("\t");
                list.forEach(ans => {
                    if(ans=='') str+='&nbsp&nbsp&nbsp&nbsp&nbsp';
                    else str+=ans;
                });
                return str;
            }else{
                return tab;
            }
        }
        function clear(){
            design = {
                div_class: '',
                contents: []
            }
        };
        return data;
    }
    let bTitle = computed(()=>{
        return blog.value==null? '' : blog.value.title;
    })
    onMounted(()=>{
        store.dispatch('getBlogsDetail',id)
        .then(val=>{
            blog.value = val.data.data;
        })
    });
    const color = "bg-gradient-to-br to-primary via-sky-400 from-pink-400";
    //  bg-gradient-to-tr from-red-400 via-pink-500 to-purple-500
</script>
<template><user-layout title="Blog" back="UserBlog">
    <div v-if="blog" :class="color" class="flex flex-col flex-1 p-4 md:px-32 md:py-16 lg:px-48 lg:py-28">
        <div class="block bg-white rounded-2xl pb-6 md:pb-10 h-screen overflow-y-auto scrollbar-hide">
            <div class="flex flex-row mb-6 relative">
                <img :src="store.state.base+blog.img_url" class="w-full rounded-t-2xl overflow-hidden" alt="">
                <div class="absolute bottom-0 flex flex-row justify-start items-center w-full">
                    <div class="bg-indigo-200 opacity-75 px-8 py-2 text-black font-bold text-sm md:text-2xl">{{blog.title}}</div>
                </div>
            </div>
            <div class="mx-4 md:mx-8 flex flex-row justify-between items-center">
                <div class="text-gray-600">
                    Author : {{blog.author.name}}
                </div>
                <div class="flex flex-row items-center text-gray-600">
                    <Icon :path="mdiEye" size="30" class="mr-3"/>
                    <span>{{blog.view}}</span>
                </div>
            </div>
            <div v-for="(item, index) in tailwind(JSON.parse(blog.subtitle))" :key="index" class="mx-4 md:mx-8 text-black">
                <div v-if="item.contents[0].span!=''" :class="item.div_class" class="text-sm">
                    <span class="leading-loose" v-html="content.span" :class="content.class" v-for="(content, index) in item.contents" :key="index"></span>
                </div>
                <div v-else >
                    <br>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="flex flex-col items-center pt-20 pb-72">
        <Icon :path="mdiLoading" size="40" w="0" h="0" class="animate-spin text-[#F98E04]"/>
        <div class="text-gray-500 mt-8 animate-pulse">Loading . . .</div>
    </div>
</user-layout></template>