<script setup>
    import { onMounted } from 'vue';
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import AccountCard from '@/components/admin/Account/accountCard.vue';
    import DataTable from '@/components/admin/Test/dataTable.vue';
    import { mdiAccountTie, mdiAccountSchool, mdiAccountPlusOutline ,mdiDeleteOutline , mdiEye ,mdiAccountMultiple, mdiAccountStar } from '@mdi/js';
    import Icon from '@/components/Icon.vue';
    import router from '@/router';
    import { useStore } from 'vuex';
    let store = useStore();

    const checkRole = (role)=>{
        if(role=='admin') return `you can only access "STAFF","LECTURER" AND "STUDENT".`;
        if(role=='staff') return `you can only access "LECTURER" AND "STUDENT".`;
        if(role=='lecturer') return `you can not manage other account!.`;
        else return `you have no access right.`;
    };
    onMounted(()=>{
        store.dispatch('checkUpdate');
        if(store.state.auth.role=='user' || store.state.auth.role=='student'){
            store.commit('toNamed',{name: 'HomePage'});return;
        }
        store.dispatch('getAccounts'); 
    });
    const detail = (id,name) => {
        const navs = [
            {
                routeName: 'Account',
                title: 'Account',
                params: {},
            },
            {
                routeName: 'AccountDetail',
                title: name,
                params: {
                    name: name,
                    id: id
                },
            }
        ];
        store.commit('setAccountNavs',navs);
        router.push({name: 'AccountDetail', params: { name: name, id: id }});
    };
</script>
<template>
    <admin-layout>
    <div class="flex flex-row h-20 items-center pl-8 md:pl-12 text-xl cursor-pointer select-none">
        Account
    </div>
    <div class="flex flex-col md:flex-row py-7 items-center justify-end md:pr-12 text-xl bg-slate-300 dark:bg-side-bg-dark select-none">
        <span>Your Role is</span> <span class="mx-2 font-bold uppercase">{{store.state.auth.role}}</span> <span class="text-center">{{checkRole(store.state.auth.role)}}</span>
    </div>
    <div v-if="store.getters.getUsers.length>0" class="px-8 pt-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 select-none">
        <AccountCard :datas="store.getters.getUsers" :detail="detail"/>
    </div>
    <div v-else class="px-8 pt-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 select-none">
        <div v-for="v,i in 4" :key="i" class="animate-pulse bg-gray-400 dark:bg-gray-600 flex flex-row justify-between p-4 rounded-md hover:drop-shadow-2xl cursor-pointer">
            <div class="flex flex-col ml-1 items-center justify-center">
                <div class="bg-gray-300 dark:bg-gray-500 text-xl p-1 font-semibold w-20 h-4">
                </div>
                <div class="text-4xl pt-2 p-1 font-bold">
                    <h1 class="text-gray-300 dark:text-gray-500">?</h1>
                </div>
            </div>
            <div class="items-center mt-4 text-gray-300 dark:text-gray-500">
                <Icon :path="mdiAccountMultiple" size="80" w="w-18" h="h-18" class="px-4"/>
            </div>
        </div>
    </div>
    <div class="h-20"></div>
    </admin-layout>
</template>