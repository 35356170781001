<script setup>
    import UserLayout from '../../layout/UserLayout.vue';
    import Course from '@/components/user/course/Course.vue';
    import router from '@/router';
    import { onMounted } from 'vue';
    import { useStore } from 'vuex';
    import Footer from '@/components/Footer.vue';
    let store = useStore();
    onMounted( ()=>{
        if(store.getters.getUserCourses.length==0){
            store.dispatch('getUserCourses');
        }
    });
    let detail = (course)=> router.push({name: 'Room', params:{id:course.id, name:course.name}});
</script>
<template><user-layout title="Your Class" back="UserProfile">
    <div class="container mx-auto mt-4 md:mt-8 lg:mt-10">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 lg:gap-10">
            <Course v-for="course,index in store.getters.getUserCourses" :key="index" :course="course" :detail="detail"/>   
        </div>
    </div>
    <div class="my-32"></div>
    <Footer :class="{'fixed bottom-0':true}" :small="true"/>
</user-layout></template>