<script setup>
    import Icon from '@/components/Icon.vue';
    import { mdiClockTimeTwoOutline, mdiCalendarCheck, mdiRunFast, mdiCash, mdiInformationOutline, mdiChevronDown, mdiChevronUp } from '@mdi/js';
    import { ref } from 'vue';
    import router from '@/router';
    let show = ref(false);
    const toggle = ()=>show.value = !show.value;
    const getStatus = (status)=> status==-1? 'ကျောင်းအပ်လက်ခံနေပါပြီ' : 'လူပြည့်သွားပါပြီ';
    let props = defineProps({
        item: {
            type: Object,
            required: true
        }
    });
    const enroll = id=> router.push({path: '/enroll', query:{id}});
    const iconMargin = 'mr-4 text-primary shadow-md shadow-gray-300 rounded-full';
    const verMargin = 'my-3';
</script>

<template>
    <!-- <div class="p-2 rounded-lg bg-gradient-to-br from-red-200 via-red-300 to-yellow-200"> -->
    <div class="p-3 rounded-lg bg-gradient-to-br to-primary via-sky-400 from-pink-400">
        <div class="bg-white p-3.5 rounded-md text-gray-500 h-full">
            <!-- title -->
            <div class="flex flex-row justify-between">
                <div class="text-gray-700 text-lg font-semibold">{{item.name}}</div>
                <div class="text-amber-500 font-semibold">{{getStatus(item.status)}}</div>
            </div>
            <!-- စတင်မည့်ရက် -->
            <div :class="verMargin" class="flex flex-row">
                <Icon :class="iconMargin" :path="mdiRunFast" size="30"/>
                {{item.start_at}}
            </div>
            <!-- သင်တန်းကြေး -->
            <div :class="verMargin" class="flex flex-row">
                <Icon :class="iconMargin" :path="mdiCash" size="30"/>
                {{item.price}} ကျပ်
            </div>
            <!-- မှတ်ချက် -->
            <div :class="verMargin" class="flex flex-row">
                <Icon :class="iconMargin" :path="mdiInformationOutline" size="30"/>
                {{item.remark}}
            </div>
            <!-- အချိန် -->
            <div :class="verMargin" class="flex flex-row">
                <Icon :class="iconMargin" :path="mdiClockTimeTwoOutline" size="30"/>
                {{item.class_time}} ({{item.class_day}})
            </div>
            <!-- သင်တန်းကာလ -->
            <div :class="verMargin" class="flex flex-row">
                <Icon :class="iconMargin" :path="mdiCalendarCheck" size="30"/>
                {{item.period}}
            </div>
            <!-- mdiChevronDown, mdiChevronUp -->
            <div @click="toggle" class="relative">
                <div class="p-0.5 cursor-pointer bg-gradient-to-br from-pink-500 to-orange-400 rounded-lg">
                    <div :class="show?'rounded-t-md':'rounded-md'" class="flex flex-row justify-between p-2 bg-white select-none">
                        <div>အသေးစိပ်အချက်အလက်များကြည့်ရန်</div>
                        <Icon :path="show?mdiChevronUp:mdiChevronDown" size="30"/>
                    </div>
                </div>
                 <!-- rounded-lg -->
                <div v-if="show" class="-mt-1.5 text-justify select-none flex flex-row w-full px-0.5 pb-0.5 bg-gradient-to-br from-pink-500 to-orange-400 rounded-b-lg absolute z-10">
                    <span class="bg-white rounded-b-md p-2 w-full">{{item.description}}</span> 
                </div>
            </div>
            
            <div class="flex flex-row justify-center mt-5 z-0">
                <!-- https://www.facebook.com/Shizuoka-Japanese-Language-Center-1763915927007452 -->
                <button @click="enroll(item.id)" class="relative inline-flex items-center justify-center p-1 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary via-sky-400 to-pink-400 group-hover:from-purple-500 group-hover:to-pink-500 dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800">                
                    <span class="relative px-5 py-2.5 transition-all hover:font-semibold ease-in duration-75 bg-white dark:bg-gray-900 rounded-md">
                        Enroll Now
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>