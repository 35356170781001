<script setup>
    import Icon from '@/components/Icon.vue';
    import { mdiMenu, mdiHome, mdiHumanMaleBoardPoll, mdiNoteEditOutline, mdiBookOpenVariant, mdiInformationOutline, mdiAccountTie, mdiLogin } from '@mdi/js';
    import { useRoute } from 'vue-router';
    import { useStore } from 'vuex';
    const currentRoute = useRoute().path;
    let props = defineProps({
        isDrop:{
            type: Boolean,
            required: true
        },
        callBack:{
            type: Function,
            required: true,
        }
    })
    const isLogin = useStore().state.auth.token!='';
</script>
<template>
    <div class="relative">
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-100"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
            >
            <div
                v-if="isDrop"
                class="origin-top absolute opacity-80 top-0 w-full rounded-b-md shadow-lg text-sm overflow-hidden border z-20 bg-white"
            >
                <div class="py-1 select-none">
                    <router-link 
                        to="/"
                        @click="callBack"
                        class="p-4 flex items-center space-x-4"
                    >
                        <icon :path="mdiHome" size="30"/>
                        <span :class="{'font-semibold': currentRoute.includes('/')}" class="text-xl"> Home </span>
                    </router-link>
                </div>
                <div class="py-1 select-none">
                    <router-link 
                        to="/courses"
                        @click="callBack"
                        class="p-4 flex items-center space-x-4"
                    >
                        <icon :path="mdiHumanMaleBoardPoll" size="30"/>
                        <span class="text-xl"> Courses </span>
                    </router-link>
                </div>
                <div class="py-1 select-none">
                    <router-link 
                        to="/blogs"
                        @click="callBack"
                        class="p-4 flex items-center space-x-4"
                    >
                        <icon :path="mdiNoteEditOutline" size="30"/>
                        <span class="text-xl"> Blog </span>
                    </router-link>
                </div>
                <div class="py-1 select-none">
                    <router-link 
                        to="/tests"
                        @click="callBack"
                        class="p-4 flex items-center space-x-4"
                    >
                        <icon :path="mdiBookOpenVariant" size="30"/>
                        <span class="text-xl"> Tests </span>
                    </router-link>
                </div>
                <div class="py-1 select-none">
                    <router-link 
                        to="/about"
                        @click="callBack"
                        class="p-4 flex items-center space-x-4"
                    >
                        <icon :path="mdiInformationOutline" size="30"/>
                        <span class="text-xl"> About Us </span>
                    </router-link>
                </div>
                <div class="py-1 select-none">
                    <!-- <router-link  :class="{'font-semibold': currentRoute.includes('/login')}" class="nav"></router-link> -->
                    <router-link 
                        :to="isLogin?'/user-profile':'/login'"
                        @click="callBack"
                        class="p-4 flex items-center space-x-4"
                    >
                        <icon :path="isLogin?mdiAccountTie:mdiLogin" size="30"/>
                        <span class="text-xl"> {{isLogin?'Profile':'Login'}} </span>
                    </router-link>
                </div>
            </div>
        </transition>
    </div>
</template>