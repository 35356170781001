<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import Icon from '@/components/Icon.vue';
import { mdiChartBar, mdiClipboardEditOutline  , mdiTrashCanOutline  } from '@mdi/js';
const props = defineProps({
    datas: {
    type: Array,
    required: true,
    default: []
  },
    title: {
    type: String,
    required: true,
    default: 'Card'
  },
    actions: {
    type: Object,
    required: false,
  },
    icon: {
    type: String,
    required: false,
    default: mdiChartBar
  },
});

const actions = (index,name)=>{
    let action = event.target.getAttribute("action");
    if(action=="edit") props.actions.edit(index,name);
    else if(action=="delete") props.actions.del(index);
    else props.actions.detail(index,name);
};

</script>

<template>
    <div @click="actions(data.id,data.name)" v-for="data,index in datas" :key="index" class="flex flex-row bg-cyan-800 hover:bg-cyan-700 justify-between p-4 rounded-md hover:drop-shadow-2xl select-none cursor-pointer">
        <div class="flex flex-col p-2">
            <div class="flex flex-col -mt-4 justify-end cursor-pointer mb-3 text-yellow-500">
                <Icon :path="props.icon" size="80" w="w-18" h="h-18" class=""/>
            </div>
            <div class="text-2xl text-center mb-2 text-gray-100 flex flex-col justify-center border-rose-100 border-2 px-2 rounded-full">
                {{ data.name }}
            </div>
            <div class="text-xl font-semibold text-slate-200 hover:text-sky-100 dark:hover:text-amber-200 pl-3">
                {{ props.title }}
            </div>
        </div>
        <div class="flex flex-col justify-between items-end">
            <div class="text-4xl font-bold text-gray-100 tracking-widest">
                {{ data.total }}
            </div>
            <div class="flex flex-row items-end">
                <div class="flex flex-col justify-end cursor-pointer text-green-300 mr-6">
                    <Icon action="edit" :path="mdiClipboardEditOutline  " size="45" w="w-12" h="h-12" class=""/>
                </div>
                <div class="flex flex-col justify-end cursor-pointer text-red-400">
                    <Icon action="delete" :path="mdiTrashCanOutline " size="45" w="w-12" h="h-12" class=""/>
                </div>
            </div>
        </div>
    </div>
</template>