<script setup>
    import UserLayout from '../../layout/UserLayout.vue';
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import router from '@/router';
    import Icon from '@/components/Icon.vue';
    import { mdiLoading } from '@mdi/js';
    import Footer from '@/components/Footer.vue';
    let store = useStore();
    let levels = ref([]);
    let toSubCate = (category)=> router.push({name:'UserTestsCategory',params:{id: category.id, name:category.name}});
    const rounded = 'rounded-md md:rounded-xl';
    // const rounded = 'rounded-tl-md md:rounded-tl-xl rounded-br-xl md:rounded-br-3xl rounded-tr-sm rounded-bl-sm';
    const color = 'bg-primary text-white text-lg';
    // const color = 'bg-gradient-to-tr from-pink-400 to-purple-200 text-gray-800 text-lg';
    onMounted(()=>{
        if(store.getters.getUserTests.length>0){
            levels.value = store.getters.getUserTests;
        }else{
            store.dispatch('getLevelWithCate')
            .then(function(data){
                store.commit('setUserTests',data.data.data);
                levels.value = data.data.data;
            }).catch(function(error){
                console.log(error);
            });
        }
    });
</script>
<template><user-layout title="Testings" back="HomePage">
    <div class="container mx-auto pt-12">
        <div class="text-center font-semibold text-gray-500 text-lg">Welcome to ShiZuoka Japanese Language Center Free Level Test!</div>
        <div class="text-center text-lg pt-6 text-gray-500">ကြိုက်နှစ်သက်ရာ Level ကိုစမ်းသပ်ဖြေဆိုနိုင်ပါတယ်။</div>
        <div v-for="level,index in levels" :key="index" class="py-6">
            <div class="text-lg mt-10 mb-6 md:mt-20 md:mb-8 py-2 px-4 ring-2 ring-blue-900 w-fit rounded-lg">
                {{level.name}}
            </div>
            <div class="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8">
                <div 
                    v-for="category,index in level.categories" :key="index"
                    @click="toSubCate(category)"
                    :class="[rounded,color]"
                    class="text-center py-4 md:py-8 shadow-lg shadow-gray-400 cursor-pointer select-none">
                    {{category.name}}
                </div>
            </div>
        </div>
        <div v-if="levels.length==0">
            <div class="flex flex-col items-center pt-20">
                <Icon :path="mdiLoading" size="40" w="0" h="0" class="animate-spin text-[#F98E04]"/>
                <div class="text-gray-500 mt-8 animate-pulse">Loading . . .</div>
            </div>
        </div>
    </div>
    <div class="my-32"></div>
    <Footer :class="{'fixed bottom-0':levels.length==0}"/>
</user-layout></template>s