<script setup>
// const { onMounted }=require("@vue/runtime-core");
import { onMounted } from 'vue';
import { useStore } from 'vuex'
import {container as WidgetContainerModal} from "jenesius-vue-modal";
import Snaker from '@/components/Snaker.vue'
onMounted(()=>useStore().dispatch("storageInit"));
</script>
<template>
    <!-- <div class="bg-white text-base dark:bg-gray-800 dark:text-gray-100"> -->
    <router-view/>
    <widget-container-modal/>
    <!-- </div> -->
    <Snaker/>
</template>
<!--
<script>
    import {container} from "jenesius-vue-modal";    
    export default {
        components: {WidgetContainerModal: container},
        name: "App",
        mounted(){
            this.$store.dispatch("storageInit");
        },
    }
</script>
-->