<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import { mdiAccountSupervisorOutline, mdiMicrosoftOnenote, mdiClipboardListOutline, mdiCommentEditOutline } from '@mdi/js';
    import Icon from '@/components/Icon.vue';
    import { onMounted, onUnmounted, ref, } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import { isEmpty, sleep } from '@/helper/helper';
    const currentRoute = useRoute().path;
    let store = useStore();
    let data = ref();
    // real time data
    let feature = "dashboard";
    let getRealTimeData = ref(true);
    const getRTD = async ()=>{
        if(store.isRTDRun){
            return;
        }else{
            store.isRTDRun=true;
        }
        while(1==2-1){
            store.dispatch('getEnrollOrder');
            await sleep(store.state.interval);
        }
    }
    // real time data
    onMounted( async ()=>{
        getRTD();
        store.dispatch('checkUpdate');
        if(store.state.auth.role=='user' || store.state.auth.role=='student'){
            store.commit('toNamed',{name: 'HomePage'});return;
        }
        await store.dispatch('dashboard').then((val)=> data.value=val);
    });
</script>
<template><admin-layout>
    <div class="mx-8 md:mx-12 my-16 md:my-12 grid grid-cols-1 md:grid-cols-4 gap-8">
        <!-- Account -->
        <div @click="store.commit('toNamed',{name:'Account'})" class="bg-gradient-to-tr from-[#1fe1d8] to-[#5b7deb] rounded-xl p-6 cursor-pointer">
            <div class="flex flex-row w-full text-white">
                <div class="flex flex-1 flex-col justify-center items-start w-full">
                    <div :class="{'animate-bounce':isEmpty(data==null?{}:data)}" class="text-4xl tracking-widest mb-2">
                        {{data==null? '?': (data.users-1)}}
                    </div>
                    <div class="tracking-widest">Users</div>
                </div>
                <div class="flex flex-1 flex-col h-full justify-center items-end">
                    <Icon :path="mdiAccountSupervisorOutline" size="80" w="12" h="12"/>
                </div>
            </div>
        </div>
        <!-- Class -->
        <div @click="store.commit('toNamed',{name:'ClassRoom'})" class="bg-gradient-to-tr from-[#f451a2] to-[#ff7479] rounded-xl p-6 cursor-pointer">
            <div class="flex flex-row w-full text-white">
                <div class="flex flex-1 flex-col justify-center items-start w-full">
                    <div :class="{'animate-bounce':isEmpty(data==null?{}:data)}" class="text-4xl tracking-widest mb-2">{{data==null? '?':data.class}}</div>
                    <div class="tracking-widest">Class</div>
                </div>
                <div class="flex flex-1 flex-col h-full justify-center items-end">
                    <Icon :path="mdiMicrosoftOnenote" size="80" w="12" h="12"/>
                </div>
            </div>
        </div>
        <!-- Question -->
        <div @click="store.commit('toNamed',{name:'Test'})" class="bg-gradient-to-tr from-[#41e296] to-[#39b5b7] rounded-xl p-6 cursor-pointer">
            <div class="flex flex-row w-full text-white">
                <div class="flex flex-1 flex-col justify-center items-start w-full">
                    <div :class="{'animate-bounce':isEmpty(data==null?{}:data)}" class="text-4xl tracking-widest mb-2">{{data==null? '?':data.test}}</div>
                    <div class="tracking-widest">Tests</div>
                </div>
                <div class="flex flex-1 flex-col h-full justify-center items-end">
                    <Icon :path="mdiClipboardListOutline" size="80" w="12" h="12"/>
                </div>
            </div>
        </div>
        <!-- Blogs -->
        <div @click="store.commit('toNamed',{name:'Blog'})" class="bg-gradient-to-tr from-[#ffd742] to-[#ff8659] rounded-xl p-6 cursor-pointer">
            <div class="flex flex-row w-full text-white">
                <div class="flex flex-1 flex-col justify-center items-start w-full">
                    <div :class="{'animate-bounce':isEmpty(data==null?{}:data)}" class="text-4xl tracking-widest mb-2">{{data==null? '?':data.blog}}</div>
                    <div class="tracking-widest">Blogs</div>
                </div>
                <div class="flex flex-1 flex-col h-full justify-center items-end">
                    <Icon :path="mdiCommentEditOutline" size="80" w="12" h="12"/>
                </div>
            </div>
        </div>
    </div>
</admin-layout></template>