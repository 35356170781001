import { createStore } from "vuex";
import axios from "axios";
// admin module
import account from "./modules/admin/account";
import blog from "./modules/admin/blog";
import classroom from "./modules/admin/classroom";
import dashboard from "./modules/admin/dashboard";
import profile from "./modules/admin/profile";
import test from "./modules/admin/test";
import example from "./modules/example";
import login from "./modules/login";
// admin module

// user module
import userTest from "./modules/user/userTest";
import userBlog from "./modules/user/userBlog";
import userCourse from "./modules/user/userCourse";
// user module
import router from "../router";
import { sleep } from "@/helper/helper";

export default createStore({
  state: {
    baseUrl: "https://services.fruitysense.com/api/",
    base: "https://services.fruitysense.com/",
    ownBase: "https://saas.fruitysense.com/",
    dark: 1,
    auth: {
      id: "",
      phone: "",
      name: "",
      token: "",
      fb_link: "",
      photo_url: "",
      role: "user",
      updated_at: "",
    },
    interval: 10000,
    isRTDRun: false,
    errFormModel: "",
    loadFormModel: false,
    notification: {
      type: null,
      show: false,
      message: null,
    },
  },
  getters: {},
  mutations: {
    setDark: function (state, mode) {
      state.dark = mode;
    },
    setAuth: function (
      state,
      auth = {
        id: "",
        phone: "",
        name: "",
        token: "",
        fb_link: "",
        photo_url: "",
        role: "user",
        updated_at: "",
      }
    ) {
      state.auth = auth;
      localStorage.setItem("auth", JSON.stringify(auth));
    },
    toPath: (state, to) => router.push(to),
    toNamed: (state, val) => router.push(val),
    checkError: (state, error) => {
      if (error.name == "AxiosError") {
        if (error.response.data.message == "Unauthenticated.") {
          s;
          router.push("/login");
        }
      }
    },
    setErrorFormModel: function (state, err) {
      state.errFormModel = err;
    },
    setLoadFormModel: function (state, load) {
      state.loadFormModel = load;
    },
    notify: (state, { message, type }) => {
      state.notification.show = true;
      state.notification.type = type;
      state.notification.message = message;
      setTimeout(() => {
        state.notification.show = false;
      }, 3000);
    },
  },
  actions: {
    setDark({ commit }, mode) {
      let html = document.getElementById("html");
      let current = html.getAttribute("class");
      localStorage.setItem("dark", mode);
      commit("setDark", mode);
      if (mode == 1) {
        html.setAttribute("class", "dark");
      } else {
        html.setAttribute("class", "");
      }
    },
    storageInit({ commit }) {
      let dark = localStorage.getItem("dark");
      let auth = localStorage.getItem("auth");
      if (dark) {
        dark = parseInt(dark);
        commit("setDark", dark);
        html.setAttribute("class", dark == 1 ? "dark" : "");
      }
      if (auth) {
        auth = JSON.parse(auth);
        commit("setAuth", auth);
      }
    },
    async dashboard({ commit, state }) {
      let baseUri = state.baseUrl;
      let prefix = state.auth.role;
      let token = state.auth.token;
      return axios
        .get(`${baseUri}${prefix}/dashboard`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(function (data) {
          return data.data.data;
        })
        .catch(function (error) {
          commit("checkError", error);
        });
    },
  },
  modules: {
    example,
    login,
    account,
    blog,
    classroom,
    dashboard,
    profile,
    test,
    userTest,
    userBlog,
    userCourse,
  },
});
