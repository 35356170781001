import axios from 'axios';
import {closeModal} from "jenesius-vue-modal";
import { sleep } from "@/helper/helper";
const state = {
    navs: [],
    blogs: [],
};

const getters = {
   getBlogNavs: state=>state.navs,
   getBlogs: state=> state.blogs,
};

const actions = {
    // Blog
    async getBlogs({commit,rootState}){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.get(`${baseUri}${prefix}/blogs`,{
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        }
        ).then(function(data){
            commit('getBlogs',data.data.data)
        }).catch(function(error){
            commit('checkError',error);
        });
    },
    async createBlog({commit,rootState},{formData,callBack}){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        axios.post(`${baseUri}${prefix}/blogs`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            // console.log(data.data.user_data);
            let ans = data.data.data;
            callBack(ans);
            commit('setBlog',ans);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
                if(message.title) commit('setErrorFormModel',message.title[0]);
                if(message.user_id) commit('setErrorFormModel',message.user_id[0]);
                if(error.response.status==413) commit('setErrorFormModel','your file too lerge!');
            }
        });
    },
    async updateBlog({commit,rootState},{formData,callBack}){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        if(formData.get('status')=='true') formData.set('status',1);
        else formData.set('status',0);
        axios.post(`${baseUri}${prefix}/update-blogs`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token,
                }
            }
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            let ans = data.data.data;
            console.log(ans);
            commit('updateBlog',ans);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message) commit('setErrorFormModel',message);
                if(message.title) commit('setErrorFormModel',message.title[0]);
                if(message.user_id) commit('setErrorFormModel',message.user_id[0]);
                if(error.response.status==413) commit('setErrorFormModel','your file too lerge!');
            }
        });
    },
    async deleteBlog({commit,rootState},id){
        commit('setLoadFormModel',true);
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.delete(`${baseUri}${prefix}/blogs/${id}`,
        headers
        ).then(function(data){
            commit('setLoadFormModel',false);
            closeModal();
            commit('deleteBlog',id);
        }).catch((error)=>{
            commit('setLoadFormModel',false);
            if(error.response){
                let message = error.response.data.message;
                if(message.name) commit('setErrorFormModel',message.name[0]);
                if(message) commit('setErrorFormModel',message);
            }            
        });
    },
    // Blog
};

const mutations = {
    setBlogNavs: (state,navs)=>{
        state.navs = navs;
    },
    getBlogs: (state,blogs)=>{
        state.blogs=blogs;
    },
    setBlog: (state,blog)=>{
        state.blogs=[blog,...state.blogs];
    },
    updateBlog: (state,blog)=>{
        state.blogs.forEach( (val,ind) => {
            if(val.id==blog.id){
                state.blogs[ind]=blog;
                return;
            }
        });
    },
    deleteBlog: (state,id)=>{
        state.blogs = state.blogs.filter( val=>val.id!=id );
    }
};

export default {
    state, getters, actions, mutations
}