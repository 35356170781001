<script setup>
import UserLayout from '../../layout/UserLayout.vue';
import ListTile from '@/components/ListTile.vue';
import Icon from '@/components/Svg.vue';
import { mdiEqualizerOutline, mdiCheckDecagramOutline } from '@mdi/js';
import router from '@/router';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Footer from '@/components/Footer.vue';
let store = useStore();let route = useRoute();
let testId = route.params.id;
let testStatus = route.params.status;
let nEnroll = route.params.nEnroll;
let courseId = route.params.courseId;
let test = ref(null);
let userDetail = (item)=> console.log(item.id);
let takeExam = ()=> {
    router.push({
        name: 'UserTestDetail',
        params: {
            id: test.value.subcategory_id,
            name: test.value.title,
            test_id: test.value.id,
            courseId: courseId,
        }
    });
};
let pastPaper = ()=> {
    router.push({
        name: 'UserTestDetail',
        params: {
            id: test.value.subcategory_id,
            name: test.value.title,
        }
    });
};
onMounted(()=>{
    if(testId==null){
        router.push({name: 'ClassRooms'});
        return;
    }
    store.dispatch('getTest',testId)
    .then(val=>{
        test.value = val.data.message;
    });
});
</script>
<!-- back="ClassRooms" -->
<template><user-layout title="Exam Room">
    <div v-if="test" class="container mx-auto mt-4 md:mt-8 lg:mt-10">
        <div class="tracking-wider text-2xl">{{test.title}}</div>
        <div class="text-justify text-gray-600 py-1">{{test.description}}</div>
        <div class="flex flex-row py-3">
            <div class="flex-1 py-2 rounded-l-lg text-green-700 bg-green-200 text-center">start: {{test.start_at}}</div>
            <div class="flex-1 py-2 rounded-r-lg text-red-700 bg-red-200 text-center">end: {{test.dead_line}}</div>
        </div>
        <div class="py-1 capitalize">
            <Icon :path="mdiCheckDecagramOutline" size="25"/>
            total question {{test.questions.length}}
        </div>
        <div v-if="test.scores.length>0" class="my-8 md:mx-28 lg:mx-32">
            <ListTile :title="`Result ${test.scores.length}/${nEnroll}`" :titleIcon="mdiEqualizerOutline" :items="test.scores" :detail="userDetail" />
        </div>
        <div v-if="test.score==null&&testStatus==1" class="flex flex-row justify-center w-full">
            <button @click="takeExam" type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-8 py-2.5 text-center mr-2 mt-4 mb-2 w-fit shadow-lg shadow-gray-400">take the exam</button>
        </div>
        <div v-else-if="test.score!=null" class="flex flex-row justify-center w-full">
            <button @click="pastPaper" type="button" class="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-8 py-2.5 text-center mr-2 mt-4 mb-2 w-fit shadow-lg shadow-gray-400">check past paper</button>
        </div>
    </div>
<div class="my-32"></div>
<Footer :class="{'fixed bottom-0':true}" :small="true"/>
</user-layout></template>