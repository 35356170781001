<script setup>
    import UserLayout from '../../layout/UserLayout.vue';
    import UserCourses from '@/components/user/UserCourses.vue';
    import { onMounted, ref } from 'vue'; 
    import { useStore } from 'vuex';
    import { mdiLoading, mdiInformationOutline } from '@mdi/js';
    import router from '@/router';
    import Footer from '@/components/Footer.vue';
    import Icon from '@/components/Icon.vue';
    let store = useStore();
    onMounted(()=>{
        store.dispatch('setDark',0);
        if(store.getters.getClasses.newClasses.length==0){
            store.dispatch('getClasses');
        }
    });
</script>
<template><user-layout title="Course" back="HomePage">
    <div class="container mx-auto">
        <div v-if="store.getters.getClasses.newClasses.length>0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12 md:mt-20">
            <UserCourses v-for="item,index in store.getters.getClasses.newClasses" :key="index" :item="item"/>
        </div>
        <div v-else-if="store.getters.getEmptyClassesInUserApp" class="flex flex-col items-center pt-20 pb-72">
            <Icon :path="mdiInformationOutline" size="40" w="0" h="0" class="text-gray-500"/>
            <div class="text-gray-500 mt-8 animate-pulse">There is no course</div>
        </div>
        <div v-else class="flex flex-col items-center pt-20 pb-72">
            <Icon :path="mdiLoading" size="40" w="0" h="0" class="animate-spin text-[#F98E04]"/>
            <div class="text-gray-500 mt-8 animate-pulse">Loading . . .</div>
        </div>
    </div>
    <div class="my-32"></div>
    <!-- store.getters.getClasses.newClasses.length>0 -->
    <Footer :class="{'fixed bottom-0':store.getters.getClasses.newClasses.length==0}"/>
</user-layout></template>