<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
let store = useStore();
let notification = computed(()=>{
    return store.state.notification;
});
</script>

<template>
<!-- v-if = "notification.show" 
class="fixed w-[300px] left-96 bottom-4 py-2 px-4 text-white animate-fade-in-down"
-->
    <div
        v-if = "notification.show"
        class="fixed bottom-4 py-2 animate-fade-in-down flex flex-row w-screen justify-center"
        >
            <div class="w-3/4 md:w-1/3 px-4 rounded-lg h-10 text-slate-600" :class="[notification.type === 'success' ? 'bg-emerald-200' : 'bg-red-200']">
                <div class="flex flex-row justify-center items-center h-full">
                    {{ notification.message }}
                </div>
            </div>
    </div>
</template>

<!--
    #usage
    add in tailwind.config.js -> extend
     keyframes: {
        'fade-in-down': {
            "from": {
                transform: "translateY(-0.75rem)",
                opacity: '0'
            },
            "to": {
                transform: "translateY(0rem)",
                opacity: '1'
            },
        },
      },
      animation: {
          'fade-in-down': "fade-in-down 0.2s ease-in-out both",
      },

-->