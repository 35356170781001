<script setup>
    import Icon from '@/components/Svg.vue';
    import { mdiAccount, mdiChevronRight } from '@mdi/js';
    import { useStore } from 'vuex';
    let store = useStore();
    let props = defineProps({
        items:{
            type: Array,
            required: true,
        },
        detail:{
            type: Function,
            required: true,
        },
        title:{
            type: String,
            required: true,
        },
        titleIcon:{
            type: String,
            required: false,
            default: null,
        }
    });


    let color = (i)=> i%2==0?'bg-slate-300': 'bg-slate-200';
    let tileHeight = (len)=> len>5? 'h-80': 'h-fit';
</script>
<template>
   <div class="select-none">
        <div class="flex flex-row items-center justify-center text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-t-2xl h-14">
            <span v-if="titleIcon"><Icon :path="titleIcon" class="mr-2"/></span>{{title}} 
        </div>
        <div :class="tileHeight(items.length)" class="overflow-y-auto scrollbar-hide bg-gradient-to-r from-green-400 to-blue-600 px-0.5">
            <div @click="detail(item)" v-for="item,i in items" :key="i" :class="[color(i)]" class="h-14 flex flex-row hover:opacity-80 cursor-pointer">
                <!-- leading -->
                <div class="w-10 h-10 my-2 mx-4 flex flex-col justify-center items-center rounded-full bg-white">
                    <div v-if="item.photo_url" class="w-10 h-10 overflow-hidden rounded-full">
                        <img :src="store.state.base+item.photo_url" alt="img" class="rounded-full">
                    </div>
                    <div v-else>
                        <Icon :path="mdiAccount" size="30" class="text-gray-300"/>
                    </div>
                </div>
                <!-- Title / SubTitle -->
                <div class="flex-1 flex flex-col justify-center">
                    <div class="mt-2 font-semibold text-gray-700 tracking-wider leading-tight">{{item.name}}</div>
                    <div class="text-sm text-gray-500 tracking-wider leading-tight">Score: {{item.score}}</div>
                </div>
                <!-- tail -->
                <div class="flex flex-col justify-center mx-3">
                    <Icon :path="mdiChevronRight" size="35" :class="i%2==0? 'text-gray-600' : 'text-gray-400'"/>
                </div>
            </div>
        </div>
        <div class="flex flex-row items-center justify-center text-white bg-gradient-to-r from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-b-2xl h-6">
            
        </div>
   </div> 
</template>