<script setup>
    import NavBar from '@/components/user/NavBar.vue';
    import { useRoute } from 'vue-router';
    let currentRoute = useRoute().path;
    let props = defineProps({
        title:{
            type: String,
            required: false,
            default: ''
        },
        centerTitle:{
            type: Boolean,
            required: false,
            default: true
        },
        back:{
            type: String,
            required: false,
            default: null
        }
    });
    const eleHeight = currentRoute=='/'? 'h-20':'h-14';
</script>
<template>
    <div  class="font-poppin relative flex flex-col text-black bg-white h-screen overflow-auto scrollbar-hide">
        <!-- nav bar -->
        <NavBar :title="title" :centerTitle="centerTitle" :back="back"/>
        <!-- Slot Body -->
        <div id="list">
            <!-- id="list" and empty div purpose of scrollToTop -->
            <!-- let pos = document.getElementById("list") -->
            <!-- pos.scrollIntoView(true) -->
            <div :class="eleHeight" class="lg:h-28" ></div> 
            <slot/>
        </div>
        <!-- footer -->
        <div>

        </div>
    </div>
</template>