<script setup>
    import AdminLayout from '@/views/layout/AdminLayout.vue';
    import Addbar from '@/components/admin/Addbar.vue';
    import {openModal,closeModal} from "jenesius-vue-modal";
    import FormModel from '@/components/FormModel.vue';
    import ClassDetailCard from '@/components/admin/ClassRoom/ClasssDetailCard.vue';
    import Navbar from '@/components/admin/Navbar.vue';
    import { mdiAccountCircle, mdiPlusCircleOutline, mdiTrashCanOutline, mdiOpenInNew } from '@mdi/js';
    import { isEmpty,formatDate } from '@/helper/helper';
    import Icon from '@/components/Icon.vue';
    import { reactive,ref, onMounted, computed } from 'vue';
    import router from '@/router';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import DataTable from 'vue3-easy-data-table';
    import 'vue3-easy-data-table/dist/style.css'; 
     const headers = [
      { text: "User Id", value: "id", sortable: true },
      { text: "Name", value: "name", sortable: true },
      { text: "Attendance", value: "attr", sortable: true },
      { text: "Action", value: "action"}
    ];
    let store = useStore();
    let route = useRoute();
    let courseId = route.params.id;
    // search feature
    const searchField = ref('name');
    const searchValue = ref('');
    const searchKey = ['user_id','name'];
    // search feature
    const goRoute = (name,params,index)=>{
        let navs=[];
        for(let x=0;x<index+1;x++){
            navs = [...navs,store.getters.getClassNavs[x]];
        }
        store.commit('setClassNavs',navs);
        router.push({name: name, params: params});
    }
    let absent = reactive({
        data: []
    });
    const toggleAtt = id=> {
        absent.data.filter( (val,ind)=> {
            if(val.id==id) absent.data[ind].absent=!val.absent
        })
    };
    const getData = computed(()=>{
        let temp = [];
        if(store.getters.getClassDetail.length>0){
            let userBodies = store.getters.getClassDetail[0].data.map( (val)=>{
                temp= [...temp,{id:val.id,absent:false}];
                return {id:val.id,course_id:val.course_id,name:val.name,user_id:val.user_id}
            })
            absent.data=temp;
            return userBodies;
        }else{
            return [];
        }
        
    });
    const finish = ()=>{
        let today = new Date();
        // prepare for absent student
        let temp = absent.data.filter( val=> val.absent );
        let absent_list=[];
        temp.filter( val=> absent_list=[...absent_list,val.id] );
        absent_list = JSON.stringify(absent_list);
        // prepare for absent student
        const callBack= ()=>{
            let navs=[];
            for(let x=0;x<(store.getters.getClassNavs.length-1);x++){
                navs = [...navs,store.getters.getClassNavs[x]];
            }
            store.commit('setClassNavs',navs);
            router.push({name: navs[navs.length-1].routeName, params: navs[navs.length-1].params});
        };
        openModal(FormModel, {
            toggle: ()=>closeModal(),
            model: {
                title: 'Create Attendance',
                form: [
                    {
                        label: 'Select Date',
                        type: 'date',
                        name: 'att_date',
                        ref: ref(formatDate(today)) //'YYYY-MM-DD')
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'absent_list',
                        placeholder: '',
                        ref: ref(absent_list)
                    },
                    {
                        label: '',
                        type: 'hidden',
                        name: 'course_id',
                        placeholder: '',
                        ref: ref(courseId)
                    },
                ],
                action: {
                    title: 'create attendance'
                },
            },
            submit: (formData)=>{
                store.dispatch('createAttendance',{formData:formData,callBack:callBack});
            }
        });
    }
    
    onMounted(()=>{
        if(courseId==null){
            router.push({name: 'ClassRoom'});
            return;
        }
    });
    let isAttend = (id)=> {
        let temp = false;
        absent.data.filter( (val,ind)=> {
            if(val.id==id) {
                temp = absent.data[ind].absent;
                return;
            }
        });
        return temp;
    };
const jinn = (ref)=> ref.length==1? 'h-36':ref.length==2? 'h-20': ref.length==3? 'h-12':'';
</script>
<template><admin-layout>
    <Navbar :navs="store.getters.getClassNavs" :goRoute="goRoute" />
    <Addbar label="Finish" :add="finish"/>
    <div v-if="getData.length>0" class="px-4 md:px-12 py-8 z-0">
        <div class="flex flex-col md:flex-row justify-between mb-6">
            <div>
                <span class="mr-2">search field:</span>
                <select v-model="searchField" class="rounded-sm dark:bg-gray-600 py-1 pl-2">
                    <option v-for="v,i in searchKey" :key="i">{{v}}</option>
                </select>
            </div>
            <div class="flex flex-row mt-4 lg:mt-0">
                <span class="mr-4">search value: </span>
                <input type="text" v-model="searchValue" class="rounded-sm bg-gray-200 dark:bg-gray-600 py-1 px-2 w-40 md:w-60">
            </div>
        </div>

        <DataTable :headers="headers" :items="getData" :rows-per-page="10" :search-field="searchField" :search-value="searchValue">
            <template #id="{user_id}">
                <div class="flex flex-row items-center justify-start" :class="jinn(getData)">
                    {{user_id}}
                </div>
            </template>
            <template #name="{name}">
                <div class="flex flex-row items-center justify-start w-32">
                    {{name}}
                </div>
            </template>
            <template #attr="{id}">
                <div class="flex flex-row items-center justify-start">
                    <div v-if="!isAttend(id)" class="text-green-500 uppercase">
                        Attendence
                    </div>
                    <div v-else class="text-red-500 uppercase">
                        Absend
                    </div>
                </div>
            </template>
            <template #action="{id}">
                <div class="flex flex-row items-center justify-start my-1">
                    <div @click="toggleAtt(id)" v-if="!isAttend(id)" class="px-2 py-1 bg-red-300 text-red-800 rounded-full w-fit cursor-pointer">
                        absent
                    </div>
                    <div v-else @click="toggleAtt(id)" class="px-2 py-1 bg-green-300 text-green-800 rounded-full w-fit cursor-pointer">
                        attend
                    </div>
                </div>
            </template>
        </DataTable>
    </div>
</admin-layout></template>