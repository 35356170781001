import axios from 'axios';

const state = {
   
};

const getters = {
   
};

const actions = {
    async updateProfile({commit,rootState},formData){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        // return axios.post(`${baseUri}${prefix}/csrf`,
        return axios.post(`${baseUri}${prefix}/edit-profile`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+ token,
                // 'Access-Control-Allow-Origin' : "*",
                "Access-Control-Allow-Origin" : "*",
                // 'Access-Control-Allow-Origin' : baseUri,
                'Access-Control-Allow-Methods':'POST',
                // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                }
            }
        );
    },
    async checkUpdate({commit,rootState}){
        let baseUri = rootState.baseUrl;
        let prefix = rootState.auth.role;
        let token = rootState.auth.token;
        let params = {"updated_at":rootState.auth.updated_at};
        const headers = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        };
        axios.put(`${baseUri}${prefix}/check-update`,
        params,headers
        ).then(function(ans){
            let auth = {
                id: ans.data.user_data.id,
                phone: ans.data.user_data.phone,
                name: ans.data.user_data.name,
                token: rootState.auth.token,
                fb_link: ans.data.user_data.fb_link,
                photo_url: ans.data.user_data.photo_url,
                role: rootState.auth.role,
                updated_at: ans.data.user_data.updated_at,
            }
            commit('setAuth',auth);
        })
        .catch((error)=>{
        });
    },
};

const mutations = {
    

};

export default {
    state, getters, actions, mutations
}