<!--
Resuable Model Template,
Ui reference flowbite
Modify By Arkar Mann Aung
Internal Use Only
-->
<script setup>
import { reactive, onMounted } from 'vue';
import { useStore } from 'vuex';
import FormData from 'form-data';
import { mdiLoading } from '@mdi/js';
import Icon from '@/components/Icon.vue'
let store = useStore();
const props = defineProps({
    toggle:{
        type: Function,
        required: true,
    },
    model: {
        type: Object,
        required: false,
    },
    submit: {
        type: Function,
        required: true,
    },
    style: {
        type: String,
        default: ''
    }
});
let data = reactive({
    toggle: props.toggle,
    model: props.model,
    submit: props.submit
});
const submit=()=>{
    if(!store.state.loadFormModel){
        let formData = new FormData();
        if(data.model.form){
            data.model.form.forEach(form => {
                if( (form.ref!=''&&form.ref!=null)||form.type=='checkbox' ) formData.append(form.name, form.ref); 
            });
        }
        data.submit(formData);
    }
};
const selectFile = (event)=>{
    let name = event.target.name;
    // find on data
    data.model.form.forEach(element => {
        if(element.name==name) element.ref = event.target.files[0];
    });
}
onMounted(()=>{
    useStore().state.errFormModel='';
    useStore().state.loadFormModel=false;
});

const titleStyle="mb-4 text-xl font-medium text-gray-900 dark:text-white";
const labelStyle="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300";
const inputStyle="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white";
const labelFileStyle="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300";
const inputFileStyle="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400";
</script>
<template>
<div :class="style" class="relative overflow-x-auto scrollbar-hide bg-white rounded-lg shadow dark:bg-gray-700 w-full mx-8 md:w-1/3 z-50">
    <button @click="toggle" type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white  select-none" data-modal-toggle="authentication-modal">
        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>  
    </button>
    <div class="py-6 px-6 lg:px-8">
        <h3 v-if="data.model.form" :class="titleStyle">{{data.model.title}}</h3>
        <div class="space-y-6" action="#">
            <div v-for="item,index in data.model.form" :key="index">
                <!-- with checkbox -->
                <div v-if="item.type=='checkbox'" class="flex flex-row justify-start items-center"> 
                    <label v-if="item.label" :for="item.name" class="mr-2 select-none">{{item.label}}</label>
                    <input :type="item.type" :name="item.name" :id="item.name" v-model="item.ref" :checked="true">
                </div>
                <!-- with select/option -->
                <div v-else-if="item.type=='select'" class="flex flex-col justify-start items-start"> 
                    <label v-if="item.label" :for="item.name" class="mr-2 select-none">{{item.label}}</label>
                    <!-- <select v-model="item.ref" :name="item.name" :id="item.name" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white text-black rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"> -->
                    <select v-model="item.ref" :name="item.name" :id="item.name" :class="inputStyle">
                        <option v-for="item,i in item.options" :key="i" :value="item.value">{{item.text}}</option>
                    </select>
                </div>
                <!-- with file -->
                <div v-else-if="item.type=='file'" class="flex flex-col justify-start items-start"> 
                    <!-- <label v-if="item.label" :for="item.name" class="mr-2 select-none">{{item.label}}</label> -->
                    <!-- <input :type="item.type" @change="selectFile" :name="item.name" :id="item.name"> -->
                    <label :class="labelFileStyle" :for="item.name">{{item.label}}</label>
                    <input @change="selectFile" :class="inputFileStyle" :name="item.name" :id="item.name" type="file">
                    <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">{{item.placeholder}}</div>
                </div>
                <!-- inputbox -->
                <div v-else>
                    <label v-if="item.label" :for="item.name" :class="labelStyle">{{item.label}}</label>
                    <input v-model="item.ref" :type="item.type" :autofocus="item.autofocus" :name="item.name" :id="item.name" :class="inputStyle" :placeholder="item.placeholder" required>
                </div>
            </div>
            <div v-if="useStore().state.errFormModel && data.model.form" class="text-red-500">
                {{useStore().state.errFormModel}}
            </div>
            <button v-if="data.model.form" @click="submit" type="submit" class="flex flex-row items-center justify-center w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 select-none select-none">
                <span class="mr-2">{{data.model.action.title}}</span> <Icon v-if="store.state.loadFormModel" :path="mdiLoading" size="20" class="animate-spin"></Icon>
            </button>
            <div v-if="!data.model.form" class="p-6 text-center">
                <svg class="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{{model.title}}</h3>
                <div v-if="useStore().state.errFormModel" class="text-red-500 pb-6 text-left">
                    {{useStore().state.errFormModel}}
                </div>
                <button @click="submit" type="button" class="flex-row justify-center text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                    <span class="mr-2">Yes, I'm sure</span> <Icon v-if="store.state.loadFormModel" :path="mdiLoading" size="20" class="animate-spin select-none"></Icon>
                </button>
                <button @click="toggle" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 select-none">No, cancel</button>
            </div>
        </div>
    </div>
</div>
</template>

<!-- Example Call Model
openModal(FormModel, {
    showModal:data.showModal,
    toggle:toggleModel,
    model: {
        title: 'Add New Level',
        form: [
            {
                label: 'Your email',
                type: 'email',
                name: 'email',
                placeholder: 'name@company.com',
                ref: ref(null)
            },
            {
                label: 'Your password',
                type: 'password',
                name: 'password',
                placeholder: '••••••••',
                ref: ref(null)
            },
            {
                label: 'Upload file',
                type: 'file',
                name: 'file',
                autofocus: true,
                placeholder: 'Upload Certificate File',
                ref: ref(null)
            }
        ]
    }
});

plug in for hide scoll bar
# Using npm
npm install tailwind-scrollbar-hide
// tailwind.config.js
module.exports = {
  theme: {
    // ...
  },
  plugins: [
    require('tailwind-scrollbar-hide')
    // ...
  ]
}
<div class="w-4 scrollbar-hide">...</div>


-->