<script setup>
    import UserLayout from '../../layout/UserLayout.vue';
    import Course from '@/components/user/course/Course.vue';
    import Icon from '@/components/Svg.vue';
    import { mdiBullhornOutline, mdiEqualizerOutline, mdiFileSign, mdiWalk } from '@mdi/js';
    import ListTile from '@/components/ListTile.vue';
    import ExamTile from '@/components/user/room/ExamTile.vue';
    import router from '@/router';
    import { onMounted, computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import Footer from '@/components/Footer.vue';
    let store = useStore();let route = useRoute();
    let courseId = route.params.id;
    let courseName = route.params.name;
    let userDetail = (item)=> {
        router.push({path:'/profile',query:{id: item.user_id}});
    };
    let examDetail = (item)=> router.push({name: 'UserExamRoom', params:{id:item.id,status:item.status,nEnroll:store.getters.getClassDetail[0].data.length,courseId:courseId}});
    let getAttr = computed(()=>{
        if(store.getters.getClassDetailAttr.length>0){
            let youAttr = store.getters.getClassDetailAttr.filter(val=>val.user_id==store.state.auth.id);
            let attr = youAttr[0].attendance;
            return attr>=75? {attr: attr + ' %',css: 'text-green-500'}
                : attr>49? {attr: attr + ' %',css: 'text-yellow-500'} : {attr: attr + ' %',css: 'text-red-500'};
        }else{
            return {attr: '-',css: 'text-gray-500'};
        }
    });
    onMounted( ()=>{
        if(courseId==null){
            router.push({name: 'ClassRooms'});
            return;
        }
        store.commit('resetClassDetail');
        store.dispatch('getClassDetail',courseId);
    });
</script>
<template><user-layout :title="courseName" back="ClassRooms">
    <div class="container mx-auto mt-4 md:mt-8 lg:mt-10">
        <div v-if="store.getters.getClassDetail.length>0">
            <div v-for="item,index in store.getters.getClassDetail[3].data" :key="index" class="p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
                <div class="flex flex-row items-center">
                    <div class="">
                        <Icon :path="mdiBullhornOutline" size="30" class="mr-4 text-green-300 p-0.5 -rotate-[30deg] ring-1 rounded-full"/>
                    </div>
                    <div class="">
                        <span class="mr-4 font-bold">{{item.title}}</span>
                        {{item.content}}
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-center mt-12 p-2 md:mx-28 lg:mx-32">
                <div class="flex-1 flex flex-col">
                    <Icon :path="mdiWalk" size="45" class="text-sky-400"/>
                    <div class="text-gray-500">Your Attendance</div>
                </div>
                <div :class="getAttr.css" class="flex-1 flex flex-col justify-center items-center font-bold font-domine text-3xl">
                    {{getAttr.attr}}
                </div>
            </div>
            <div v-if="store.getters.getClassDetail[0].data.length>0" class="my-12 md:mx-28 lg:mx-32">
                <ListTile title="Ranking" :titleIcon="mdiEqualizerOutline" :items="store.getters.getClassDetail[0].data" :detail="userDetail" />
            </div>
            <div v-if="store.getters.getClassDetail[2].data.length>0" class="my-12 md:mx-28 lg:mx-32">
                <ExamTile title="Level Test" :titleIcon="mdiFileSign" :items="store.getters.getClassDetail[2].data" :detail="examDetail" />
            </div>
        </div>
        <div v-else class="text-center text-xl text-gray-500 mt-12 tracking-wider font-domine">L o a d i n g    .   .   . </div>
    </div>
    <div class="my-32"></div>
    <Footer :class="{'fixed bottom-0':true}" :small="true"/>
</user-layout></template>