<script setup>
import UserLayout from '../../layout/UserLayout.vue';
import Footer from '@/components/Footer.vue';
import Icon from '@/components/Svg.vue';
import { mdiGithub, mdiFacebook, mdiWeb } from '@mdi/js';
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import constData from '@/const/about';
let store = useStore();
let lecturer = ref([]);let staff = ref([]);
let getEmployee =computed(()=>{
    let temp = [];
    lecturer.value.forEach((val)=>{
        let data = {
            name: val.name,
            imgUrl: store.state.base+val.photo_url,
            role: 'Lecturer',
        };
        temp=[...temp,data];
    });
    staff.value.forEach((val)=>{
        let data = {
            name: val.name,
            imgUrl: val.photo_url==null?null:store.state.base+val.photo_url,
            role: 'Staff',
        };
        temp=[...temp,data];
    });
    return temp;
});
const devInfo = [
    {
        name: 'Aung Min Khant',
        role: 'Full-Stack X-Developer',
        image: require('@/assets/dev/minkhant.jpg'),
        social: [
            {
                icon: mdiFacebook,
                link: 'https://www.facebook.com/khantkhantmin.min',
                color: 'text-blue-500'
            },
            {
                icon: mdiGithub,
                link: 'https://github.com/WannaMinKhant',
                color: 'text-gray-800'
            },
            {
                icon: mdiWeb,
                link: 'https://wannaminkhant.github.io/',
                color: 'text-primary'
            },
        ]
    },
    {
        name: 'Arkar Mann Aung',
        role: 'Full-Stack X-Developer',
        image: require('@/assets/dev/arkar.jpg'),
        social: [
            {
                icon: mdiFacebook,
                link: 'https://www.facebook.com/arkarmannaung',
                color: 'text-blue-500'
            },
            {
                icon: mdiGithub,
                link: 'https://github.com/arkarMannAung',
                color: 'text-gray-800'
            },
            {
                icon: mdiWeb,
                link: 'https://arkarmannaung.github.io',
                color: 'text-primary'
            },
        ]
    }
];
onMounted(()=>{
    store.dispatch('getAboutData')
    .then((data)=>{
        lecturer.value = data.data.lecturer;
        staff.value = data.data.staff;
        // console.log(data.data);
    })
});
</script>
<template><user-layout title="About" back="HomePage">
    <div class="container mx-auto">
        <!-- about -->
        <div class="flex flex-col mt-12 lg:mt-24">
            <div class="flex flex-row justify-center mb-12 lg:mb-4 font-domine font-semibold text-3xl text-primary text-center lg:text-left">About Us</div>
            <div class="flex flex-col-reverse lg:flex-row">
                <div class="flex-1 flex flex-col mt-12 lg:mt-0">
                    <div class="font-domine text-justify mt-6 text-gray-600 text-xl tracking-wide leading-loose">
                        {{constData.about.info}}
                    </div>
                </div>
                <div class="flex-1 flex flex-col justify-center lg:flex-none lg:w-3/5 lg:px-12">
                    <img :src="constData.about.photoUrl" alt="img" class="rounded-3xl shadow-xl">
                </div>
            </div>
        </div>
        <!-- divider -->
        <div class="flex flex-row justify-center my-12 lg:my-20">
            <div class="h-0.5 w-2/3 bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
        </div>

        <div class="flex flex-col md:flex-row justify-evenly">
            <div v-for="v,i in constData.founder" :key="i" class="flex flex-col items-center">
                <!-- avator -->
                <div class="w-80 h-60 rounded-2xl ring-2 ring-primary bg-white p-2 shadow-md shadow-gray-400">
                    <img :src="v.imageUrl" alt="founder" class="rounded-lg w-full h-full shadow-md shadow-gray-400">
                </div>
                <div class="text-primary text-xl font-semibold mt-4">
                    {{v.title}}
                </div>
                <div class="text-primary text-lg mt-1">
                    {{v.subTitle}}
                </div>
            </div>
        </div>
        
        <!-- divider -->
        <div v-if="getEmployee.length>0" class="flex flex-row justify-center my-12 lg:my-20 lg:mb-12">
            <div class="h-0.5 w-2/3 bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
        </div>

        <!-- employee -->
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 mb-12">
            <div v-for="item,i in getEmployee" :key="i" class="flex flex-col items-center mt-12 lg:mt-20">
                <!-- avator -->
                <div class="w-44 h-44 rounded-full ring-1 ring-gray-600 bg-white p-2">
                    <img :src="item.imgUrl" alt="image" class="rounded-full w-full h-full shadow-md shadow-gray-400">
                </div>
                <div class="text-primary text-xl font-semibold mt-3">
                    {{item.role}}
                </div>
                <div class="text-primary text-xl mt-1">
                    {{item.name}}
                </div>
            </div>
        </div>
        
        <!-- divider -->
        <div class="flex flex-row justify-center my-12 lg:my-20">
            <div class="h-0.5 w-2/3 bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
        </div>
        
        <!-- system info -->
        <div class="">
            <div class="text-2xl md:text-3xl font-domine mt-12 lg:mt-24 text-primary tracking-widest font-semibold">System Informations</div>
            <div class="flex flex-col lg:flex-row">
                <div class="flex flex-col flex-1">
                    <div class="text-lg font-semibold mt-6 mb-2 md:mb-4">Sever Side Technology</div>
                    <div class="italic flex flex-col ml-3 text-gray-600">
                        <li class="list-inside list-disc ml-4">PHP | Laravel 8</li>
                        <li class="list-inside list-disc ml-4">Security With OAuth 2.0</li>
                        <li class="list-inside list-disc ml-4">Maria Db With Eloquent Layer</li>
                    </div>
                </div>
                <div class="flex flex-col flex-1">
                    <div class="text-lg font-semibold mt-6 mb-2 md:mb-4">Client Side Technology</div>
                    <div class="italic flex flex-col ml-3 text-gray-600">
                        <li class="list-inside list-disc ml-4">Vuex, Vue3</li>
                        <li class="list-inside list-disc ml-4">JQuery</li>
                        <li class="list-inside list-disc ml-4">Network with Axios</li>
                        <li class="list-inside list-disc ml-4">Design with TailwindCss</li>
                        <li class="list-inside list-disc ml-4">Material Desing Icon @mdi</li>
                    </div>
                </div>
                <div class="flex flex-col flex-1">
                    <div class="text-lg font-semibold mt-6 mb-2 md:mb-4">Dev Standard {{'</>'}}</div>                
                    <div class="italic flex flex-col ml-3 text-gray-600">
                        <li class="list-inside list-disc ml-4">Clean Code</li>
                        <li class="list-inside list-disc ml-4">Reusable Component</li>
                        <li class="list-inside list-disc ml-4">Maintainable and Reliable</li>
                        <li class="list-inside list-disc ml-4">Developed Period 2 Months</li>
                    </div>
                </div>
            </div>
        </div>
        <!-- divider -->
        <div class="flex flex-row justify-center my-12 lg:my-20">
            <div class="h-0.5 w-2/3 bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"></div>
        </div>
        <!-- dev info -->
        <div class="mt-12">
            <div class="text-2xl md:text-3xl font-domine mt-12 lg:mt-24 text-primary tracking-widest font-semibold">Developers Info</div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-12 mt-12 md:mx-24">
                <div v-for="item,i in devInfo" :key="i" class="flex flex-col items-center">
                    <div class="w-44 h-44 rounded-full bg-gray-300 shadow-md shadow-gray-400">
                        <img :src="item.image" alt="photo" class="w-44 h-44 rounded-full">
                    </div>
                    <div class="font-semibold text-xl text-primary mt-4">{{item.name}}</div>
                    <div class="text-gray-500 mt-1">{{item.role}}</div>
                    <div class="flex flex-row mt-1">
                        <a :href="social.link" target="blank" v-for="social,i in item.social" :key="i">
                            <Icon :path="social.icon" size="25" class="mr-2" :class="social.color"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-32"></div>
    </div>
    <Footer :small="false"/>
</user-layout></template>

